import debounce from "lodash/debounce";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../contexts/AuthContext";
import { useApi } from "../../hooks/useApi";
import { showToast } from "../../utils/toast";
import Spinner from "../Spinner";
import { Button } from "../ui/button";
import { Card, CardContent } from "../ui/card";
import { Checkbox } from "../ui/checkbox";
import { Input } from "../ui/input";
import { Label } from "../ui/label";

const SiteSettings = () => {
  const { t } = useTranslation();
  const { user } = useAuth();
  const { apiCall } = useApi();

  const isAdmin = user?.role === "admin";

  // Stato per le impostazioni del sito
  const [siteSettings, setSiteSettings] = useState({
    alias: user?.alias || "",
    visible_sections: user?.visible_sections || {
      apartments: true,
      guides: true,
    },
  });

  const [isCheckingAlias, setIsCheckingAlias] = useState(false);
  const [aliasError, setAliasError] = useState("");
  const [aliasSuccess, setAliasSuccess] = useState("");

  // Funzione per controllare l'alias
  const checkAlias = debounce(async (alias) => {
    if (!alias) {
      setAliasError("");
      setAliasSuccess("");
      return;
    }

    if (alias.length < 3) {
      setAliasError("L'alias deve essere di almeno 3 caratteri");
      setAliasSuccess("");
      return;
    }

    setIsCheckingAlias(true);
    try {
      const response = await apiCall(
        "get",
        `/users/settings/verify-alias/${alias}`,
        null,
        true,
      );

      if (!response.isAvailable) {
        setAliasError(response.message);
        setAliasSuccess("");
      } else {
        setAliasError("");
        setAliasSuccess("Alias disponibile");
      }
    } catch (error) {
      console.error("Error checking alias:", error);
      setAliasError("Errore durante la verifica dell'alias");
      setAliasSuccess("");
    } finally {
      setIsCheckingAlias(false);
    }
  }, 500);

  const handleAliasChange = (e) => {
    const newAlias = e.target.value.toLowerCase().trim();

    const aliasRegex = /^[a-zA-Z0-9-]*$/;
    if (newAlias && !aliasRegex.test(newAlias)) {
      setAliasError("L'alias può contenere solo lettere, numeri e trattini");
      setAliasSuccess("");
      return;
    }

    setSiteSettings((prev) => ({ ...prev, alias: newAlias }));

    if (!newAlias) {
      setAliasError("");
      setAliasSuccess("");
      return;
    }

    if (newAlias && aliasRegex.test(newAlias)) {
      checkAlias(newAlias);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (aliasError) {
      showToast.error(aliasError);
      return;
    }

    try {
      // Convertiamo l'oggetto visible_sections in un array
      const visibleSectionsArray = Object.entries(siteSettings.visible_sections)
        .filter(([_, isEnabled]) => isEnabled)
        .map(([section]) => section);

      const response = await apiCall(
        "put",
        `/users/settings/${user.id}`,
        {
          alias: siteSettings.alias,
          visible_sections: visibleSectionsArray,
        },
        true,
      );

      if (response.success) {
        showToast.success("Impostazioni salvate con successo");
      }
    } catch (error) {
      showToast.error(
        error.response?.data?.message ||
          "Errore durante il salvataggio delle impostazioni",
      );
    }
  };

  // Aggiungi questa funzione per renderizzare il link al sito
  const renderSiteLink = () => {
    if (siteSettings.alias) {
      return (
        <div className="mt-2">
          <p className="text-sm text-gray-600">
            {t("settings_site_online")}:{" "}
            <a
              href={`https://${siteSettings.alias}.guestsuite.app`}
              target="_blank"
              rel="noopener noreferrer"
              className="text-primary hover:text-primary/80 underline transition-colors"
            >
              {`${siteSettings.alias}.guestsuite.app`}
              <span className="inline-block ml-1">↗</span>
            </a>
          </p>
        </div>
      );
    }
    return null;
  };

  return (
    <div className="w-full space-y-6">
      {/* Impostazioni Sito Title */}
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-2xl font-bold">{t("settings_site")}</h2>
      </div>
      <Card className="w-full bg-white">
        <CardContent>
          <form onSubmit={handleSubmit} className="space-y-6">
            {/* URL Personalizzato */}
            <div className="space-y-2">
              <Label htmlFor="alias">
                {t("settings_site_alias")}
                <span className="text-sm text-gray-500 ml-1">
                  .guestsuite.app
                </span>
              </Label>
              <div className="relative">
                <Input
                  id="alias"
                  name="alias"
                  value={siteSettings.alias}
                  onChange={handleAliasChange}
                  className={`${aliasError ? "border-red-500" : aliasSuccess ? "border-green-500" : ""}`}
                  placeholder="casagulia"
                />
                {isCheckingAlias && (
                  <span className="absolute right-2 top-1/2 -translate-y-1/2">
                    <Spinner className="w-4 h-4" />
                  </span>
                )}
              </div>
              {aliasError && (
                <p className="text-sm text-red-500 mt-1">{aliasError}</p>
              )}
              {aliasSuccess && (
                <p className="text-sm text-green-500 mt-1">{aliasSuccess}</p>
              )}
              {renderSiteLink()}
              {isAdmin ? (
                <p className="text-sm text-gray-500 mt-1">
                  {t("settings_alias_admin_help")}
                </p>
              ) : user.alias ? (
                <p className="text-sm text-gray-500 mt-1">
                  {t("settings_alias_locked")}
                </p>
              ) : (
                <p className="text-sm text-gray-500 mt-1">
                  {t("settings_alias_help")}
                </p>
              )}
            </div>

            {/* Sezioni Visibili */}
            <div className="space-y-2 pt-6 border-t">
              <Label>{t("settings_visible_sections")}</Label>
              <div className="grid gap-2">
                <div className="flex items-center space-x-2">
                  <Checkbox
                    id="showApartments"
                    checked={siteSettings.visible_sections.apartments}
                    onCheckedChange={(checked) =>
                      setSiteSettings((prev) => ({
                        ...prev,
                        visible_sections: {
                          ...prev.visible_sections,
                          apartments: checked,
                        },
                      }))
                    }
                  />
                  <Label htmlFor="showApartments">
                    {t("settings_show_apartments")}
                  </Label>
                </div>
                <div className="flex items-center space-x-2">
                  <Checkbox
                    id="showGuides"
                    checked={siteSettings.visible_sections.guides}
                    onCheckedChange={(checked) =>
                      setSiteSettings((prev) => ({
                        ...prev,
                        visible_sections: {
                          ...prev.visible_sections,
                          guides: checked,
                        },
                      }))
                    }
                  />
                  <Label htmlFor="showGuides">
                    {t("settings_show_guides")}
                  </Label>
                </div>
              </div>
            </div>

            <div className="flex justify-end pt-6">
              <Button type="submit" className="w-full sm:w-auto">
                {t("settings_save_changes")}
              </Button>
            </div>
          </form>
        </CardContent>
      </Card>
    </div>
  );
};

export default SiteSettings;
