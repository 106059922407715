import { Plus } from "lucide-react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useApi } from "../../hooks/useApi";
import { Button } from "../ui/button";
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "../ui/dialog";
import ItemCard from "./ItemCard";
import ItemForm from "./ItemForm";

const ItemList = ({
  items = [],
  isLoading,
  error,
  onRefetch,
  itemType,
  title,
}) => {
  const [editingItem, setEditingItem] = useState(null);
  const { t } = useTranslation();

  const { apiCall } = useApi();

  if (isLoading) {
    return <p>{t("common_loading")}</p>;
  }

  if (error) {
    return (
      <p className="text-red-500">
        {t("common_error_message")}: {error.message}
      </p>
    );
  }

  const handleSubmit = async (data) => {
    await onRefetch();
    setEditingItem(null);
  };

  const handleCancel = () => {
    setEditingItem(null);
  };

  const handleAdd = () => {
    setEditingItem({});
  };

  const handleDelete = async (itemId) => {
    try {
      const data = await apiCall(
        "delete",
        `/${itemType}/${itemId}`,
        null,
        true,
      );
      onRefetch();
    } catch (error) {
      console.error("Error deleting item:", error);
    }
  };

  return (
    <>
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-2xl font-bold">{title}</h2>
        <Button onClick={handleAdd} className="flex items-center gap-1">
          <Plus className="h-4 w-4" />
          Aggiungi
        </Button>
      </div>
      <Dialog
        open={editingItem !== null}
        onOpenChange={(open) => {
          if (!open) {
            handleCancel();
          }
        }}
      >
        <DialogContent className="fixed left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%] w-[90vw] max-w-3xl max-h-[90vh] overflow-y-auto bg-background p-6 shadow-lg rounded-lg">
          <DialogHeader>
            <DialogTitle>
              {editingItem?.id ? "Modifica" : "Nuovo"}{" "}
              {itemType === "apartments" ? "Appartamento" : "Servizio"}
            </DialogTitle>
          </DialogHeader>

          <ItemForm
            editingItem={editingItem}
            onSubmit={handleSubmit}
            onCancel={handleCancel}
            itemType={itemType}
          />
        </DialogContent>
      </Dialog>
      {Array.isArray(items) && items.length > 0 ? (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          {items.map((item) => (
            <ItemCard
              key={item.id}
              item={item}
              onEdit={() => {
                setEditingItem(item);
              }}
              onDelete={() => handleDelete(item.id)}
              itemType={itemType}
            />
          ))}
        </div>
      ) : (
        <p>{t("common_no_items")}</p>
      )}
    </>
  );
};

export default ItemList;
