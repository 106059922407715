import { useCallback, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useApi } from "../../hooks/useApi";
import { Button } from "../ui/button";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "../ui/dialog";
import { Input } from "../ui/input";
import { Label } from "../ui/label";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../ui/table";
import ReservationDetail from "./ReservationDetail";
import { Trash2 } from "lucide-react";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "../ui/alert-dialog";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../ui/select";
import { Calendar } from "../ui/calendar";
import { addDays, format } from "date-fns";
import { it } from "date-fns/locale";
import { CalendarIcon } from "lucide-react";
import { Popover, PopoverContent, PopoverTrigger } from "../ui/popover";
import { cn } from "../../lib/utils";

const Reservations = ({ reservations: initialReservations, apartments, onReservationsChange }) => {
  const { t } = useTranslation();

  const [newReservation, setNewReservation] = useState({
    apartment_id: "",
    apartment_title: "",
    check_in_date: "",
    check_out_date: "",
    number_of_guests: 1,
    status: "Pending",
    main_guest_name: "",
    main_guest_surname: "",
    main_guest_telephone: "",
  });
  const [isCreatingReservation, setIsCreatingReservation] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const { apiCall } = useApi();
  const [isLoading, setIsLoading] = useState(false);
  const [localReservations, setLocalReservations] = useState(
    initialReservations || [],
  );
  const [selectedReservation, setSelectedReservation] = useState(null);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [reservationToDelete, setReservationToDelete] = useState(null);
  const [date, setDate] = useState({
    from: undefined,
    to: undefined,
  });
  const [timeFilter, setTimeFilter] = useState("future");

  useEffect(() => {
    if (date?.from) {
      setNewReservation((prev) => ({
        ...prev,
        check_in_date: format(date.from, "yyyy-MM-dd"),
        check_out_date: date?.to ? format(date.to, "yyyy-MM-dd") : "",
      }));
    }
  }, [date]);

  const fetchReservations = useCallback(async () => {
    try {
      const response = await apiCall(
        "get",
        "/reservations/get-reservations",
        null,
        true,
      );

      const sortedReservations = (response.data.reservations || []).sort(
        (a, b) => new Date(a.check_in_date) - new Date(b.check_in_date),
      );
      setLocalReservations(sortedReservations);
      return sortedReservations;
    } catch (error) {
      console.error("Error fetching reservations:", error);
    }
  }, [apiCall]);

  useEffect(() => {
    fetchReservations();
  }, [fetchReservations]);

  const handleCreateReservation = async (e) => {
    e.preventDefault();
    try {
      const reservationData = {
        apartment_id: parseInt(newReservation.apartment_id),
        apartment_title: newReservation.apartment_title,
        check_in_date: newReservation.check_in_date,
        check_out_date: newReservation.check_out_date,
        number_of_guests: parseInt(newReservation.number_of_guests),
        status: newReservation.status,
      };

      if (!reservationData.apartment_id) {
        alert(t("please_select_apartment"));
        return;
      }

      if (!reservationData.check_in_date || !reservationData.check_out_date) {
        alert(t("please_select_dates"));
        return;
      }

      const response = await apiCall(
        "post",
        "/reservations",
        reservationData,
        true,
      );

      if (response.success) {
        await fetchReservations();
        setIsCreatingReservation(false);
        setNewReservation({
          apartment_id: "",
          apartment_title: "",
          check_in_date: "",
          check_out_date: "",
          number_of_guests: 1,
          status: "Pending",
        });
        setDate({ from: undefined, to: undefined });
      }
    } catch (error) {
      console.error("Error creating reservation:", error);
      alert(t("error_creating_reservation"));
    }
  };

  const handleRefreshReservations = async () => {
    setIsLoading(true);
    try {
      await apiCall("get", "/reservations/refresh-reservations", null, true);
      const response = await fetchReservations();
      onReservationsChange?.(response);
    } catch (err) {
      console.error(t("error_refreshing_reservations"), err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleUpdateReservation = (updatedReservation) => {
    setSelectedReservation(updatedReservation);
    setLocalReservations((prevReservations) => {
      const updated = prevReservations.map((reservation) =>
        reservation.id === updatedReservation.id
          ? updatedReservation
          : reservation,
      );
      return updated.sort(
        (a, b) => new Date(a.check_in_date) - new Date(b.check_in_date),
      );
    });
  };

  const handleDeleteClick = (e, reservation) => {
    e.stopPropagation();
    setReservationToDelete(reservation);
    setIsDeleteDialogOpen(true);
  };

  const handleDeleteReservation = async () => {
    try {
      await apiCall(
        "delete",
        `/reservations/${reservationToDelete.id}`,
        null,
        true,
      );

      const updatedReservations = localReservations.filter(
        (res) => res.id !== reservationToDelete.id
      );
      setLocalReservations(updatedReservations);
      
      onReservationsChange?.(updatedReservations);
      
      setIsDeleteDialogOpen(false);
      setReservationToDelete(null);

    } catch (err) {
      console.error(t("error_deleting_reservation"), err);
    }
  };

  const filteredReservations = localReservations.filter((reservation) => {
    const searchMatch =
      searchTerm.toLowerCase() === "" ||
      reservation.apartment_title
        .toLowerCase()
        .includes(searchTerm.toLowerCase()) ||
      reservation.status.toLowerCase().includes(searchTerm.toLowerCase()) ||
      reservation.guests?.some((g) =>
        `${g.name} ${g.surname}`
          .toLowerCase()
          .includes(searchTerm.toLowerCase()),
      );

    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const checkOutDate = new Date(reservation.check_out_date);

    if (timeFilter === "past") {
      return searchMatch && checkOutDate < today;
    } else if (timeFilter === "future") {
      return searchMatch && checkOutDate >= today;
    }
    return searchMatch;
  });

  return (
    <>
      {!selectedReservation ? (
        <div className="space-y-6">
          <div className="flex flex-col sm:flex-row gap-4 sm:justify-between">
            <Button
              onClick={() => setIsCreatingReservation(true)}
              className="bg-primary hover:bg-primary/90 text-white font-medium w-full sm:w-auto"
            >
              {t("create_new_reservation")}
            </Button>

            <Button
              onClick={handleRefreshReservations}
              disabled={isLoading}
              variant="outline"
              className="border-gray-200 w-full sm:w-auto"
            >
              {isLoading
                ? t("refreshing_reservations")
                : t("refresh_reservations")}
            </Button>
          </div>

          <div className="flex flex-col sm:flex-row gap-4">
            <Input
              type="text"
              placeholder={t("search_by_apartment_name_guest_name_or_status")}
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="flex-1"
            />

            <Select value={timeFilter} onValueChange={setTimeFilter}>
              <SelectTrigger className="w-[200px]">
                <SelectValue />
              </SelectTrigger>
              <SelectContent
                position="popper"
                className="w-[--radix-select-trigger-width] bg-white dark:bg-gray-800"
              >
                <SelectItem value="all">{t("all_reservations")}</SelectItem>
                <SelectItem value="future">
                  {t("future_reservations")}
                </SelectItem>
                <SelectItem value="past">{t("past_reservations")}</SelectItem>
              </SelectContent>
            </Select>
          </div>

          {/* Desktop View */}
          <div className="hidden md:block bg-white rounded-lg border border-gray-200 overflow-hidden">
            <div className="overflow-x-auto">
              <Table className="min-w-full">
                <TableHeader>
                  <TableRow className="bg-gray-50 border-b border-gray-200">
                    <TableHead className="py-4 px-4 text-xs md:text-sm font-semibold text-gray-900">
                      {t("apartment_name")}
                    </TableHead>
                    <TableHead className="py-4 px-4 text-xs md:text-sm font-semibold text-gray-900">
                      {t("guest_full_name")}
                    </TableHead>
                    <TableHead className="py-4 px-4 text-xs md:text-sm font-semibold text-gray-900">
                      {t("stay_dates")}
                    </TableHead>
                    <TableHead className="py-4 px-4 text-xs md:text-sm font-semibold text-gray-900">
                      {t("number_of_guests")}
                    </TableHead>
                    <TableHead className="py-4 px-4 text-xs md:text-sm font-semibold text-gray-900">
                      {t("status")}
                    </TableHead>
                    <TableHead className="py-4 px-4 text-xs md:text-sm font-semibold text-gray-900">
                      {t("actions")}
                    </TableHead>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {filteredReservations.map((reservation, index) => (
                    <TableRow
                      key={reservation.id}
                      className={`
                        cursor-pointer transition-colors
                        hover:bg-gray-50
                        ${index % 2 === 0 ? "bg-white" : "bg-gray-50/50"}
                      `}
                      onClick={() => setSelectedReservation(reservation)}
                    >
                      <TableCell className="py-4 px-4 text-sm text-gray-900">
                        {reservation.apartment_title}
                      </TableCell>
                      <TableCell className="py-4 px-4 text-sm text-gray-900">
                        {
                          reservation.guests?.find(
                            (g) => g.guest_type === "main",
                          )?.name
                        }{" "}
                        {reservation.guests?.find(
                          (g) => g.guest_type === "main",
                        )?.surname || "N/A"}
                      </TableCell>
                      <TableCell className="py-4 px-4 text-sm text-gray-900">
                        <div className="flex items-center space-x-1">
                          <span>
                            {new Date(
                              reservation.check_in_date,
                            ).toLocaleDateString()}
                          </span>
                          <span className="text-gray-400">→</span>
                          <span>
                            {new Date(
                              reservation.check_out_date,
                            ).toLocaleDateString()}
                          </span>
                        </div>
                      </TableCell>
                      <TableCell className="py-4 px-4 text-sm text-gray-900">
                        {reservation.guests?.length || 0}
                      </TableCell>
                      <TableCell className="py-4 px-4">
                        <span
                          className={`
                          inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium
                          ${getStatusColor(reservation.status)}
                        `}
                        >
                          {reservation.status}
                        </span>
                      </TableCell>
                      <TableCell className="py-4 px-4">
                        <Button
                          variant="ghost"
                          size="sm"
                          onClick={(e) => handleDeleteClick(e, reservation)}
                          className="text-gray-500 hover:text-red-600 hover:bg-red-50"
                        >
                          <Trash2 className="h-4 w-4" />
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
          </div>

          {/* Mobile View */}
          <div className="md:hidden space-y-4">
            {filteredReservations.map((reservation) => (
              <div
                key={reservation.id}
                className="bg-white rounded-lg border border-gray-200 p-4 space-y-3 cursor-pointer hover:bg-gray-50"
                onClick={() => setSelectedReservation(reservation)}
              >
                <div className="flex items-center justify-between">
                  <h3 className="font-medium text-gray-900">
                    {reservation.apartment_title}
                  </h3>
                  <Button
                    variant="ghost"
                    size="sm"
                    onClick={(e) => handleDeleteClick(e, reservation)}
                    className="text-gray-500 hover:text-red-600 hover:bg-red-50"
                  >
                    <Trash2 className="h-4 w-4" />
                  </Button>
                </div>

                <div className="space-y-2">
                  <div className="flex justify-between items-center">
                    <span className="text-sm text-gray-500">
                      {t("guest_full_name")}
                    </span>
                    <span className="text-sm font-medium">
                      {
                        reservation.guests?.find((g) => g.guest_type === "main")
                          ?.name
                      }{" "}
                      {reservation.guests?.find((g) => g.guest_type === "main")
                        ?.surname || "N/A"}
                    </span>
                  </div>

                  <div className="flex justify-between items-center">
                    <span className="text-sm text-gray-500">
                      {t("stay_dates")}
                    </span>
                    <div className="text-sm font-medium flex items-center gap-1">
                      <span>
                        {new Date(
                          reservation.check_in_date,
                        ).toLocaleDateString()}
                      </span>
                      <span className="text-gray-400">→</span>
                      <span>
                        {new Date(
                          reservation.check_out_date,
                        ).toLocaleDateString()}
                      </span>
                    </div>
                  </div>

                  <div className="flex justify-between items-center">
                    <span className="text-sm text-gray-500">
                      {t("number_of_guests")}
                    </span>
                    <span className="text-sm font-medium">
                      {reservation.guests?.length || 0}
                    </span>
                  </div>

                  <div className="flex justify-between items-center">
                    <span className="text-sm text-gray-500">{t("status")}</span>
                    <span
                      className={`
                      inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium
                      ${getStatusColor(reservation.status)}
                    `}
                    >
                      {reservation.status}
                    </span>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      ) : (
        // Vista Dettaglio
        <>
          <ReservationDetail
            reservation={selectedReservation}
            apartments={apartments}
            onUpdateReservation={handleUpdateReservation}
            onClose={() => setSelectedReservation(null)}
            onBackToReservations={() => setSelectedReservation(null)}
          />
        </>
      )}

      <Dialog
        open={isCreatingReservation}
        onOpenChange={(open) => {
          if (!open) {
            setIsCreatingReservation(false);
            setDate({ from: undefined, to: undefined });
            setNewReservation({
              status: "confirmed",
              apartment_id: "",
              apartment_title: "",
              check_in_date: "",
              check_out_date: "",
              number_of_guests: 1,
            });
          }
        }}
      >
        <DialogContent className="sm:max-w-[500px]">
          <DialogHeader>
            <DialogTitle className="text-xl font-semibold text-gray-900">
              {t("create_new_reservation")}
            </DialogTitle>
          </DialogHeader>
          <form onSubmit={handleCreateReservation} className="space-y-6 mt-4">
            <div className="space-y-4">
              <div className="space-y-2">
                <Label className="text-sm font-medium text-gray-900">
                  {t("apartment_name")}
                </Label>
                <Select
                  value={newReservation.apartment_id || ""}
                  onValueChange={(value) => {
                    const selectedApartment = apartments.find(
                      (apt) => apt.id.toString() === value.toString(),
                    );
                    if (selectedApartment) {
                      setNewReservation((prev) => ({
                        ...prev,
                        apartment_id: selectedApartment.id.toString(),
                        apartment_title: selectedApartment.title,
                      }));
                    }
                  }}
                  required
                >
                  <SelectTrigger className="h-10">
                    <SelectValue placeholder={t("select_apartment")}>
                      {newReservation.apartment_title || t("select_apartment")}
                    </SelectValue>
                  </SelectTrigger>
                  <SelectContent
                    position="popper"
                    className="w-[--radix-select-trigger-width]"
                  >
                    {apartments.map((apartment) => (
                      <SelectItem
                        key={apartment.id}
                        value={apartment.id.toString()}
                        className="cursor-pointer"
                      >
                        {apartment.title}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              </div>

              <div className="space-y-2">
                <Label className="text-sm font-medium text-gray-900">
                  {t("select_dates")}
                </Label>
                <Popover>
                  <PopoverTrigger asChild>
                    <Button
                      id="date"
                      variant={"outline"}
                      className={cn(
                        "w-full justify-start text-left font-normal",
                        !date && "text-muted-foreground",
                      )}
                    >
                      <CalendarIcon className="mr-2 h-4 w-4" />
                      {date?.from ? (
                        date.to ? (
                          <>
                            {format(date.from, "LLL dd, y")} -{" "}
                            {format(date.to, "LLL dd, y")}
                          </>
                        ) : (
                          format(date.from, "LLL dd, y")
                        )
                      ) : (
                        <span>{t("pick_date_range")}</span>
                      )}
                    </Button>
                  </PopoverTrigger>
                  <PopoverContent className="w-auto p-0" align="start">
                    <div className="bg-white rounded-md border shadow-md p-3">
                      <Calendar
                        initialFocus
                        mode="range"
                        defaultMonth={date?.from}
                        selected={date}
                        onSelect={setDate}
                        numberOfMonths={2}
                        locale={it}
                        disabled={(date) => date < new Date()}
                        className="bg-white"
                      />
                    </div>
                  </PopoverContent>
                </Popover>
              </div>

              <div className="space-y-2">
                <Label className="text-sm font-medium text-gray-900">
                  {t("number_of_guests")}
                </Label>
                <Input
                  type="number"
                  value={newReservation.number_of_guests}
                  onChange={(e) =>
                    setNewReservation({
                      ...newReservation,
                      number_of_guests: parseInt(e.target.value) || 1,
                    })
                  }
                  min="1"
                  className="h-10"
                  required
                />
              </div>
            </div>

            <DialogFooter className="mt-6 gap-2">
              <Button
                type="button"
                variant="outline"
                onClick={() => setIsCreatingReservation(false)}
                className="w-full md:w-auto"
              >
                {t("cancel")}
              </Button>
              <Button
                type="submit"
                className="w-full md:w-auto bg-primary hover:bg-primary/90"
                disabled={!date?.from || !date?.to}
              >
                {t("create_reservation")}
              </Button>
            </DialogFooter>
          </form>
        </DialogContent>
      </Dialog>

      <AlertDialog
        open={isDeleteDialogOpen}
        onOpenChange={setIsDeleteDialogOpen}
      >
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>{t("confirm_deletion")}</AlertDialogTitle>
            <AlertDialogDescription>
              {t("delete_reservation_confirmation")}
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel>{t("cancel")}</AlertDialogCancel>
            <AlertDialogAction
              onClick={handleDeleteReservation}
              className="bg-red-500 hover:bg-red-600"
            >
              {t("delete")}
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};

// Aggiungi questa funzione helper per i colori degli status
const getStatusColor = (status) => {
  switch (status.toLowerCase()) {
    case "confirmed":
      return "bg-green-100 text-green-800";
    case "pending":
      return "bg-yellow-100 text-yellow-800";
    case "cancelled":
      return "bg-red-100 text-red-800";
    case "completed":
      return "bg-blue-100 text-blue-800";
    default:
      return "bg-gray-100 text-gray-800";
  }
};

export default Reservations;
