//client/src/components/Menu.js
import { Globe, Menu as MenuIcon, X } from "lucide-react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useApi } from "../hooks/useApi";
import { prefetchData } from "../hooks/useDataPrefetch";
import { getAvailableLanguages } from "../utils/dataloader";
import { Button } from "./ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "./ui/dropdown-menu";
import {
  NavigationMenu,
  NavigationMenuItem,
  NavigationMenuLink,
  NavigationMenuList,
} from "./ui/navigation-menu";
import { Sheet, SheetClose, SheetContent, SheetTrigger } from "./ui/sheet";

const Menu = ({ visibleSections }) => {
  const { t, i18n } = useTranslation();
  const [language, setLanguage] = useState(i18n.language);
  const [availableLanguages, setAvailableLanguages] = useState([]);
  const { apiCall } = useApi();

  useEffect(() => {
    getAvailableLanguages().then(setAvailableLanguages);
  }, []);

  const menuItems = [
    { label: t("menu_home"), href: "/" },
    ...(visibleSections?.apartments
      ? [{ label: t("menu_apartments"), href: "/apartments" }]
      : []),
    ...(visibleSections?.guides
      ? [{ label: t("menu_guides"), href: "/guides" }]
      : []),
  ];

  const changeLanguage = (lang) => {
    setLanguage(lang);
    i18n.changeLanguage(lang);
  };

  const handlePrefetch = (route) => {
    if (route === "/apartments") {
      prefetchData(
        "apartments/public",
        async (apiCall) =>
          await apiCall("get", "/apartments/public", null, false),
        apiCall,
      );
    } else if (route === "/guides") {
      prefetchData(
        "guides/public",
        async (apiCall) => await apiCall("get", "/guides/public", null, false),
        apiCall,
      );
    }
  };

  const LanguageSelector = ({ mobile = false }) => (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button
          variant="ghost"
          size="sm"
          className={`flex items-center justify-start w-full ${
            mobile ? "text-lg" : ""
          } text-black`}
        >
          <Globe className="mr-2" size={20} />
          {language.toUpperCase()}
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent
        className="bg-white w-full min-w-[200px]"
        align="end"
      >
        {availableLanguages.map((lang) => (
          <DropdownMenuItem
            key={lang.code}
            onClick={() => changeLanguage(lang.code)}
            className="py-2 px-4 hover:bg-gray-100"
          >
            {lang.label}
          </DropdownMenuItem>
        ))}
      </DropdownMenuContent>
    </DropdownMenu>
  );

  return (
    <nav className="fixed top-0 left-0 right-0 z-40 bg-transparent">
      <div className="container mx-auto px-4">
        <div className="flex justify-between items-center py-4">
          <Sheet>
            <SheetTrigger asChild>
              <Button
                variant="ghost"
                size="icon"
                className="md:hidden text-black"
                aria-label={t("openMenu")}
              >
                <MenuIcon size={24} />
              </Button>
            </SheetTrigger>
            <SheetContent
              side="left"
              className="w-[300px] sm:w-[400px] z-50 fixed inset-y-0 left-0 bg-white/90 backdrop-blur-md shadow-lg transition-all duration-300 ease-in-out transform"
            >
              <div className="flex flex-col h-full bg-transparent">
                <div className="flex justify-end p-4">
                  <SheetClose asChild>
                    <Button
                      variant="ghost"
                      size="icon"
                      aria-label={t("closeMenu")}
                      className="text-black hover:bg-gray-100"
                    >
                      <X size={24} />
                    </Button>
                  </SheetClose>
                </div>
                <nav className="flex flex-col space-y-4 flex-grow p-4">
                  {menuItems.map((item) => (
                    <SheetClose asChild key={item.label}>
                      <a
                        href={item.href}
                        className="text-lg text-black hover:text-blue-600 transition-colors duration-200 px-2 py-1 rounded hover:bg-gray-100"
                        onMouseEnter={() => handlePrefetch(item.href)}
                      >
                        {item.label}
                      </a>
                    </SheetClose>
                  ))}
                  <LanguageSelector mobile />
                </nav>
              </div>
            </SheetContent>
          </Sheet>

          <div className="hidden md:flex justify-center flex-grow">
            <NavigationMenu>
              <NavigationMenuList className="space-x-4">
                {menuItems.map((item) => (
                  <NavigationMenuItem key={item.label}>
                    <NavigationMenuLink
                      href={item.href}
                      className="text-black hover:text-blue-600 transition-colors duration-200 px-3 py-2 rounded-md focus:outline-none focus:ring-0 active:bg-transparent"
                      onMouseEnter={() => handlePrefetch(item.href)}
                    >
                      {item.label}
                    </NavigationMenuLink>
                  </NavigationMenuItem>
                ))}
              </NavigationMenuList>
            </NavigationMenu>
          </div>

          <div className="hidden md:block">
            <LanguageSelector />
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Menu;
