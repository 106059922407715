import { Check } from "lucide-react";
import { useState } from "react";
import { Button } from "../ui/button";
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from "../ui/card";
import { Input } from "../ui/input";
import { Label } from "../ui/label";
import { Progress } from "../ui/progress";

const OnboardingFlow = () => {
  const [step, setStep] = useState(1);
  const [userData, setUserData] = useState({
    name: "",
    email: "",
    numApartments: 1,
  });

  const totalSteps = 3;
  const progress = (step / totalSteps) * 100;

  const handleNext = () => {
    if (step < totalSteps) {
      setStep(step + 1);
    } else {
      // Logica per salvare i dati dell'utente
      console.log({ ...userData, isFirstTime: false }); // Salva i dati e imposta il flag
    }
  };

  const handlePrevious = () => {
    if (step > 1) {
      setStep(step - 1);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUserData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  return (
    <div className="w-full max-w-lg mx-auto p-4">
      <Progress value={progress} className="mb-8" />

      <Card className="w-full bg-white">
        <CardHeader>
          <CardTitle className="text-2xl font-bold text-center">
            {step === 1 && "Benvenuto! Iniziamo"}
            {step === 2 && "I tuoi dati di contatto"}
            {step === 3 && "Complimenti!"}
          </CardTitle>
        </CardHeader>

        <CardContent className="space-y-6">
          {step === 1 && (
            <div className="space-y-4">
              <div className="space-y-2">
                <Label htmlFor="name">Come ti chiami?</Label>
                <Input
                  id="name"
                  name="name"
                  placeholder="Inserisci il tuo nome"
                  value={userData.name}
                  onChange={handleInputChange}
                />
              </div>
              <div className="space-y-2">
                <Label htmlFor="numApartments">
                  Quanti appartamenti vuoi gestire?
                </Label>
                <Input
                  id="numApartments"
                  name="numApartments"
                  type="number"
                  placeholder="Numero di appartamenti"
                  value={userData.numApartments}
                  onChange={handleInputChange}
                />
              </div>
            </div>
          )}

          {step === 2 && (
            <div className="space-y-4">
              <div className="space-y-2">
                <Label htmlFor="email">Qual è la tua email?</Label>
                <Input
                  id="email"
                  name="email"
                  type="email"
                  placeholder="tu@example.com"
                  value={userData.email}
                  onChange={handleInputChange}
                />
              </div>
            </div>
          )}

          {step === 3 && (
            <div className="text-center space-y-4">
              <div className="flex justify-center mb-6">
                <div className="p-2 rounded-full bg-green-100">
                  <Check className="h-12 w-12 text-green-600" />
                </div>
              </div>
              <h3 className="text-xl font-medium">
                Benvenuto {userData.name}!
              </h3>
              <p className="text-muted-foreground">
                Il tuo account è stato configurato con successo.
              </p>
            </div>
          )}
        </CardContent>

        <CardFooter className="flex justify-between">
          {step > 1 && (
            <Button variant="outline" onClick={handlePrevious}>
              Indietro
            </Button>
          )}
          <Button onClick={handleNext} disabled={step === 1 && !userData.name}>
            {step < totalSteps ? "Continua" : "Inizia"}
          </Button>
        </CardFooter>
      </Card>
    </div>
  );
};

export default OnboardingFlow;
