//src/auth/components/Login.js
/* global google */
import React, { useState, useEffect, useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Button } from "../../components/ui/button";
import { Input } from "../../components/ui/input";
import { Separator } from "../../components/ui/separator";
import Footer from "../common/Footer";
import { useAuth } from "../../contexts/AuthContext";
import { useApi } from "../../hooks/useApi";
import { showToast } from "../../utils/toast";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isSent, setIsSent] = useState(false);
  const [magicLink, setMagicLink] = useState("");
  const navigate = useNavigate();
  const { checkAuth } = useAuth();
  const { apiCall } = useApi();

  const handleGoogleLogin = useCallback(
    async (googleResponse) => {
      console.log("Google login initiated with response:", googleResponse);

      const response = await apiCall(
        "post",
        "/auth/google",
        {
          credential: googleResponse.credential,
        },
        false,
      );
      console.log("Google login successful, received data:", response);
      localStorage.setItem("token", response.data.token);

      await checkAuth();
      console.log("Authentication check completed.");

      showToast.success("Login effettuato con successo");
      navigate("/dashboard");
      console.log("Navigated to dashboard.");
    },
    [apiCall, checkAuth, navigate],
  );

  useEffect(() => {
    const initializeGoogleButton = () => {
      const buttonElement = document.getElementById("googleButton");
      if (!window.google || !buttonElement) return;

      while (buttonElement.firstChild) {
        buttonElement.removeChild(buttonElement.firstChild);
      }

      google.accounts.id.initialize({
        client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
        callback: handleGoogleLogin,
        ux_mode: "popup",
        auto_select: false,
        cancel_on_tap_outside: true,
        itp_support: true,
        context: "signin",
        prompt_parent_id: "googleButton",
        popup_bridge_mode: true,
        native_callback: handleGoogleLogin,
        intermediate_iframe_close_callback: () => {},
      });

      google.accounts.id.renderButton(buttonElement, {
        type: "standard",
        theme: "outline",
        size: "large",
        width: buttonElement.offsetWidth,
        text: "continue_with",
        locale: "it_IT",
        shape: "rectangular",
        logo_alignment: "center",
      });

      if (/iPhone|iPad|iPod|Android/i.test(navigator.userAgent)) {
        // Rimuovi o commenta questa parte se non vuoi il prompt automatico
        // google.accounts.id.prompt((notification) => {
        //   // Gestisci le notifiche se necessario
        // });
      }
    };

    // Carica lo script di Google
    const loadGoogleScript = () => {
      const script = document.createElement("script");
      script.src = "https://accounts.google.com/gsi/client";
      script.async = true;
      script.defer = true;
      script.onload = initializeGoogleButton;
      script.onerror = () =>
        console.error("Errore nel caricamento dello script Google");
      document.head.appendChild(script);
    };

    if (!window.google) {
      loadGoogleScript();
    } else {
      initializeGoogleButton();
    }

    return () => {
      const buttonElement = document.getElementById("googleButton");
      if (buttonElement) {
        buttonElement.innerHTML = "";
      }
    };
  }, [handleGoogleLogin]);

  const handleEmailLogin = async (e) => {
    e.preventDefault();
    try {
      const backendUrl =
        process.env.REACT_APP_BACKEND_URL || "http://localhost:5000";
      const response = await fetch(`${backendUrl}/auth/magic-link`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email }),
      });

      if (!response.ok) {
        throw new Error("Errore nell'invio del magic link");
      }

      // Per sviluppo, mostra il magic link
      const fakeToken = btoa(email);
      const fakeLink = `/auth/verify?token=${fakeToken}`;
      setMagicLink(fakeLink);
      setIsSent(true);
    } catch (error) {
      console.error("Errore:", error);
      // Gestisci l'errore (mostra un messaggio all'utente)
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const data = await apiCall(
        "post",
        "auth/login",
        {
          email,
          password,
        },
        false,
      );

      localStorage.setItem("token", data.token);
      await checkAuth();
      showToast.success("Login effettuato con successo");
      navigate("/dashboard");
    } catch (error) {
      console.error("Login error:", error);

      if (error.response?.status === 403) {
        showToast.error("Account disattivato");
      } else if (error.response?.status === 401) {
        showToast.error("Credenziali non valide. Riprova.");
      } else {
        showToast.error("Errore durante il login. Riprova più tardi.");
      }
    }
  };

  return (
    <div className="min-h-screen flex flex-col">
      <div className="absolute top-4 left-4 z-10">
        <Link to="/">
          <Button
            variant="ghost"
            className="text-sm text-gray-600 hover:text-primary flex items-center gap-2"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path d="M19 12H5M12 19l-7-7 7-7" />
            </svg>
            Home
          </Button>
        </Link>
      </div>

      <div className="flex-grow flex items-center justify-center bg-white px-4 sm:px-6 lg:px-8">
        {/* Blob decorativi sullo sfondo - responsive */}

        <div className="w-full max-w-md relative">
          {!isSent ? (
            <>
              <div className="text-center mb-8 px-4">
                <h1 className="text-2xl sm:text-3xl font-bold text-gray-900 mb-2">
                  Benvenuto su GuestSuite
                </h1>
                <p className="text-sm sm:text-base text-gray-600">
                  Accedi o crea un nuovo account
                </p>
              </div>

              <div className="space-y-6">
                <div
                  id="googleButton"
                  className="w-full h-[40px] flex items-center justify-center"
                ></div>
                <div className="relative">
                  <div className="absolute inset-0 flex items-center">
                    <span className="w-full border-t" />
                  </div>
                  <div className="relative flex justify-center text-xs uppercase">
                    <span className="bg-background px-2 text-muted-foreground">
                      Or continue with
                    </span>
                  </div>
                </div>

                <form onSubmit={handleEmailLogin} className="space-y-4">
                  <Input
                    type="email"
                    placeholder="Il tuo indirizzo email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                    className="w-full h-12 px-4 border-2 rounded-lg focus:ring-primary text-base"
                  />
                  <Button
                    type="submit"
                    className="w-full h-12 bg-primary hover:bg-primary/90 text-white font-medium text-sm sm:text-base"
                  >
                    Continua con Email
                  </Button>
                </form>
              </div>
            </>
          ) : (
            <div className="text-center bg-white p-6 sm:p-8 rounded-2xl shadow-lg backdrop-blur-sm mx-4">
              <div className="text-4xl sm:text-6xl mb-4 sm:mb-6">✉️</div>
              <h3 className="text-xl sm:text-2xl font-semibold mb-3 sm:mb-4">
                Link di Accesso
              </h3>
              <p className="text-sm sm:text-base text-gray-600 mb-4 sm:mb-6">
                In produzione, invieremo un'email a:
                <br />
                <span className="font-medium">{email}</span>
              </p>

              {/* Solo per sviluppo */}
              <div className="mt-4 sm:mt-6 p-3 sm:p-4 bg-gray-50 rounded-lg text-left">
                <p className="text-xs sm:text-sm text-gray-500 mb-2">
                  Debug: Link di accesso
                </p>
                <code className="text-xs sm:text-sm break-all">
                  {magicLink}
                </code>
              </div>

              <Button
                variant="ghost"
                className="mt-4 sm:mt-6 text-sm sm:text-base"
                onClick={() => setIsSent(false)}
              >
                Usa un altro metodo
              </Button>
            </div>
          )}
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Login;
