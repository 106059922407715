import { useState, useMemo, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Button } from "../ui/button";
import { Input } from "../ui/input";
import { Label } from "../ui/label";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../ui/select";
import { CameraIcon, ArrowLeft, SaveIcon } from "lucide-react";
import { Card, CardContent, CardHeader, CardTitle } from "../ui/card";
import { useApi } from "../../hooks/useApi";
import { Check, ChevronsUpDown } from "lucide-react";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "../ui/command";
import { Popover, PopoverContent, PopoverTrigger } from "../ui/popover";
import { cn } from "../../lib/utils";

const countries = [
  { code: "AF", label: "Afghanistan" },
  { code: "AL", label: "Albania" },
  { code: "DZ", label: "Algeria" },
  { code: "AD", label: "Andorra" },
  { code: "AO", label: "Angola" },
  { code: "AR", label: "Argentina" },
  { code: "AM", label: "Armenia" },
  { code: "AU", label: "Australia" },
  { code: "AT", label: "Austria" },
  { code: "AZ", label: "Azerbaigian" },
  { code: "BE", label: "Belgio" },
  { code: "BR", label: "Brasile" },
  { code: "BG", label: "Bulgaria" },
  { code: "CA", label: "Canada" },
  { code: "CN", label: "Cina" },
  { code: "CO", label: "Colombia" },
  { code: "HR", label: "Croazia" },
  { code: "CU", label: "Cuba" },
  { code: "CY", label: "Cipro" },
  { code: "CZ", label: "Repubblica Ceca" },
  { code: "DK", label: "Danimarca" },
  { code: "EG", label: "Egitto" },
  { code: "EE", label: "Estonia" },
  { code: "FI", label: "Finlandia" },
  { code: "FR", label: "Francia" },
  { code: "DE", label: "Germania" },
  { code: "GR", label: "Grecia" },
  { code: "HU", label: "Ungheria" },
  { code: "IS", label: "Islanda" },
  { code: "IN", label: "India" },
  { code: "ID", label: "Indonesia" },
  { code: "IR", label: "Iran" },
  { code: "IQ", label: "Iraq" },
  { code: "IE", label: "Irlanda" },
  { code: "IL", label: "Israele" },
  { code: "IT", label: "Italia" },
  { code: "JP", label: "Giappone" },
  { code: "JO", label: "Giordania" },
  { code: "KZ", label: "Kazakistan" },
  { code: "KE", label: "Kenya" },
  { code: "KR", label: "Corea del Sud" },
  { code: "KW", label: "Kuwait" },
  { code: "LV", label: "Lettonia" },
  { code: "LB", label: "Libano" },
  { code: "LY", label: "Libia" },
  { code: "LI", label: "Liechtenstein" },
  { code: "LT", label: "Lituania" },
  { code: "LU", label: "Lussemburgo" },
  { code: "MT", label: "Malta" },
  { code: "MX", label: "Messico" },
  { code: "MC", label: "Monaco" },
  { code: "ME", label: "Montenegro" },
  { code: "MA", label: "Marocco" },
  { code: "NL", label: "Paesi Bassi" },
  { code: "NZ", label: "Nuova Zelanda" },
  { code: "NO", label: "Norvegia" },
  { code: "PK", label: "Pakistan" },
  { code: "PE", label: "Perù" },
  { code: "PH", label: "Filippine" },
  { code: "PL", label: "Polonia" },
  { code: "PT", label: "Portogallo" },
  { code: "QA", label: "Qatar" },
  { code: "RO", label: "Romania" },
  { code: "RU", label: "Russia" },
  { code: "SA", label: "Arabia Saudita" },
  { code: "RS", label: "Serbia" },
  { code: "SG", label: "Singapore" },
  { code: "SK", label: "Slovacchia" },
  { code: "SI", label: "Slovenia" },
  { code: "ZA", label: "Sudafrica" },
  { code: "ES", label: "Spagna" },
  { code: "SE", label: "Svezia" },
  { code: "CH", label: "Svizzera" },
  { code: "TH", label: "Thailandia" },
  { code: "TR", label: "Turchia" },
  { code: "UA", label: "Ucraina" },
  { code: "AE", label: "Emirati Arabi Uniti" },
  { code: "GB", label: "Regno Unito" },
  { code: "US", label: "Stati Uniti" },
  { code: "UY", label: "Uruguay" },
  { code: "VE", label: "Venezuela" },
  { code: "VN", label: "Vietnam" },
].sort((a, b) => a.label.localeCompare(b.label));

const validateCountry = (value) => {
  return countries.some((country) => country.code === value);
};

const GuestDetail = ({
  guest,
  isMainGuest = false,
  onBack: handleGuestDetailClose,
  onUpdate,
}) => {
  const { t } = useTranslation();
  const [guestData, setGuestData] = useState(() => {
    let birthDate = { day: "", month: "", year: "" };
    if (guest?.birth_date) {
      const [day, month, year] = guest.birth_date.split("/");
      birthDate = {
        day: day || "",
        month: month || "",
        year: year || "",
      };
    }

    let telephone = "";
    let telephonePrefix = "";
    if (guest?.telephone) {
      const [prefix, number] = guest.telephone.split("_");
      telephone = number || "";
      telephonePrefix = prefix || "";
    }

    return {
      name: guest?.name || "",
      surname: guest?.surname || "",
      birth_date: birthDate,
      gender: guest?.gender || "",
      nationality: guest?.nationality || "",
      document_type: guest?.document_type || "",
      document_number: guest?.document_number || "",
      document_country: guest?.document_country || "",
      birth_country: guest?.birth_country || "",
      residence_country: guest?.residence_country || "",
      city_of_residence: guest?.city_of_residence || "",
      address_of_residence: guest?.address_of_residence || "",
      cap_of_residence: guest?.cap_of_residence || "",
      email: guest?.email || "",
      telephone: telephone,
      telephone_prefix: telephonePrefix,
    };
  });
  const [searchValue, setSearchValue] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [documentCountrySearch, setDocumentCountrySearch] = useState("");
  const [birthCountrySearch, setBirthCountrySearch] = useState("");
  const [isDocumentCountryOpen, setIsDocumentCountryOpen] = useState(false);
  const [isBirthCountryOpen, setIsBirthCountryOpen] = useState(false);
  const [isResidenceCountryOpen, setIsResidenceCountryOpen] = useState(false);
  const [hasChanges, setHasChanges] = useState(false);
  const { apiCall } = useApi();
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (guestData.nationality) {
      const country = countries.find((c) => c.code === guestData.nationality);
      if (country) setSearchValue(country.label);
    }
    if (guestData.document_country) {
      const country = countries.find(
        (c) => c.code === guestData.document_country,
      );
      if (country) setDocumentCountrySearch(country.label);
    }
    if (guestData.birth_country) {
      const country = countries.find((c) => c.code === guestData.birth_country);
      if (country) setBirthCountrySearch(country.label);
    }
  }, []);

  const handleInputChange = (field) => (e) => {
    setHasChanges(true);
    // Rimuovi tutti gli errori
    setErrors({});

    setGuestData((prev) => {
      if (field.startsWith("birth_date.")) {
        const [parent, child] = field.split(".");
        return {
          ...prev,
          [parent]: {
            ...prev[parent],
            [child]: e.target.value,
          },
        };
      }
      return {
        ...prev,
        [field]: e.target.value,
      };
    });
  };

  const handleSelectChange = (field) => (value) => {
    setHasChanges(true);
    // Rimuovi tutti gli errori
    setErrors({});

    setGuestData((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const handleSave = async () => {
    // Reset degli errori
    setErrors({});

    // Validazione campi obbligatori
    const newErrors = {};

    if (!guestData.name?.trim()) newErrors.name = true;
    if (!guestData.surname?.trim()) newErrors.surname = true;
    if (
      !guestData.birth_date.day ||
      !guestData.birth_date.month ||
      !guestData.birth_date.year
    )
      newErrors.birth_date = true;
    if (!guestData.gender) newErrors.gender = true;
    if (!guestData.nationality) newErrors.nationality = true;
    if (!guestData.document_type) newErrors.document_type = true;
    if (!guestData.document_number?.trim()) newErrors.document_number = true;
    if (!guestData.document_country) newErrors.document_country = true;
    if (!guestData.birth_country) newErrors.birth_country = true;
    if (!guestData.residence_country) newErrors.residence_country = true;
    if (!guestData.city_of_residence?.trim())
      newErrors.city_of_residence = true;
    if (!guestData.address_of_residence?.trim())
      newErrors.address_of_residence = true;
    if (!guestData.cap_of_residence?.trim()) newErrors.cap_of_residence = true;

    // Se ci sono errori, interrompi il salvataggio
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    console.log("selected guest", guest);
    const formatBirthDate = (dateObj) => {
      const day = String(dateObj.day).padStart(2, "0");
      const month = String(dateObj.month).padStart(2, "0");
      const year = dateObj.year;
      return `${day}/${month}/${year}`;
    };

    const formattedTelephone =
      guestData.telephone_prefix && guestData.telephone
        ? `${guestData.telephone_prefix}_${guestData.telephone}`
        : "";

    const data = {
      name: guestData.name,
      surname: guestData.surname,
      birth_date: formatBirthDate(guestData.birth_date),
      gender: guestData.gender,
      nationality: guestData.nationality,
      document_type: guestData.document_type,
      document_number: guestData.document_number,
      document_country: guestData.document_country,
      birth_country: guestData.birth_country,
      residence_country: guestData.residence_country,
      address_of_residence: guestData.address_of_residence,
      residence_city: guestData.residence_city,
      city_of_residence: guestData.city_of_residence,
      cap_of_residence: guestData.cap_of_residence,
      email: guestData.email,
      telephone: formattedTelephone,
    };

    console.log("data to save guest", data);

    const response = await apiCall("PUT", `/guests/${guest.id}`, data, true);

    if (response.data.success) {
      setHasChanges(false);
      onUpdate({
        ...guest,
        ...data,
      });
    } else {
      console.error("Error saving reservation:", response.data.message);
    }
  };

  return (
    <div className="py-6 space-y-6 w-full">
      <div className="px-4 md:px-6 flex flex-col md:flex-row md:items-center justify-between gap-4">
        <div className="flex flex-col md:flex-row md:items-center gap-4">
          <Button
            variant="ghost"
            onClick={handleGuestDetailClose}
            className="flex items-center space-x-2 h-auto py-2"
          >
            <ArrowLeft className="h-4 w-4" />
            <span>Torna alla prenotazione</span>
          </Button>
          <h1 className="text-xl md:text-2xl font-bold">
            {isMainGuest ? "Ospite Principale" : "Dettagli Ospite"}
          </h1>
        </div>
      </div>

      <Card className="bg-white rounded-none md:rounded-lg mx-0 md:mx-6">
        <CardHeader>
          <CardTitle>Dati Personali</CardTitle>
        </CardHeader>
        <CardContent className="space-y-6">
          <div className="grid gap-6">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div className="space-y-2">
                <div className="flex justify-between items-center h-5">
                  <Label>Nome</Label>
                  {errors.name && (
                    <span className="text-sm text-red-500">Richiesto</span>
                  )}
                </div>
                <Input
                  value={guestData.name}
                  onChange={handleInputChange("name")}
                  placeholder="Inserisci nome"
                  className={errors.name ? "border-red-500" : ""}
                />
              </div>
              <div className="space-y-2">
                <div className="flex justify-between items-center h-5">
                  <Label>Cognome</Label>
                  {errors.surname && (
                    <span className="text-sm text-red-500">Richiesto</span>
                  )}
                </div>
                <Input
                  value={guestData.surname}
                  onChange={handleInputChange("surname")}
                  placeholder="Inserisci il cognome"
                  className={errors.surname ? "border-red-500" : ""}
                />
              </div>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
              <div className="space-y-2">
                <div className="flex justify-between items-center h-5">
                  <Label>Data di nascita</Label>
                  {errors.birth_date && (
                    <span className="text-sm text-red-500">Richiesto</span>
                  )}
                </div>
                <div className="flex gap-2">
                  <Input
                    value={guestData.birth_date.day}
                    onChange={handleInputChange("birth_date.day")}
                    placeholder="Giorno"
                    className="w-[32%]"
                  />
                  <Select
                    value={
                      guestData.birth_date.month
                        ? String(guestData.birth_date.month)
                        : undefined
                    }
                    onValueChange={(value) => {
                      setHasChanges(true);
                      setGuestData((prev) => ({
                        ...prev,
                        birth_date: {
                          ...prev.birth_date,
                          month: value,
                        },
                      }));
                    }}
                  >
                    <SelectTrigger className="w-[32%]">
                      <SelectValue placeholder="Mese">
                        {guestData.birth_date.month
                          ? String(guestData.birth_date.month).padStart(2, "0")
                          : "Mese"}
                      </SelectValue>
                    </SelectTrigger>
                    <SelectContent
                      position="popper"
                      className="w-[--radix-select-trigger-width] bg-white dark:bg-gray-800"
                    >
                      {Array.from({ length: 12 }, (_, i) => (
                        <SelectItem key={i + 1} value={String(i + 1)}>
                          {String(i + 1).padStart(2, "0")}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                  <Input
                    value={guestData.birth_date.year}
                    onChange={handleInputChange("birth_date.year")}
                    placeholder="Anno"
                    className="w-[32%]"
                  />
                </div>
              </div>
              <div className="space-y-2">
                <div className="flex justify-between items-center h-5">
                  <Label>Sesso</Label>
                  {errors.gender && (
                    <span className="text-sm text-red-500">Richiesto</span>
                  )}
                </div>
                <Select
                  value={guestData.gender}
                  onValueChange={handleSelectChange("gender")}
                  className={errors.gender ? "border-red-500" : ""}
                >
                  <SelectTrigger>
                    <SelectValue placeholder="--" />
                  </SelectTrigger>
                  <SelectContent
                    position="popper"
                    className="w-[--radix-select-trigger-width] bg-white dark:bg-gray-800"
                  >
                    <SelectItem value="M">M</SelectItem>
                    <SelectItem value="F">F</SelectItem>
                  </SelectContent>
                </Select>
              </div>
              <div className="space-y-2">
                <div className="flex justify-between items-center h-5">
                  <Label>Nazionalità</Label>
                  {errors.nationality && (
                    <span className="text-sm text-red-500">Richiesto</span>
                  )}
                </div>
                <Popover open={isOpen} onOpenChange={setIsOpen}>
                  <PopoverTrigger asChild>
                    <Button
                      variant="outline"
                      role="combobox"
                      aria-expanded={isOpen}
                      className="w-full justify-between h-10 px-3 flex items-center"
                    >
                      <span className="line-clamp-1 text-left flex-1">
                        {guestData.nationality
                          ? countries.find(
                              (country) =>
                                country.code === guestData.nationality,
                            )?.label
                          : "Seleziona paese..."}
                      </span>
                      <ChevronsUpDown className="h-4 w-4 shrink-0 opacity-50 flex-none" />
                    </Button>
                  </PopoverTrigger>
                  <PopoverContent className="w-[--radix-popover-trigger-width] p-0 bg-white dark:bg-gray-800">
                    <Command>
                      <CommandInput
                        placeholder="Cerca paese..."
                        className="h-9"
                      />
                      <CommandList>
                        <CommandEmpty>Nessun paese trovato.</CommandEmpty>
                        <CommandGroup className="max-h-[200px] overflow-auto">
                          {countries.map((country) => (
                            <CommandItem
                              key={country.code}
                              value={country.code}
                              onSelect={(currentValue) => {
                                handleSelectChange("nationality")(
                                  currentValue === guestData.nationality
                                    ? ""
                                    : currentValue,
                                );
                                setIsOpen(false);
                              }}
                              className="cursor-pointer hover:bg-accent"
                            >
                              <span className="line-clamp-1 flex-1">
                                {country.label}
                              </span>
                              <Check
                                className={cn(
                                  "ml-2 h-4 w-4 shrink-0",
                                  guestData.nationality === country.code
                                    ? "opacity-100"
                                    : "opacity-0",
                                )}
                              />
                            </CommandItem>
                          ))}
                        </CommandGroup>
                      </CommandList>
                    </Command>
                  </PopoverContent>
                </Popover>
              </div>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
              <div className="space-y-2">
                <div className="flex justify-between items-center h-5">
                  <Label>Tipo di documento</Label>
                  {errors.document_type && (
                    <span className="text-sm text-red-500">Richiesto</span>
                  )}
                </div>
                <Select
                  value={guestData.document_type}
                  onValueChange={handleSelectChange("document_type")}
                  className={errors.document_type ? "border-red-500" : ""}
                >
                  <SelectTrigger>
                    <SelectValue placeholder="--" />
                  </SelectTrigger>
                  <SelectContent
                    position="popper"
                    className="w-[--radix-select-trigger-width] bg-white dark:bg-gray-800"
                  >
                    <SelectItem value="CI">Carta d'identità</SelectItem>
                    <SelectItem value="PP">Passaporto</SelectItem>
                    <SelectItem value="PT">Patente</SelectItem>
                  </SelectContent>
                </Select>
              </div>
              <div className="space-y-2">
                <div className="flex justify-between items-center h-5">
                  <Label>Numero di documento</Label>
                  {errors.document_number && (
                    <span className="text-sm text-red-500">Richiesto</span>
                  )}
                </div>
                <Input
                  value={guestData.document_number}
                  onChange={handleInputChange("document_number")}
                  className={errors.document_number ? "border-red-500" : ""}
                />
              </div>
              <div className="space-y-2">
                <div className="flex justify-between items-center h-5">
                  <Label>Paese rilascio</Label>
                  {errors.document_country && (
                    <span className="text-sm text-red-500">Richiesto</span>
                  )}
                </div>
                <Popover
                  open={isDocumentCountryOpen}
                  onOpenChange={setIsDocumentCountryOpen}
                >
                  <PopoverTrigger asChild>
                    <Button
                      variant="outline"
                      role="combobox"
                      aria-expanded={isDocumentCountryOpen}
                      className="w-full justify-between h-10 px-3 flex items-center"
                    >
                      <span className="line-clamp-1 text-left flex-1">
                        {guestData.document_country
                          ? countries.find(
                              (country) =>
                                country.code === guestData.document_country,
                            )?.label
                          : "Seleziona paese..."}
                      </span>
                      <ChevronsUpDown className="h-4 w-4 shrink-0 opacity-50 flex-none" />
                    </Button>
                  </PopoverTrigger>
                  <PopoverContent className="w-[--radix-popover-trigger-width] p-0 bg-white dark:bg-gray-800">
                    <Command>
                      <CommandInput
                        placeholder="Cerca paese..."
                        className="h-9"
                      />
                      <CommandList>
                        <CommandEmpty>Nessun paese trovato.</CommandEmpty>
                        <CommandGroup className="max-h-[200px] overflow-auto">
                          {countries.map((country) => (
                            <CommandItem
                              key={country.code}
                              value={country.code}
                              onSelect={(currentValue) => {
                                handleSelectChange("document_country")(
                                  currentValue === guestData.document_country
                                    ? ""
                                    : currentValue,
                                );
                                setIsDocumentCountryOpen(false);
                              }}
                              className="cursor-pointer hover:bg-accent"
                            >
                              <span className="line-clamp-1 flex-1">
                                {country.label}
                              </span>
                              <Check
                                className={cn(
                                  "ml-2 h-4 w-4 shrink-0",
                                  guestData.document_country === country.code
                                    ? "opacity-100"
                                    : "opacity-0",
                                )}
                              />
                            </CommandItem>
                          ))}
                        </CommandGroup>
                      </CommandList>
                    </Command>
                  </PopoverContent>
                </Popover>
              </div>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div className="space-y-2">
                <div className="flex justify-between items-center h-5">
                  <Label>Paese di nascita</Label>
                  {errors.birth_country && (
                    <span className="text-sm text-red-500">Richiesto</span>
                  )}
                </div>
                <Popover
                  open={isBirthCountryOpen}
                  onOpenChange={setIsBirthCountryOpen}
                >
                  <PopoverTrigger asChild>
                    <Button
                      variant="outline"
                      role="combobox"
                      aria-expanded={isBirthCountryOpen}
                      className="w-full justify-between h-10 px-3 flex items-center"
                    >
                      <span className="line-clamp-1 text-left flex-1">
                        {guestData.birth_country
                          ? countries.find(
                              (country) =>
                                country.code === guestData.birth_country,
                            )?.label
                          : "Seleziona paese..."}
                      </span>
                      <ChevronsUpDown className="h-4 w-4 shrink-0 opacity-50 flex-none" />
                    </Button>
                  </PopoverTrigger>
                  <PopoverContent className="w-[--radix-popover-trigger-width] p-0 bg-white dark:bg-gray-800">
                    <Command>
                      <CommandInput
                        placeholder="Cerca paese..."
                        className="h-9"
                      />
                      <CommandList>
                        <CommandEmpty>Nessun paese trovato.</CommandEmpty>
                        <CommandGroup className="max-h-[200px] overflow-auto">
                          {countries.map((country) => (
                            <CommandItem
                              key={country.code}
                              value={country.code}
                              onSelect={(currentValue) => {
                                handleSelectChange("birth_country")(
                                  currentValue === guestData.birth_country
                                    ? ""
                                    : currentValue,
                                );
                                setIsBirthCountryOpen(false);
                              }}
                              className="cursor-pointer hover:bg-accent"
                            >
                              <span className="line-clamp-1 flex-1">
                                {country.label}
                              </span>
                              <Check
                                className={cn(
                                  "ml-2 h-4 w-4 shrink-0",
                                  guestData.birth_country === country.code
                                    ? "opacity-100"
                                    : "opacity-0",
                                )}
                              />
                            </CommandItem>
                          ))}
                        </CommandGroup>
                      </CommandList>
                    </Command>
                  </PopoverContent>
                </Popover>
              </div>
              <div className="space-y-2">
                <div className="flex justify-between items-center h-5">
                  <Label>Paese di residenza</Label>
                  {errors.residence_country && (
                    <span className="text-sm text-red-500">Richiesto</span>
                  )}
                </div>
                <Popover
                  open={isResidenceCountryOpen}
                  onOpenChange={setIsResidenceCountryOpen}
                >
                  <PopoverTrigger asChild>
                    <Button
                      variant="outline"
                      role="combobox"
                      aria-expanded={isResidenceCountryOpen}
                      className="w-full justify-between h-10 px-3 flex items-center"
                    >
                      <span className="line-clamp-1 text-left flex-1">
                        {guestData.residence_country
                          ? countries.find(
                              (country) =>
                                country.code === guestData.residence_country,
                            )?.label
                          : "Seleziona paese..."}
                      </span>
                      <ChevronsUpDown className="h-4 w-4 shrink-0 opacity-50 flex-none" />
                    </Button>
                  </PopoverTrigger>
                  <PopoverContent className="w-[--radix-popover-trigger-width] p-0 bg-white dark:bg-gray-800">
                    <Command>
                      <CommandInput
                        placeholder="Cerca paese..."
                        className="h-9"
                      />
                      <CommandList>
                        <CommandEmpty>Nessun paese trovato.</CommandEmpty>
                        <CommandGroup className="max-h-[200px] overflow-auto">
                          {countries.map((country) => (
                            <CommandItem
                              key={country.code}
                              value={country.code}
                              onSelect={(currentValue) => {
                                handleSelectChange("residence_country")(
                                  currentValue === guestData.residence_country
                                    ? ""
                                    : currentValue,
                                );
                                setIsResidenceCountryOpen(false);
                              }}
                              className="cursor-pointer hover:bg-accent"
                            >
                              <span className="line-clamp-1 flex-1">
                                {country.label}
                              </span>
                              <Check
                                className={cn(
                                  "ml-2 h-4 w-4 shrink-0",
                                  guestData.residence_country === country.code
                                    ? "opacity-100"
                                    : "opacity-0",
                                )}
                              />
                            </CommandItem>
                          ))}
                        </CommandGroup>
                      </CommandList>
                    </Command>
                  </PopoverContent>
                </Popover>
              </div>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
              <div className="space-y-2">
                <div className="flex justify-between items-center h-5">
                  <Label>Comune di residenza</Label>
                  {errors.city_of_residence && (
                    <span className="text-sm text-red-500">Richiesto</span>
                  )}
                </div>
                <Input
                  value={guestData.city_of_residence}
                  onChange={handleInputChange("city_of_residence")}
                  className={errors.city_of_residence ? "border-red-500" : ""}
                />
              </div>
              <div className="space-y-2">
                <div className="flex justify-between items-center h-5">
                  <Label>Indirizzo di residenza</Label>
                  {errors.address_of_residence && (
                    <span className="text-sm text-red-500">Richiesto</span>
                  )}
                </div>
                <Input
                  value={guestData.address_of_residence}
                  onChange={handleInputChange("address_of_residence")}
                  className={
                    errors.address_of_residence ? "border-red-500" : ""
                  }
                />
              </div>
              <div className="space-y-2">
                <div className="flex justify-between items-center h-5">
                  <Label>CAP</Label>
                  {errors.cap_of_residence && (
                    <span className="text-sm text-red-500">Richiesto</span>
                  )}
                </div>
                <Input
                  value={guestData.cap_of_residence}
                  onChange={handleInputChange("cap_of_residence")}
                  className={errors.cap_of_residence ? "border-red-500" : ""}
                />
              </div>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div className="space-y-2">
                <div className="flex justify-between items-center h-5">
                  <Label>Email (facoltativo)</Label>
                </div>
                <Input
                  type="email"
                  value={guestData.email}
                  onChange={handleInputChange("email")}
                />
              </div>
              <div className="space-y-2">
                <div className="flex justify-between items-center h-5">
                  <Label>Telefono (facoltativo)</Label>
                </div>
                <div className="flex gap-2">
                  <Select
                    value={guestData.telephone_prefix}
                    onValueChange={handleSelectChange("telephone_prefix")}
                  >
                    <SelectTrigger className="w-24">
                      <SelectValue placeholder="--" />
                    </SelectTrigger>
                    <SelectContent
                      position="popper"
                      className="w-[--radix-select-trigger-width]"
                    >
                      <SelectItem value="+39">+39</SelectItem>
                    </SelectContent>
                  </Select>
                  <Input
                    value={guestData.telephone}
                    onChange={handleInputChange("telephone")}
                  />
                </div>
              </div>
            </div>
          </div>
        </CardContent>
      </Card>

      {hasChanges && (
        <div className="fixed bottom-0 left-0 right-0 p-4 flex justify-center">
          <Button onClick={handleSave} className="flex items-center gap-2">
            <SaveIcon className="h-4 w-4" />
            Salva Modifiche
          </Button>
        </div>
      )}
    </div>
  );
};

export default GuestDetail;
