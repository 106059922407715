const Logo = ({ className = "", size = 24 }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinejoin="round"
      strokeLinecap="round"
      className={className}
    >
      {/* Tetto (spostato più in alto) */}
      <path d="M2 9L12 2L22 9" />
      {/* Pareti (mantenute alla stessa posizione) */}
      <path d="M5 10V20H19V10" />
    </svg>
  );
};

export default Logo;
