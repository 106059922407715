// src/components/GuideDetail.js

import axios from "axios";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { API_BASE_URL } from "../config";
import Menu from "./Menu";

const GuideDetail = ({ visibleSections }) => {
  const { t, i18n } = useTranslation();
  const { id } = useParams();
  const [guide, setGuide] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchGuide = async () => {
      try {
        const fetchedGuide = await axios.get(
          `${API_BASE_URL}/guides/public/${id}`,
        );
        setGuide(fetchedGuide.data);
        setLoading(false);
      } catch (err) {
        console.error("Error fetching guide:", err);
        setError(t("guide.fetchError"));
        setLoading(false);
      }
    };

    fetchGuide();
  }, [id, i18n.language, t]);

  if (loading) return <div>{t("guide.loading")}</div>;
  if (error) return <div>{error}</div>;
  if (!guide) return <div>{t("guide.notFound")}</div>;

  return (
    <div className="min-h-screen bg-gradient-to-r from-blue-100 to-green-100">
      <Menu visibleSections={visibleSections} />
      <header className="mb-6 sm:mb-10 text-center pt-8 sm:pt-16 px-4">
        <h1 className="text-2xl sm:text-4xl font-bold text-blue-800 mb-2">
          {guide.translations[i18n.language]?.title || guide.title}
        </h1>
        <p className="text-lg sm:text-xl text-gray-600">
          {guide.translations[i18n.language]?.description || guide.description}
        </p>
      </header>

      <main className="w-full">
        {guide.image && (
          <div className="mb-6 sm:mb-8">
            <img
              src={guide.image}
              alt={guide.translations[i18n.language]?.title || guide.title}
              className="w-full h-64 sm:h-96 object-cover shadow-md"
            />
          </div>
        )}

        <div className="w-full">
          {/* Content Section */}
          <div className="bg-white shadow-sm">
            <div
              className="content-text p-4 sm:p-6"
              dangerouslySetInnerHTML={{
                __html:
                  guide.translations[i18n.language]?.content || guide.content,
              }}
            />
          </div>

          {/* Additional Info Section */}
          {guide.additionalInfo && (
            <div className="bg-blue-50">
              <div className="p-4 sm:p-6">
                <h2 className="text-xl sm:text-2xl font-semibold text-blue-800 mb-4">
                  {t("guide.additionalInfo")}
                </h2>
                <div className="text-gray-700">
                  {guide.translations[i18n.language]?.additionalInfo ||
                    guide.additionalInfo}
                </div>
              </div>
            </div>
          )}

          {/* Tags Section */}
          {guide.tags && guide.tags.length > 0 && (
            <div className="p-4 sm:p-6">
              <h3 className="text-lg font-medium text-gray-700 mb-2">
                {t("guide.tags")}
              </h3>
              <div className="flex flex-wrap gap-2">
                {guide.tags.map((tag, index) => (
                  <span
                    key={index}
                    className="px-3 py-1 bg-blue-100 text-blue-700 rounded-full text-sm"
                  >
                    {tag}
                  </span>
                ))}
              </div>
            </div>
          )}
        </div>
      </main>

      <footer className="mt-8 sm:mt-12 text-center text-gray-600 pb-8 px-4">
        <p className="text-sm sm:text-base">{t("common_footer")}</p>
      </footer>
    </div>
  );
};

export default GuideDetail;
