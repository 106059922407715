import { Globe } from "lucide-react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "../contexts/ThemeContext";
import { getAvailableLanguages } from "../utils/dataloader";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "./ui/dropdown-menu";
import { Tooltip, TooltipContent, TooltipTrigger } from "./ui/tooltip";

const LanguageSelector = () => {
  // Fixed the function declaration
  const [availableLanguages, setAvailableLanguages] = useState([]);
  const { isDarkMode } = useTheme(); // Removed toggleTheme as it is not used

  const { t, i18n } = useTranslation();

  useEffect(() => {
    getAvailableLanguages().then(setAvailableLanguages);
  }, []);

  return (
    <DropdownMenu>
      <Tooltip delayDuration={0}>
        <DropdownMenuTrigger
          className={`flex items-center w-fit p-2 rounded-lg transition-colors text-sm font-medium ${
            isDarkMode
              ? "text-white hover:cursor-pointer"
              : "text-black hover:cursor-pointer"
          }`}
        >
          <TooltipTrigger asChild>
            <Globe size={20} />
          </TooltipTrigger>
          <TooltipContent>
            <p>{t("language_tooltip")}</p>
          </TooltipContent>
        </DropdownMenuTrigger>
      </Tooltip>

      <DropdownMenuContent>
        {availableLanguages.map((lang) => (
          <DropdownMenuItem
            key={lang.code}
            onClick={() => {
              i18n.changeLanguage(lang.code);
            }}
            className="cursor-pointer hover-white" // Utilizzo della classe CSS personalizzata
          >
            {lang.label}
          </DropdownMenuItem>
        ))}
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

export default LanguageSelector;
