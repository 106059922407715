import React from "react";

const Footer = () => {
  return (
    <footer className="w-full py-2 text-center text-xs text-gray-400 bg-transparent">
      <p>
        Created and developed by{" "}
        <a
          href="https://www.linkedin.com/in/simone-ruggiero"
          target="_blank"
          rel="noopener noreferrer"
          className="font-medium hover:text-primary transition-colors"
        >
          Simone Ruggiero
        </a>
      </p>
    </footer>
  );
};

export default Footer;
