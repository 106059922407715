// src/App.js

import React, { useEffect, useState } from "react";
import Guides from "./components/Guides";

import { Route, BrowserRouter as Router, Routes } from "react-router-dom";

import { I18nextProvider } from "react-i18next";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ApartmentDetail from "./components/ApartmentDetail";
import Apartments from "./components/Apartments";
import Login from "./components/auth/Login";
import ProtectedRoute from "./components/auth/ProtectedRoute";
import Register from "./components/auth/Register";
import Dashboard from "./components/dashboard/Dashboard";
import GuideDetail from "./components/GuideDetail";
import LandingPage from "./components/marketing/LandingPage";
import NotFound from "./components/NotFound";
import { AuthProvider } from "./contexts/AuthContext";
import i18n from "./i18n";

import { GoogleOAuthProvider } from "@react-oauth/google";
import HomePage from "./components/Home";
import { TooltipProvider } from "./components/ui/tooltip";
import { useApi } from "./hooks/useApi";
import Help from "./components/marketing/Help";
import GuestRegistration from "./components/public/GuestRegistration";

const MainApp = () => {
  const [isValidSubdomain, setIsValidSubdomain] = useState(false);
  const [visibleSections, setVisibleSections] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const { apiCall } = useApi();

  const hostname = window.location.hostname;
  const isSubdomain = hostname.includes("localhost")
    ? hostname.split(".")[0] !== "localhost"
    : hostname.split(".").length > 2 && !hostname.startsWith("www.");
  const subdomain = isSubdomain ? hostname.split(".")[0] : null;

  useEffect(() => {
    const verifySubdomain = async () => {
      if (isSubdomain && subdomain !== "help") {
        try {
          const response = await apiCall(
            "get",
            `/users/public/settings/${subdomain}`,
          );
          if (response.success) {
            setIsValidSubdomain(true);
            setVisibleSections(response.visible_sections || {});
          } else {
            setIsValidSubdomain(false);
            const isDevelopment = process.env.NODE_ENV === "development";
            const mainDomain = isDevelopment
              ? `localhost:3000`
              : hostname.split(".").slice(-2).join(".");

            window.location.href = `${window.location.protocol}//${mainDomain}`;
          }
        } catch (error) {
          console.error("Error verifying subdomain:", error);
          setIsValidSubdomain(false);
          const isDevelopment = process.env.NODE_ENV === "development";
          const mainDomain = isDevelopment
            ? `localhost:3000`
            : hostname.split(".").slice(-2).join(".");

          window.location.href = `${window.location.protocol}//${mainDomain}`;
        }
      } else {
        setIsValidSubdomain(true);
        setVisibleSections({});
      }
      setIsLoading(false);
    };

    verifySubdomain();
  }, [isSubdomain, subdomain]);

  // Mostriamo un loader mentre verifichiamo il sottodominio
  if (isLoading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  // Se siamo su un sottodominio valido, mostra il sito dell'host specifico
  if (isSubdomain) {
    if (subdomain === "help") {
      return (
        <Routes>
          <Route path="/" element={<Help />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      );
    }

    if (isValidSubdomain) {
      return (
        <Routes>
          <Route
            path="/"
            element={<HomePage visibleSections={visibleSections} />}
          />
          {visibleSections?.guides && (
            <>
              <Route
                path="/guides"
                element={<Guides visibleSections={visibleSections} />}
              />
              <Route
                path="/guides/:id"
                element={<GuideDetail visibleSections={visibleSections} />}
              />
            </>
          )}
          {visibleSections?.apartments && (
            <>
              <Route
                path="/apartments"
                element={<Apartments visibleSections={visibleSections} />}
              />
              <Route
                path="/apartments/:id"
                element={<ApartmentDetail visibleSections={visibleSections} />}
              />
            </>
          )}
          <Route
            path="*"
            element={<NotFound visibleSections={visibleSections} />}
          />
        </Routes>
      );
    }
  }

  // Se siamo sul dominio principale
  return (
    <Routes>
      <Route path="/" element={<LandingPage />} />
      <Route path="/login" element={<Login />} />
      <Route path="/register" element={<Register />} />
      <Route path="/dashboard/*" element={<Dashboard />} />
      <Route
        path="/reservation/bookingcode/:bookingCode"
        element={<GuestRegistration />}
      />
      <Route
        path="/reservation/:reservationId"
        element={<GuestRegistration />}
      />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

const App = () => {
  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
      <AuthProvider>
        <I18nextProvider i18n={i18n}>
          <TooltipProvider>
            <Router>
              <MainApp />
            </Router>
          </TooltipProvider>
        </I18nextProvider>
      </AuthProvider>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </GoogleOAuthProvider>
  );
};

export default App;
