import { format, isValid } from "date-fns";
import { it } from "date-fns/locale";
import {
  BarChart,
  CalendarIcon,
  PhoneIcon,
  PlusCircleIcon,
  SaveIcon,
  ShoppingCart,
  UserCheck,
  UserIcon,
  Pencil,
  Trash2,
  Copy,
  ExternalLink,
} from "lucide-react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { cn } from "../../lib/utils";
import { Button } from "../ui/button";
import { Calendar } from "../ui/calendar";
import { Card, CardContent, CardHeader, CardTitle } from "../ui/card";
import { Input } from "../ui/input";
import { Label } from "../ui/label";
import { Popover, PopoverContent, PopoverTrigger } from "../ui/popover";
import { ScrollArea } from "../ui/scroll-area";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../ui/select";

import { useApi } from "../../hooks/useApi";
import GuestDetail from "./GuestDetail";

import {
  AlertDialog,
  AlertDialogTrigger,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogCancel,
  AlertDialogAction,
} from "../../components/ui/alert-dialog";

import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../ui/tooltip";

import { useToast } from "../ui/use-toast";

// Funzione helper definita fuori dal componente
const getValidGuestsCount = (guests) => {
  return Array.isArray(guests)
    ? guests.filter((guest) => guest && !guest.deleted).length
    : 0;
};

const ReservationDetail = ({
  reservation: initialReservation,
  apartments = [],
  onUpdateReservation,
  onBackToReservations,
}) => {
  const { t } = useTranslation();
  const [isEditingGuest, setIsEditingGuest] = useState(false);
  const [selectedGuest, setSelectedGuest] = useState(null);
  const [checkInDate, setCheckInDate] = useState(null);
  const [checkOutDate, setCheckOutDate] = useState(null);
  const [selectedApartment, setSelectedApartment] = useState(
    initialReservation.apartment_id,
  );
  const [registration_type, setRegistration_type] = useState(
    initialReservation.registration_type || "singolo",
  );
  const [isModified, setIsModified] = useState(false);
  const { apiCall } = useApi();
  const [reservation, setReservation] = useState(initialReservation);
  const [formData, setFormData] = useState({});
  const [showGuestDetail, setShowGuestDetail] = useState(false);
  const [pendingGuestCount, setPendingGuestCount] = useState(
    getValidGuestsCount(initialReservation.guests),
  );
  const { toast } = useToast();

  const parseDate = (dateString) => {
    if (!dateString) return null;
    const date = new Date(dateString);
    return isValid(date) ? date : null;
  };

  useEffect(() => {
    const inDate = parseDate(initialReservation.check_in_date);
    const outDate = parseDate(initialReservation.check_out_date);

    setCheckInDate(inDate);
    setCheckOutDate(outDate);
  }, [initialReservation.check_in_date, initialReservation.check_out_date]);

  const formatDate = (date) => {
    if (!date || !isValid(date)) return "";
    return format(date, "dd/MM/yyyy", { locale: it });
  };

  const handleSave = async () => {
    try {
      // Aggiorna il numero di ospiti se necessario
      if (pendingGuestCount !== getValidGuestsCount(reservation.guests)) {
        await handleGuestCountChange(pendingGuestCount);
      }

      // Aggiorna la reservation
      const response = await apiCall(
        "put",
        `/reservations/${reservation.id}`,
        {
          ...reservation,
          number_of_guests: pendingGuestCount,
        },
        true,
      );

      if (response.data.success) {
        // Aggiorna lo stato locale con i dati aggiornati
        setReservation(response.data.reservation);
        // Aggiorna pendingGuestCount con il nuovo numero di ospiti
        setPendingGuestCount(
          getValidGuestsCount(response.data.reservation.guests),
        );
        setIsModified(false);

        toast({
          title: "Success",
          description: "Reservation updated successfully",
        });
      }
    } catch (error) {
      console.error("Error saving reservation:", error);
      toast({
        variant: "destructive",
        title: "Error",
        description: "Failed to save reservation",
      });
    }
  };

  const handleEditGuest = (guest) => {
    setSelectedGuest(guest);
    setShowGuestDetail(true);
  };

  const handleGuestDetailClose = () => {
    setShowGuestDetail(false);
    setSelectedGuest(null);
  };

  const handleDateChange = (date, type) => {
    if (type === "check-in") {
      const newDate = new Date(date);
      setCheckInDate(newDate);
    } else {
      const newDate = new Date(date);
      setCheckOutDate(newDate);
    }
    console.log("nuova check-in", checkInDate);
    console.log("nuova check-out", checkOutDate);
    setIsModified(true);
  };

  const statusItems = [
    {
      title: "Registrazione ospite",
      icon: ShoppingCart,
      value: `${initialReservation.registered_guests || 0}/${initialReservation.total_guests || 0}`,
    },
    {
      title: "Polizia (check-in)",
      icon: UserCheck,
      value: `${initialReservation.police_registered || 0} ospiti in attesa di essere registrati automaticamente`,
    },
    {
      title: "ISTAT (check-in)",
      icon: BarChart,
      value: `${initialReservation.istat_checkin || 0} ospiti in attesa di essere registrati automaticamente`,
    },
    {
      title: "ISTAT (check-out)",
      icon: BarChart,
      value: `${initialReservation.istat_checkout || 0} ospiti in attesa di essere registrati automaticamente`,
    },
  ];

  const handleApartmentChange = (newApartmentId) => {
    setSelectedApartment(newApartmentId);
    // Qui potresti voler aggiornare altri stati o fare una chiamata API
    console.log(`Apartment changed to: ${newApartmentId}`);
  };

  // Effetto per aggiornare automaticamente il tipo di registrazione quando cambia il numero di ospiti
  useEffect(() => {
    const guestsCount = reservation.guests?.length || 0;
    if (guestsCount === 1 && registration_type !== "singolo") {
      setRegistration_type("singolo");
    } else if (guestsCount > 1 && registration_type === "singolo") {
      setRegistration_type("gruppo");
    }
  }, [reservation.guests]);

  const handleInputChange = (setter) => (e) => {
    const newValue = e.target.value;
    setter(newValue);

    // Update only the number_of_guests in the reservation state
    // without modifying the guests array
    setReservation((prev) => ({
      ...prev,
      number_of_guests: newValue,
    }));

    setIsModified(true);
  };

  useEffect(() => {
    setReservation(initialReservation);
  }, [initialReservation]);

  useEffect(() => {
    const mainGuest = initialReservation.guests?.find(
      (guest) => guest.guest_type === "main",
    );
    setReservation({
      ...initialReservation,
      main_guest_name: mainGuest?.name || "",
      main_guest_surname: mainGuest?.surname || "",
      main_guest_telephone: mainGuest?.telephone || "",
    });
  }, [initialReservation]);

  // Modify generateGuestRows to use the original guests array
  const generateGuestRows = () => {
    // Ordina gli ospiti con l'ospite principale per primo
    const sortedGuests = [...(reservation.guests || [])].sort((a, b) => {
      if (a.guest_type === "main") return -1;
      if (b.guest_type === "main") return 1;
      return 0;
    });

    return sortedGuests.map((guest, index) => (
      <tr
        key={index}
        className="hover:bg-gray-50 cursor-pointer"
        onClick={() => handleEditGuest(guest)}
      >
        <td className="px-6 py-3">
          {guest.name ? (
            <span>{`${guest.name} ${guest.surname || ""}`}</span>
          ) : (
            <span className="text-gray-400">[Ospite mancante]</span>
          )}
        </td>
        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
          {guest.name ? (
            guest.police_status ? (
              <span className="text-green-600">✓ Registrato</span>
            ) : (
              <span className="text-yellow-600">In attesa</span>
            )
          ) : (
            <span></span>
          )}
        </td>
        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
          {guest.name ? (
            guest.istat_checkin ? (
              <span className="text-green-600">✓ Registrato</span>
            ) : (
              <span className="text-yellow-600">In attesa</span>
            )
          ) : (
            <span></span>
          )}
        </td>
        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
          {guest.name ? (
            guest.istat_checkout ? (
              <span className="text-green-600">✓ Registrato</span>
            ) : (
              <span className="text-yellow-600">In attesa</span>
            )
          ) : (
            <span></span>
          )}
        </td>
        <td
          className="px-6 py-4 whitespace-nowrap text-sm text-gray-900"
          onClick={(e) => e.stopPropagation()} // Prevent row click when clicking actions
        >
          <div className="flex gap-2">
            <Button
              onClick={(e) => {
                e.stopPropagation();
                handleEditGuest(guest);
              }}
              variant="ghost"
              size="icon"
              className="h-8 w-8 flex items-center justify-center"
            >
              <Pencil className="h-4 w-4" />
            </Button>
            {guest.guest_type !== "main" && (
              <AlertDialog>
                <AlertDialogTrigger asChild>
                  <Button
                    onClick={(e) => e.stopPropagation()}
                    variant="ghost"
                    size="icon"
                    className="h-8 w-8 text-red-600 hover:text-red-700 hover:bg-red-50 flex items-center justify-center"
                  >
                    <Trash2 className="h-4 w-4" />
                  </Button>
                </AlertDialogTrigger>
                <AlertDialogContent>
                  <AlertDialogHeader>
                    <AlertDialogTitle>Conferma eliminazione</AlertDialogTitle>
                    <AlertDialogDescription>
                      Sei sicuro di voler eliminare questo ospite?
                    </AlertDialogDescription>
                  </AlertDialogHeader>
                  <AlertDialogFooter>
                    <AlertDialogCancel>Annulla</AlertDialogCancel>
                    <AlertDialogAction
                      onClick={() => handleDeleteGuest(guest.id)}
                    >
                      Conferma
                    </AlertDialogAction>
                  </AlertDialogFooter>
                </AlertDialogContent>
              </AlertDialog>
            )}
          </div>
        </td>
      </tr>
    ));
  };

  const handleGuestUpdate = (updatedGuest) => {
    // Aggiorna l'array degli ospiti con i dati aggiornati
    const updatedGuests = reservation.guests.map((guest) =>
      guest.id === updatedGuest.id ? updatedGuest : guest,
    );

    setReservation((prev) => ({
      ...prev,
      guests: updatedGuests,
    }));
  };

  const handleDeleteGuest = async (guestId) => {
    if (!guestId) return;

    const response = await apiCall("DELETE", `/guests/${guestId}`, null, true);

    if (response.data.success) {
      // Update the local state by removing the deleted guest
      setReservation((prev) => {
        const updatedGuests = prev.guests.filter((g) => g.id !== guestId);
        return {
          ...prev,
          guests: updatedGuests,
          number_of_guests: updatedGuests.length,
        };
      });
    } else {
      console.error("Error deleting guest:", response.message);
    }
  };

  const handleAddGuest = async () => {
    try {
      const newGuest = {
        name: "",
        surname: "",
        guest_type: "additional",
        police_status: false,
        istat_checkin: false,
        istat_checkout: false,
        reservation_id: reservation.id,
      };

      const response = await apiCall("POST", `/guests`, newGuest, true);
      console.log("response guest", response);

      if (response.data.success) {
        const addedGuest = response.data.guest;
        setReservation((prev) => ({
          ...prev,
          guests: [...prev.guests, addedGuest],
          number_of_guests: prev.guests.length + 1,
        }));
      } else {
        console.error("Error adding guest:", response.message);
      }
    } catch (error) {
      console.error("Error adding guest:", error);
    }
  };

  const handleCopyLink = () => {
    const bookingLink = `${window.location.origin}/reservation/${reservation.id}`;
    navigator.clipboard.writeText(bookingLink);
  };

  const handleGuestCountChange = async (newCount) => {
    const currentCount = reservation.guests?.length || 0;

    if (newCount > currentCount) {
      // Aggiunge nuovi ospiti
      for (let i = 0; i < newCount - currentCount; i++) {
        await handleAddGuest();
      }
    } else if (newCount < currentCount) {
      // Rimuove ospiti dalla fine della lista, escludendo l'ospite principale
      const guestsToRemove = reservation.guests
        .filter((guest) => guest.guest_type !== "main")
        .slice(newCount - currentCount);

      for (const guest of guestsToRemove) {
        await handleDeleteGuest(guest.id);
      }
    }
  };

  if (showGuestDetail) {
    return (
      <GuestDetail
        guest={selectedGuest}
        isMainGuest={selectedGuest?.guest_type === "main"}
        onBack={handleGuestDetailClose}
        onUpdate={handleGuestUpdate}
      />
    );
  }

  return (
    <div className="space-y-6">
      <div className="mb-4">
        <Button
          variant="outline"
          onClick={onBackToReservations}
          className="flex items-center gap-2"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path d="M19 12H5M12 19l-7-7 7-7" />
          </svg>
          {t("back_to_reservations")}
        </Button>
      </div>

      {/* Header with Save Button */}
      <div className="flex justify-center items-center w-full py-4">
        <h1 className="text-2xl font-bold">{reservation.apartment_title}</h1>
      </div>

      {/* Status Card */}
      <Card className="bg-white shadow-sm">
        <CardHeader>
          <CardTitle>Status della prenotazione</CardTitle>
        </CardHeader>
        <CardContent>
          <div className="grid grid-cols-2 gap-4 sm:grid-cols-2 lg:grid-cols-4">
            {statusItems.map((item, index) => (
              <Card key={index} className="bg-white shadow-sm">
                <CardContent className="pt-6">
                  <div className="flex flex-col items-center text-center">
                    <div className="p-2 bg-primary/10 rounded-full mb-4">
                      <item.icon className="h-6 w-6 text-primary" />
                    </div>
                    <h3 className="font-semibold mb-1">{item.title}</h3>
                    <p className="text-sm text-muted-foreground mb-4">
                      {item.value}
                    </p>
                    {item.action && (
                      <a
                        href={item.actionHref}
                        className="text-primary hover:underline text-sm"
                      >
                        {item.action}
                      </a>
                    )}
                  </div>
                </CardContent>
              </Card>
            ))}
          </div>
        </CardContent>
      </Card>

      {/* Informazioni sulla prenotazione */}
      <Card className="bg-white shadow-sm">
        <CardHeader>
          <CardTitle>Informazioni sulla prenotazione</CardTitle>
        </CardHeader>
        <CardContent>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {/* Alloggio - occupa due colonne */}
            <div className="space-y-2 lg:col-span-2">
              <Label>Alloggio</Label>
              <Select
                value={selectedApartment}
                onValueChange={handleApartmentChange}
              >
                <SelectTrigger className="w-full">
                  <SelectValue placeholder="Seleziona alloggio" />
                </SelectTrigger>
                <SelectContent
                  position="popper"
                  className="w-[--radix-select-trigger-width] bg-white dark:bg-gray-800"
                >
                  {apartments && apartments.length > 0 ? (
                    apartments.map((apartment) => (
                      <SelectItem key={apartment.id} value={apartment.id}>
                        {apartment.title}
                      </SelectItem>
                    ))
                  ) : (
                    <SelectItem value={initialReservation.apartment_id}>
                      {initialReservation.apartment_title}
                    </SelectItem>
                  )}
                </SelectContent>
              </Select>
            </div>

            {/* Tipo di registrazione */}
            <div className="space-y-2">
              <Label>Tipo di registrazione</Label>
              <Select
                value={registration_type}
                onValueChange={setRegistration_type}
                disabled={reservation.guests?.length === 1}
              >
                <SelectTrigger className="w-full">
                  <SelectValue placeholder="Seleziona tipo" />
                </SelectTrigger>
                <SelectContent
                  position="popper"
                  className="w-[--radix-select-trigger-width] bg-white dark:bg-gray-800"
                >
                  {reservation.guests?.length === 1 ? (
                    <SelectItem value="singolo">Singolo</SelectItem>
                  ) : (
                    <>
                      <SelectItem value="gruppo">Gruppo</SelectItem>
                      <SelectItem value="famiglia">Famiglia</SelectItem>
                    </>
                  )}
                </SelectContent>
              </Select>
            </div>

            {/* Check-in e Check-out - occupa due colonne */}
            <div className="space-y-2 lg:col-span-2">
              <Label>{t("select_dates")}</Label>
              <Popover>
                <PopoverTrigger asChild>
                  <Button
                    variant={"outline"}
                    className={cn(
                      "w-full justify-start text-left font-normal",
                      !checkInDate && "text-muted-foreground",
                    )}
                  >
                    <CalendarIcon className="mr-2 h-4 w-4" />
                    {checkInDate ? (
                      checkOutDate ? (
                        <>
                          {format(checkInDate, "LLL dd, y", { locale: it })} -{" "}
                          {format(checkOutDate, "LLL dd, y", { locale: it })}
                        </>
                      ) : (
                        format(checkInDate, "LLL dd, y", { locale: it })
                      )
                    ) : (
                      <span>{t("pick_date_range")}</span>
                    )}
                  </Button>
                </PopoverTrigger>
                <PopoverContent className="w-auto p-0" align="start">
                  <div className="bg-white rounded-md border shadow-md p-3">
                    <Calendar
                      initialFocus
                      mode="range"
                      defaultMonth={checkInDate || new Date()}
                      selected={{
                        from: checkInDate,
                        to: checkOutDate,
                      }}
                      onSelect={(range) => {
                        if (range?.from) {
                          handleDateChange(range.from, "check-in");
                        }
                        if (range?.to) {
                          handleDateChange(range.to, "check-out");
                        }
                      }}
                      numberOfMonths={2}
                      locale={it}
                      disabled={(date) => date < new Date()}
                    />
                  </div>
                </PopoverContent>
              </Popover>
            </div>

            {/* Ospiti da registrare */}
            <div className="space-y-2">
              <Label>Ospiti da registrare</Label>
              <Input
                type="number"
                min="1"
                value={pendingGuestCount || ""}
                onChange={(e) => {
                  if (e.target.value === "") {
                    setPendingGuestCount("");
                    setIsModified(true);
                    return;
                  }

                  const value = Math.max(1, parseInt(e.target.value, 10) || 1);
                  setPendingGuestCount(value);
                  setIsModified(true);
                }}
                onBlur={(e) => {
                  const value = Math.max(1, parseInt(e.target.value, 10) || 1);
                  setPendingGuestCount(value);
                }}
                onKeyDown={(e) => {
                  const allowedKeys = [
                    "Backspace",
                    "Delete",
                    "Tab",
                    "Enter",
                    "ArrowLeft",
                    "ArrowRight",
                  ];
                  if (!allowedKeys.includes(e.key) && !/^[0-9]$/.test(e.key)) {
                    e.preventDefault();
                  }
                }}
                className="h-10 [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
              />
            </div>

            {/* Fonte della prenotazione */}
            <div className="space-y-2">
              <Label>
                Fonte della prenotazione{" "}
                <span className="text-sm text-muted-foreground">
                  - Facoltativo
                </span>
              </Label>
              <Select
                value={reservation?.booking_source || ""}
                onValueChange={(value) => {
                  setReservation((prev) => ({
                    ...prev,
                    booking_source: value,
                  }));
                  setIsModified(true);
                }}
              >
                <SelectTrigger className="w-full h-10">
                  <SelectValue placeholder="Seleziona fonte" />
                </SelectTrigger>
                <SelectContent
                  position="popper"
                  className="w-[--radix-select-trigger-width] bg-white dark:bg-gray-800"
                >
                  <SelectItem value="airbnb">Airbnb</SelectItem>
                  <SelectItem value="booking">Booking</SelectItem>
                  <SelectItem value="direct">Diretta</SelectItem>
                </SelectContent>
              </Select>
            </div>

            {/* Link prenotazione */}
            <div className="space-y-2 lg:col-span-2">
              <Label>
                Link prenotazione
                <span className="text-sm text-muted-foreground ml-2">
                  - Il link supporta anche il formato /reservation/
                  {selectedApartment}/{reservation?.booking_reference}
                </span>
              </Label>
              <div className="flex items-center gap-2">
                <Input
                  readOnly
                  value={`${window.location.origin}/reservation/${reservation?.id}`}
                  className="h-10 bg-gray-50"
                />
                <TooltipProvider>
                  <Tooltip>
                    <TooltipTrigger asChild>
                      <Button
                        onClick={handleCopyLink}
                        variant="outline"
                        size="icon"
                        className="h-10 w-10"
                      >
                        <Copy className="h-4 w-4" />
                      </Button>
                    </TooltipTrigger>
                    <TooltipContent>
                      <p>Copia link</p>
                    </TooltipContent>
                  </Tooltip>
                </TooltipProvider>
                <TooltipProvider>
                  <Tooltip>
                    <TooltipTrigger asChild>
                      <Button
                        onClick={() =>
                          window.open(
                            `${window.location.origin}/reservation/${reservation?.id}`,
                            "_blank",
                          )
                        }
                        variant="outline"
                        size="icon"
                        className="h-10 w-10"
                      >
                        <ExternalLink className="h-4 w-4" />
                      </Button>
                    </TooltipTrigger>
                    <TooltipContent>
                      <p>Apri link</p>
                    </TooltipContent>
                  </Tooltip>
                </TooltipProvider>
              </div>
            </div>
          </div>
        </CardContent>
      </Card>

      {/* Additional Guests Section */}
      <Card className="bg-white dark:bg-white shadow-sm">
        <CardHeader className="flex flex-row items-center justify-between">
          <CardTitle>{t("additional_guests_details")}</CardTitle>
          <Button
            onClick={handleAddGuest}
            variant="outline"
            className="flex items-center gap-2"
          >
            <PlusCircleIcon className="h-4 w-4" />
            {t("add_guest")}
          </Button>
        </CardHeader>
        <CardContent>
          <ScrollArea className="h-[300px] pr-4">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    {t("guest_name")}
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    {t("police_checkin_status")}
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    {t("istat_checkin")}
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    {t("istat_checkout")}
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    {t("actions")}
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {generateGuestRows()}
              </tbody>
            </table>
          </ScrollArea>
        </CardContent>
      </Card>
      {/* Main Guest Section */}
      <Card className="bg-white dark:bg-white shadow-sm">
        <CardHeader>
          <CardTitle>{t("main_guest_details")}</CardTitle>
        </CardHeader>
        <CardContent className="grid gap-6">
          <div className="grid grid-cols-2 gap-4">
            <div className="space-y-2">
              <Label>{t("name")}</Label>
              <Input
                value={reservation.main_guest_name || ""}
                onChange={(e) => {
                  setReservation({
                    ...reservation,
                    main_guest_name: e.target.value,
                  });
                  setIsModified(true);
                }}
                icon={<UserIcon className="h-4 w-4" />}
              />
            </div>
            <div className="space-y-2">
              <Label>{t("surname")}</Label>
              <Input
                value={reservation.main_guest_surname || ""}
                onChange={(e) => {
                  setReservation({
                    ...reservation,
                    main_guest_surname: e.target.value,
                  });
                  setIsModified(true);
                }}
              />
            </div>

            <div className="space-y-2">
              <Label>{t("phone")}</Label>
              <Input
                value={reservation.main_guest_telephone || ""}
                onChange={(e) => {
                  setReservation({
                    ...reservation,
                    main_guest_telephone: e.target.value,
                  });
                  setIsModified(true);
                }}
                icon={<PhoneIcon className="h-4 w-4" />}
              />
            </div>
          </div>
        </CardContent>
      </Card>

      {/* Bottone Salva Modifiche */}
      {isModified && (
        <div className="fixed bottom-0 left-0 right-0 p-4 flex justify-center">
          <Button onClick={handleSave} className="flex items-center gap-2">
            <SaveIcon className="h-4 w-4" />
            Salva Modifiche
          </Button>
        </div>
      )}
    </div>
  );
};

export default ReservationDetail;
