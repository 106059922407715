import { useState, useEffect } from "react";

import { useTranslation } from "react-i18next";
import { useParams, useNavigate } from "react-router-dom";
import { useApi } from "../../hooks/useApi";
import { Button } from "../ui/button";
import { Card, CardContent, CardHeader, CardTitle } from "../ui/card";
import { Check, Pencil } from "lucide-react";
import { Label } from "../../components/ui/label";
import { Input } from "../../components/ui/input";
import { format } from "date-fns";
import { it } from "date-fns/locale";
import { Calendar } from "../ui/calendar";
import { CalendarIcon } from "lucide-react";
import { Popover, PopoverContent, PopoverTrigger } from "../ui/popover";
import { cn } from "../../lib/utils";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../ui/select";
import { Alert, AlertDescription } from "../ui/alert";
import { Badge } from "../ui/badge";
import NotFound from "../NotFound";

// GuestDetail Component
const GuestDetail = ({ guest, onSubmit, isMain }) => {
  const { t } = useTranslation();
  const { apiCall } = useApi();
  const [guestData, setGuestData] = useState(guest || {});
  const [birthDate, setBirthDate] = useState(
    guest?.birth_date ? new Date(guest.birth_date) : undefined,
  );

  const handleSubmit = (e) => {
    e.preventDefault();
    const updatedGuestData = {
      ...guestData,
      birth_date: birthDate ? format(birthDate, "yyyy-MM-dd") : null,
    };
    onSubmit(updatedGuestData);
  };

  const handleInputChange = (field, value) => {
    setGuestData((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-6">
      {/* Nome e Cognome */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <div className="space-y-2">
          <Label htmlFor="name">{t("first_name")}</Label>
          <Input
            id="name"
            value={guestData.name || ""}
            onChange={(e) => handleInputChange("name", e.target.value)}
            required
          />
        </div>
        <div className="space-y-2">
          <Label htmlFor="surname">{t("last_name")}</Label>
          <Input
            id="surname"
            value={guestData.surname || ""}
            onChange={(e) => handleInputChange("surname", e.target.value)}
            required
          />
        </div>
      </div>

      {/* Data di nascita */}
      <div className="space-y-2">
        <Label>{t("birth_date")}</Label>
        <Popover>
          <PopoverTrigger asChild>
            <Button
              variant={"outline"}
              className={cn(
                "w-full justify-start text-left font-normal",
                !birthDate && "text-muted-foreground",
              )}
            >
              <CalendarIcon className="mr-2 h-4 w-4" />
              {birthDate ? (
                format(birthDate, "PPP", { locale: it })
              ) : (
                <span>{t("pick_date")}</span>
              )}
            </Button>
          </PopoverTrigger>
          <PopoverContent className="w-auto p-0 bg-white" align="start">
            <Calendar
              mode="single"
              selected={birthDate}
              onSelect={setBirthDate}
              disabled={(date) => date > new Date()}
              initialFocus
              locale={it}
            />
          </PopoverContent>
        </Popover>
      </div>

      {/* Nazionalità */}
      <div className="space-y-2">
        <Label>{t("nationality")}</Label>
        <Select
          value={guestData.nationality || ""}
          onValueChange={(value) => handleInputChange("nationality", value)}
          required
        >
          <SelectTrigger>
            <SelectValue placeholder={t("select_nationality")} />
          </SelectTrigger>
          <SelectContent
            position="popper"
            className="w-[--radix-select-trigger-width] bg-white dark:bg-gray-800"
          >
            <SelectItem value="IT">Italia</SelectItem>
            <SelectItem value="FR">Francia</SelectItem>
            <SelectItem value="DE">Germania</SelectItem>
            <SelectItem value="UK">Regno Unito</SelectItem>
            <SelectItem value="ES">Spagna</SelectItem>
            {/* Add more countries as needed */}
          </SelectContent>
        </Select>
      </div>

      {/* Documento */}
      <div className="space-y-6">
        <div className="space-y-2">
          <Label>{t("document_type")}</Label>
          <Select
            value={guestData.document_type || ""}
            onValueChange={(value) => handleInputChange("document_type", value)}
            required
          >
            <SelectTrigger>
              <SelectValue placeholder={t("select_document_type")} />
            </SelectTrigger>
            <SelectContent
              position="popper"
              className="w-[--radix-select-trigger-width] bg-white dark:bg-gray-800"
            >
              <SelectItem value="ID">Carta d'identità</SelectItem>
              <SelectItem value="PASSPORT">Passaporto</SelectItem>
              <SelectItem value="DRIVING">Patente</SelectItem>
            </SelectContent>
          </Select>
        </div>

        <div className="space-y-2">
          <Label>{t("document_number")}</Label>
          <Input
            value={guestData.document_number || ""}
            onChange={(e) =>
              handleInputChange("document_number", e.target.value)
            }
            required
          />
        </div>
      </div>

      {/* Contatti (solo per ospite principale) */}
      {isMain && (
        <div className="space-y-6">
          <div className="space-y-2">
            <Label>{t("email")}</Label>
            <Input
              type="email"
              value={guestData.email || ""}
              onChange={(e) => handleInputChange("email", e.target.value)}
              required
            />
          </div>
          <div className="space-y-2">
            <Label>{t("phone")}</Label>
            <Input
              type="tel"
              value={guestData.phone || ""}
              onChange={(e) => handleInputChange("phone", e.target.value)}
              required
            />
          </div>
        </div>
      )}

      <Button type="submit" className="w-full">
        {t("save_and_continue")}
      </Button>
    </form>
  );
};

const formatDate = (dateString) => {
  if (!dateString) return null;
  try {
    // Converti la stringa ISO in oggetto Date
    const date = new Date(dateString);
    if (isNaN(date.getTime())) {
      console.error("Invalid date:", dateString);
      return null;
    }
    return date;
  } catch (error) {
    console.error("Error formatting date:", error);
    return null;
  }
};

const GuestRegistration = () => {
  const { t } = useTranslation();
  const { apiCall } = useApi();
  const { reservationId, bookingCode } = useParams();
  const navigate = useNavigate();

  const [currentStep, setCurrentStep] = useState(0);
  const [contractSigned, setContractSigned] = useState(false);
  const [birthDate, setBirthDate] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [token, setToken] = useState(
    localStorage.getItem("guest_token") || null,
  );
  const [reservation, setReservation] = useState({
    guests: [
      {
        guest_type: "main",
        name: "",
        surname: "",
        nationality: "",
        document_type: "",
        document_number: "",
        email: "",
        phone: "",
      },
    ],
  });
  const [mainGuest, setMainGuest] = useState(() => {
    const existingMainGuest = reservation.guests.find(
      (g) => g.guest_type === "main",
    );
    return (
      existingMainGuest || {
        name: "",
        surname: "",
        nationality: "",
        document_type: "",
        document_number: "",
        email: "",
        phone: "",
      }
    );
  });
  const [additionalGuests, setAdditionalGuests] = useState(() => {
    return reservation.guests
      .filter((g) => g.guest_type === "additional")
      .map((g) => ({
        name: g.name || "",
        surname: g.surname || "",
        nationality: g.nationality || "",
        document_type: g.document_type || "",
        document_number: g.document_number || "",
      }));
  });
  const [currentAdditionalGuest, setCurrentAdditionalGuest] = useState(0);
  const [additionalGuestData, setAdditionalGuestData] = useState({
    name: "",
    surname: "",
    nationality: "",
    document_type: "",
    document_number: "",
  });
  const [additionalGuestBirthDate, setAdditionalGuestBirthDate] = useState();

  const steps = [
    { id: 0, title: t("welcome") },
    { id: 1, title: t("confirm_guests_number") },
    { id: 2, title: t("main_guest_and_contract") },
    { id: 3, title: t("additional_guests") },
  ];

  const handleBack = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };

  const handleStartCheckIn = () => {
    setCurrentStep(1);
  };

  const numberOfGuests = reservation.guests?.length || 1;

  const handleConfirmGuestNumber = async () => {
    try {
      // Crea un nuovo array di ospiti con la lunghezza desiderata
      const currentMainGuest = reservation.guests.find(
        (g) => g.guest_type === "main",
      );
      const currentGuests = [...reservation.guests];

      // Se stiamo aggiungendo nuovi ospiti
      if (guestNumberInput > currentGuests.length) {
        // Per ogni nuovo ospite necessario
        for (let i = currentGuests.length; i < guestNumberInput; i++) {
          // Crea il nuovo ospite nel backend
          const response = await apiCall(
            "post",
            `/reservations/public/${reservationId}/guests`,
            {
              guest_type: i === 0 ? "main" : "additional",
              name: "",
              surname: "",
              nationality: "",
              document_type: "",
              document_number: "",
              ...(i === 0 && { email: "", phone: "" }),
            },
            false,
            true,
          );

          if (!response.success) {
            throw new Error(response.message || "Failed to create guest");
          }

          currentGuests.push(response.guest);
        }
      } else if (guestNumberInput < currentGuests.length) {
        // Se stiamo riducendo il numero di ospiti, rimuovi gli ultimi
        // Nota: potrebbe essere necessario implementare questa logica nel backend
        const guestsToRemove = currentGuests.slice(guestNumberInput);
        for (const guest of guestsToRemove) {
          await apiCall(
            "delete",
            `/guests/public/${guest.id}`,
            null,
            false,
            true,
          );
        }
        currentGuests.splice(guestNumberInput);
      }

      // Aggiorna lo stato locale con i nuovi ospiti
      setReservation((prev) => ({
        ...prev,
        guests: currentGuests,
      }));

      setCurrentStep(2);
    } catch (error) {
      console.error("Error updating guests:", error);
      alert(t("error_updating_guests"));
    }
  };

  const handleMainGuestInputChange = (field, value) => {
    setMainGuest((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const handleGuestSubmit = async (e) => {
    e.preventDefault();

    // Se è l'ospite principale, verifica che il contratto sia firmato
    const isMainGuest = currentStep === 2;

    // Prepara i dati dell'ospite
    const guestData = isMainGuest ? mainGuest : additionalGuestData;
    const guestBirthDate = isMainGuest ? birthDate : additionalGuestBirthDate;
    const currentGuestId = isMainGuest
      ? reservation.guests.find((g) => g.guest_type === "main")?.id
      : reservation.guests[currentAdditionalGuest + 1]?.id;

    const formData = {
      ...guestData,
      birth_date: guestBirthDate ? format(guestBirthDate, "yyyy-MM-dd") : null,
      guest_type: isMainGuest ? "main" : "additional",
    };

    const success = await handleUpdateGuest(currentGuestId, formData);
    if (success) {
      // Reset dei form data
      if (!isMainGuest) {
        setAdditionalGuestData({
          name: "",
          surname: "",
          nationality: "",
          document_type: "",
          document_number: "",
        });
        setAdditionalGuestBirthDate(undefined);

        // Se ci sono altri ospiti da registrare, passa al prossimo
        if (currentAdditionalGuest < guestNumberInput - 2) {
          setCurrentAdditionalGuest((prev) => prev + 1);
        } else {
          // Altrimenti, torna alla schermata iniziale
          setCurrentStep(0);
        }
      } else {
        // Se è l'ospite principale e ci sono altri ospiti, passa al prossimo step
        if (guestNumberInput > 1) {
          setCurrentStep(3);
        } else {
          // Se è l'unico ospite, torna alla schermata iniziale
          setCurrentStep(0);
        }
      }
    }
  };

  const handleUpdateGuest = async (guestId, updatedData) => {
    try {
      const response = await apiCall(
        "put",
        `/guests/public/${guestId}`,
        updatedData,
        false,
        true,
      );

      if (!response.success) {
        throw new Error(response.message || "Failed to update guest");
      }

      // Aggiorna lo stato locale con i nuovi dati
      setReservation((prev) => ({
        ...prev,
        guests: prev.guests.map((g) =>
          g.id === guestId ? { ...g, ...updatedData } : g,
        ),
      }));

      return true;
    } catch (error) {
      console.error("Error updating guest:", error);
      // Qui potresti aggiungere una notifica di errore
      return false;
    }
  };

  const handleAdditionalGuestInputChange = (field, value) => {
    setAdditionalGuestData((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const handleEditGuest = async (guest) => {
    if (guest.guest_type === "main") {
      setMainGuest(guest);
      if (guest.birth_date) {
        setBirthDate(new Date(guest.birth_date));
      }
      setCurrentStep(2);
    } else {
      setAdditionalGuestData(guest);
      if (guest.birth_date) {
        setAdditionalGuestBirthDate(new Date(guest.birth_date));
      }
      const guestIndex = reservation.guests.findIndex((g) => g.id === guest.id);
      setCurrentAdditionalGuest(guestIndex - 1);
      setCurrentStep(3);
    }
  };

  // Fetch della prenotazione
  useEffect(() => {
    const fetchReservation = async () => {
      try {
        let response;

        // Se abbiamo bookingCode e apartmentId, prima otteniamo l'ID della prenotazione
        if (bookingCode) {
          response = await apiCall(
            "GET",
            `/reservations/public/bookingcode/${bookingCode}`,
          );

          console.log("Response in fetchReservation:", response);
          if (response.data.success && response.data.reservation) {
            // Redirect alla URL con l'ID della prenotazione
            navigate(`/reservation/${response.data.reservation.id}`, {
              replace: true,
            });
            return;
          }
        } else if (reservationId) {
          // Altrimenti, carica direttamente usando l'ID
          response = await apiCall(
            "GET",
            `/reservations/public/${reservationId}`,
          );
        }

        if (response?.success && response.data.reservation) {
          setReservation(response.data.reservation);

          // Salva il token se presente
          const authHeader = response.headers?.authorization;
          if (authHeader && authHeader.startsWith("Bearer ")) {
            const token = authHeader.split(" ")[1];
            localStorage.setItem("guest_token", token);
          }
        } else {
          setError(t("reservation_not_found"));
        }
      } catch (error) {
        console.log("Error fetching reservation:", error);
        if (error.response?.status === 404) {
          setError(t("reservation_not_found"));
          navigate("/not-found", { replace: true });
        } else {
          console.log("Error fetching reservation:", error.message);
          setError(t("error_loading_reservation"));
        }
      } finally {
        setLoading(false);
      }
    };

    fetchReservation();
  }, [reservationId, bookingCode, navigate]);

  // Aggiungi un effetto per monitorare i cambiamenti dello stato reservation
  useEffect(() => {
    console.log("Current reservation state:", reservation);
  }, [reservation]);

  // Aggiungi uno stato per gestire il numero di ospiti input
  const [guestNumberInput, setGuestNumberInput] = useState(
    reservation.guests.length || 1,
  );

  // Aggiungi un componente per mostrare il progresso della registrazione
  const renderProgress = () => {
    if (currentStep !== 3) return null;

    return (
      <div className="mb-6 text-center">
        <h3 className="text-lg font-medium mb-2">
          {t("registering_guest")} {currentAdditionalGuest + 2} {t("of")}{" "}
          {guestNumberInput}
        </h3>
        <div className="w-full bg-gray-200 rounded-full h-2.5">
          <div
            className="bg-blue-600 h-2.5 rounded-full transition-all duration-300"
            style={{
              width: `${((currentAdditionalGuest + 1) / (guestNumberInput - 1)) * 100}%`,
            }}
          ></div>
        </div>
      </div>
    );
  };

  // Mostra loading state
  if (loading) {
    return (
      <div className="container mx-auto px-4 h-[calc(100vh-4rem)] flex items-center justify-center">
        <div className="text-center">
          <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-600 mx-auto"></div>
          <p className="mt-4 text-gray-600">Caricamento...</p>
        </div>
      </div>
    );
  }

  return (
    <div className="container mx-auto px-4 h-[calc(100vh-4rem)]">
      <Card className="h-full overflow-auto">
        <CardHeader>
          <CardTitle className="text-2xl font-bold mb-8">
            Registrazione Ospiti
          </CardTitle>

          {/* Progress Steps - Show only after welcome screen */}
          {currentStep > 0 && (
            <div className="flex items-center justify-center w-full max-w-md mx-auto mt-4">
              {steps.slice(1).map((step, index) => (
                <div key={step.id} className="flex items-center">
                  <div
                    className={`
                    w-10 h-10 rounded-full flex items-center justify-center text-lg font-medium
                    ${
                      index === currentStep - 1
                        ? "bg-blue-600 text-white"
                        : index < currentStep - 1
                          ? "bg-green-500 text-white"
                          : "bg-gray-200 text-gray-500"
                    }
                  `}
                  >
                    {index + 1}
                  </div>
                  {index < steps.length - 2 && (
                    <div
                      className={`
                      w-24 h-[2px] mx-2
                      ${index < currentStep - 1 ? "bg-green-500" : "bg-gray-200"}
                    `}
                    />
                  )}
                </div>
              ))}
            </div>
          )}
        </CardHeader>

        <CardContent className="p-6">
          {/* Welcome Screen - Step 0 */}
          {currentStep === 0 && (
            <div className="flex flex-col items-center justify-center min-h-[60vh] text-center">
              <div className="max-w-2xl">
                <h1 className="text-6xl font-bold leading-tight mb-6 bg-gradient-to-r from-blue-600 to-blue-400 bg-clip-text text-transparent">
                  Facciamo
                  <br />
                  il check-in!
                </h1>
                <p className="text-xl text-gray-600 mb-8">
                  Completa il check-in online per evitare attese al tuo arrivo
                </p>
                <Button
                  onClick={handleStartCheckIn}
                  className="bg-blue-600 text-white px-12 py-6 rounded-full text-xl font-semibold hover:bg-blue-700 transition-colors"
                >
                  Inizia il Check-in
                </Button>

                {/* Display registered guests */}
                {reservation.guests.length > 0 && (
                  <div className="mt-8">
                    <h2 className="text-2xl font-semibold mb-4">
                      Ospiti Registrati
                    </h2>
                    <div className="space-y-4">
                      {reservation.guests.map((guest, index) => (
                        <Card key={index} className="text-left">
                          <CardHeader className="pb-2">
                            <div className="flex justify-between items-center">
                              <div>
                                <Badge className="mb-2">
                                  {guest.guest_type === "main"
                                    ? "Ospite Principale"
                                    : "Ospite Aggiuntivo"}
                                </Badge>
                                <CardTitle className="text-xl">
                                  {guest.name} {guest.surname}
                                </CardTitle>
                              </div>
                              <Button
                                variant="ghost"
                                size="icon"
                                onClick={() => handleEditGuest(guest)}
                                className="h-8 w-8"
                              >
                                <Pencil className="h-4 w-4" />
                              </Button>
                            </div>
                          </CardHeader>
                          <CardContent>
                            <div className="grid grid-cols-2 gap-2 text-sm">
                              <div>
                                <strong>Nazionalità:</strong>{" "}
                                {guest.nationality}
                              </div>
                              <div>
                                <strong>Documento:</strong>{" "}
                                {guest.document_type}
                              </div>
                              <div>
                                <strong>Numero:</strong> {guest.document_number}
                              </div>
                              {guest.birth_date && (
                                <div>
                                  <strong>Data di nascita:</strong>{" "}
                                  {format(
                                    new Date(guest.birth_date),
                                    "dd/MM/yyyy",
                                  )}
                                </div>
                              )}
                              {guest.guest_type === "main" && (
                                <>
                                  <div>
                                    <strong>Email:</strong> {guest.email}
                                  </div>
                                  <div>
                                    <strong>Telefono:</strong> {guest.phone}
                                  </div>
                                </>
                              )}
                            </div>
                          </CardContent>
                        </Card>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}

          {/* Guest Number Selection - Step 1 */}
          {currentStep === 1 && (
            <form
              id="form-step-1"
              onSubmit={(e) => {
                e.preventDefault();
                handleConfirmGuestNumber();
              }}
              className="max-w-xl mx-auto space-y-8 py-8"
            >
              <div className="text-center mb-12">
                <h2 className="text-3xl font-semibold mb-4">
                  Numero di ospiti
                </h2>
                <p className="text-gray-600">
                  Conferma il numero di ospiti che soggiorneranno
                </p>
                {reservation.guests.length > 0 && (
                  <Alert className="mt-4">
                    <AlertDescription>
                      <div className="flex items-center justify-center gap-2">
                        <Badge variant="secondary">
                          {reservation.guests.length} ospiti registrati
                        </Badge>
                        <span>su</span>
                        <Badge>{guestNumberInput} totali</Badge>
                      </div>
                    </AlertDescription>
                  </Alert>
                )}
              </div>

              <div className="bg-white p-8 rounded-lg shadow-sm border">
                <div className="flex flex-col space-y-4">
                  <Label className="text-2xl font-medium">Numero Ospiti</Label>
                  <Input
                    type="number"
                    value={guestNumberInput}
                    onChange={(e) => {
                      const value = Math.max(
                        1,
                        Math.min(10, parseInt(e.target.value) || 1),
                      );
                      setGuestNumberInput(value);
                    }}
                    min={1}
                    max={10}
                    required
                    className="text-xl text-center h-12"
                  />
                </div>
              </div>

              <div className="flex justify-between gap-4 mt-8">
                <Button
                  type="button"
                  variant="outline"
                  onClick={handleBack}
                  className="w-full"
                >
                  Indietro
                </Button>
                <Button
                  type="submit"
                  className="w-full bg-blue-600 hover:bg-blue-700"
                >
                  Continua
                </Button>
              </div>
            </form>
          )}

          {/* Main Guest Form with Contract - Step 2 */}
          {currentStep === 2 && (
            <form
              id="form-step-2"
              onSubmit={handleGuestSubmit}
              className="max-w-2xl mx-auto space-y-8 py-8"
            >
              <div className="text-center mb-12">
                <h2 className="text-3xl font-semibold mb-4">
                  {t("main_guest_details")}
                </h2>
              </div>

              <div className="bg-white p-8 rounded-lg shadow-sm border space-y-6">
                {/* Nome e Cognome */}
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                  <div className="space-y-2">
                    <Label htmlFor="name">{t("name")}</Label>
                    <Input
                      id="name"
                      name="name"
                      value={mainGuest.name}
                      onChange={(e) =>
                        handleMainGuestInputChange("name", e.target.value)
                      }
                      required
                    />
                  </div>
                  <div className="space-y-2">
                    <Label htmlFor="surname">{t("surname")}</Label>
                    <Input
                      id="surname"
                      name="surname"
                      value={mainGuest.surname}
                      onChange={(e) =>
                        handleMainGuestInputChange("surname", e.target.value)
                      }
                      required
                    />
                  </div>
                </div>

                {/* Data di nascita */}
                <div className="space-y-2">
                  <Label>{t("birth_date")}</Label>
                  <Popover>
                    <PopoverTrigger asChild>
                      <Button
                        variant={"outline"}
                        className={cn(
                          "w-full justify-start text-left font-normal",
                          !birthDate && "text-muted-foreground",
                        )}
                      >
                        <CalendarIcon className="mr-2 h-4 w-4" />
                        {birthDate ? (
                          format(birthDate, "PPP", { locale: it })
                        ) : (
                          <span>{t("pick_date")}</span>
                        )}
                      </Button>
                    </PopoverTrigger>
                    <PopoverContent
                      className="w-auto p-0 bg-white"
                      align="start"
                    >
                      <Calendar
                        mode="single"
                        selected={birthDate}
                        onSelect={setBirthDate}
                        disabled={(date) => date > new Date()}
                        initialFocus
                        locale={it}
                      />
                    </PopoverContent>
                  </Popover>
                </div>

                {/* Nazionalità */}
                <div className="space-y-2">
                  <Label>{t("nationality")}</Label>
                  <Select
                    value={mainGuest.nationality}
                    onValueChange={(value) =>
                      handleMainGuestInputChange("nationality", value)
                    }
                    required
                  >
                    <SelectTrigger>
                      <SelectValue placeholder={t("select_nationality")} />
                    </SelectTrigger>
                    <SelectContent
                      position="popper"
                      className="w-[--radix-select-trigger-width] bg-white dark:bg-gray-800"
                    >
                      <SelectItem value="IT">Italia</SelectItem>
                      <SelectItem value="FR">Francia</SelectItem>
                      <SelectItem value="DE">Germania</SelectItem>
                      <SelectItem value="UK">Regno Unito</SelectItem>
                      <SelectItem value="ES">Spagna</SelectItem>
                    </SelectContent>
                  </Select>
                </div>

                {/* Documento */}
                <div className="space-y-6">
                  <div className="space-y-2">
                    <Label>{t("document_type")}</Label>
                    <Select
                      value={mainGuest.document_type}
                      onValueChange={(value) =>
                        handleMainGuestInputChange("document_type", value)
                      }
                      required
                    >
                      <SelectTrigger>
                        <SelectValue placeholder={t("select_document_type")} />
                      </SelectTrigger>
                      <SelectContent
                        position="popper"
                        className="w-[--radix-select-trigger-width] bg-white dark:bg-gray-800"
                      >
                        <SelectItem value="ID">Carta d'identità</SelectItem>
                        <SelectItem value="PASSPORT">Passaporto</SelectItem>
                        <SelectItem value="DRIVING">Patente</SelectItem>
                      </SelectContent>
                    </Select>
                  </div>

                  <div className="space-y-2">
                    <Label>{t("document_number")}</Label>
                    <Input
                      value={mainGuest.document_number}
                      onChange={(e) =>
                        handleMainGuestInputChange(
                          "document_number",
                          e.target.value,
                        )
                      }
                      required
                    />
                  </div>
                </div>

                {/* Contatti */}
                <div className="space-y-6">
                  <div className="space-y-2">
                    <Label>{t("email")}</Label>
                    <Input
                      type="email"
                      value={mainGuest.email}
                      onChange={(e) =>
                        handleMainGuestInputChange("email", e.target.value)
                      }
                      required
                    />
                  </div>
                  <div className="space-y-2">
                    <Label>{t("phone")}</Label>
                    <Input
                      type="tel"
                      value={mainGuest.telephone || ""}
                      onChange={(e) =>
                        handleMainGuestInputChange("telephone", e.target.value)
                      }
                      required
                    />
                  </div>
                </div>

                {/* Contract Section */}
                <div className="border-t pt-6 mt-6">
                  <h3 className="text-xl font-semibold mb-4">
                    {t("terms_and_conditions")}
                  </h3>
                  <div className="prose max-w-none mb-6">
                    <p className="text-gray-600">{t("contract_content")}</p>
                  </div>

                  <div className="flex items-center gap-2">
                    <input
                      type="checkbox"
                      id="contract-agreement"
                      checked={contractSigned}
                      onChange={(e) => setContractSigned(e.target.checked)}
                      required
                      className="w-4 h-4"
                    />
                    <Label htmlFor="contract-agreement">
                      {t("i_agree_to_terms")}
                    </Label>
                  </div>
                </div>
              </div>

              <div className="flex justify-between gap-4 mt-8">
                <Button
                  type="button"
                  variant="outline"
                  onClick={handleBack}
                  className="w-full"
                >
                  Indietro
                </Button>
                <Button
                  type="submit"
                  className="w-full bg-blue-600 hover:bg-blue-700"
                >
                  {numberOfGuests > 1 ? "Continua" : "Completa registrazione"}
                </Button>
              </div>
            </form>
          )}

          {/* Additional Guests - Step 3 */}
          {currentStep === 3 && (
            <form
              onSubmit={handleGuestSubmit}
              className="max-w-2xl mx-auto space-y-8 py-8"
            >
              {renderProgress()}
              <div className="text-center mb-12">
                <h2 className="text-3xl font-semibold mb-4">
                  {t("additional_guest")} {currentAdditionalGuest + 2}
                </h2>
              </div>

              <div className="bg-white p-8 rounded-lg shadow-sm border space-y-6">
                {/* Nome e Cognome */}
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                  <div className="space-y-2">
                    <Label htmlFor="name">{t("name")}</Label>
                    <Input
                      id="name"
                      name="name"
                      value={additionalGuestData.name}
                      onChange={(e) =>
                        handleAdditionalGuestInputChange("name", e.target.value)
                      }
                      required
                    />
                  </div>
                  <div className="space-y-2">
                    <Label htmlFor="surname">{t("surname")}</Label>
                    <Input
                      id="surname"
                      name="surname"
                      value={additionalGuestData.surname}
                      onChange={(e) =>
                        handleAdditionalGuestInputChange(
                          "surname",
                          e.target.value,
                        )
                      }
                      required
                    />
                  </div>
                </div>

                {/* Data di nascita */}
                <div className="space-y-2">
                  <Label>{t("birth_date")}</Label>
                  <Popover>
                    <PopoverTrigger asChild>
                      <Button
                        variant={"outline"}
                        className={cn(
                          "w-full justify-start text-left font-normal",
                          !additionalGuestBirthDate && "text-muted-foreground",
                        )}
                      >
                        <CalendarIcon className="mr-2 h-4 w-4" />
                        {additionalGuestBirthDate ? (
                          format(additionalGuestBirthDate, "PPP", {
                            locale: it,
                          })
                        ) : (
                          <span>{t("pick_date")}</span>
                        )}
                      </Button>
                    </PopoverTrigger>
                    <PopoverContent
                      className="w-auto p-0 bg-white"
                      align="start"
                    >
                      <Calendar
                        mode="single"
                        selected={additionalGuestBirthDate}
                        onSelect={setAdditionalGuestBirthDate}
                        disabled={(date) => date > new Date()}
                        initialFocus
                        locale={it}
                      />
                    </PopoverContent>
                  </Popover>
                </div>

                {/* Nazionalità */}
                <div className="space-y-2">
                  <Label>{t("nationality")}</Label>
                  <Select
                    value={additionalGuestData.nationality}
                    onValueChange={(value) =>
                      handleAdditionalGuestInputChange("nationality", value)
                    }
                    required
                  >
                    <SelectTrigger>
                      <SelectValue placeholder={t("select_nationality")} />
                    </SelectTrigger>
                    <SelectContent
                      position="popper"
                      className="w-[--radix-select-trigger-width] bg-white dark:bg-gray-800"
                    >
                      <SelectItem value="IT">Italia</SelectItem>
                      <SelectItem value="FR">Francia</SelectItem>
                      <SelectItem value="DE">Germania</SelectItem>
                      <SelectItem value="UK">Regno Unito</SelectItem>
                      <SelectItem value="ES">Spagna</SelectItem>
                    </SelectContent>
                  </Select>
                </div>

                {/* Documento */}
                <div className="space-y-6">
                  <div className="space-y-2">
                    <Label>{t("document_type")}</Label>
                    <Select
                      value={additionalGuestData.document_type}
                      onValueChange={(value) =>
                        handleAdditionalGuestInputChange("document_type", value)
                      }
                      required
                    >
                      <SelectTrigger>
                        <SelectValue placeholder={t("select_document_type")} />
                      </SelectTrigger>
                      <SelectContent
                        position="popper"
                        className="w-[--radix-select-trigger-width] bg-white dark:bg-gray-800"
                      >
                        <SelectItem value="ID">Carta d'identità</SelectItem>
                        <SelectItem value="PASSPORT">Passaporto</SelectItem>
                        <SelectItem value="DRIVING">Patente</SelectItem>
                      </SelectContent>
                    </Select>
                  </div>

                  <div className="space-y-2">
                    <Label>{t("document_number")}</Label>
                    <Input
                      value={additionalGuestData.document_number}
                      onChange={(e) =>
                        handleAdditionalGuestInputChange(
                          "document_number",
                          e.target.value,
                        )
                      }
                      required
                    />
                  </div>
                </div>

                <div className="flex justify-between gap-4 mt-8">
                  <Button
                    type="button"
                    variant="outline"
                    onClick={handleBack}
                    className="w-full"
                  >
                    {t("back")}
                  </Button>
                  <Button
                    type="submit"
                    className="w-full bg-blue-600 hover:bg-blue-700"
                  >
                    {currentAdditionalGuest < guestNumberInput - 2
                      ? t("next")
                      : t("save")}
                  </Button>
                </div>
              </div>
            </form>
          )}
        </CardContent>
      </Card>
    </div>
  );
};

export default GuestRegistration;
