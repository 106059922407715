import { CreditCard, Upload } from "lucide-react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useApi } from "../../hooks/useApi";
import { showToast } from "../../utils/toast";
import { Button } from "../ui/button";
import { Card, CardContent, CardHeader, CardTitle } from "../ui/card";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "../ui/dialog";
import { Input } from "../ui/input";
import { Label } from "../ui/label";
import { Avatar, AvatarFallback, AvatarImage } from "../ui/avatar";
import { useAuth } from "../../contexts/AuthContext";

const AccountDetails = ({ user: initialUser }) => {
  const { apiCall } = useApi();
  const { t } = useTranslation();
  const { updateUser } = useAuth();
  const [user, setUser] = useState(initialUser);

  // Stato per i modali
  const [isPlanModalOpen, setIsPlanModalOpen] = useState(false);
  const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false);

  console.log("user", user);

  const [personalDetails, setPersonalDetails] = useState({
    first_name: user?.first_name || "",
    last_name: user?.last_name || "",
    phone: user?.phone || "",
    address: user?.address || "",
    province: user?.province || "",
    city: user?.city || "",
    state: user?.state || "",
    zip_code: user?.zip_code || "",
    taxId: user?.tax_id || "",
  });
  console.log("personalDetails", personalDetails);

  const [openDialog, setOpenDialog] = useState("");

  const [isModified, setIsModified] = useState(false);

  const [isUploading, setIsUploading] = useState(false);

  const handleInputChange = (field, value) => {
    setPersonalDetails((prev) => {
      const updatedDetails = { ...prev, [field]: value };
      setIsModified(
        JSON.stringify(updatedDetails) !==
          JSON.stringify({
            first_name: user?.first_name || "",
            last_name: user?.last_name || "",
            phone: user?.phone || "",
            address: user?.address || "",
            province: user?.province || "",
            city: user?.city || "",
            state: user?.state || "",
            zip_code: user?.zip_code || "",
            taxId: user?.tax_id || "",
          }),
      );
      return updatedDetails;
    });
  };

  const handlePersonalDetailsSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await apiCall(
        "put",
        `/users/${user.id}`,
        personalDetails,
        true,
      );
      console.log("handlePersonalDetailsSubmit", response);

      if (response.data.success) {
        showToast.success("Dettagli personali aggiornati con successo");
        setIsModified(false);
      } else {
        throw new Error(response.message || "Errore durante l'aggiornamento");
      }
    } catch (error) {
      console.error("Error updating user details:", error);
      showToast.error(
        error.message || "Errore durante l'aggiornamento dei dettagli",
      );
    }
  };

  const handleOpenDialog = (dialogName) => {
    setOpenDialog(dialogName);
  };

  const handleCloseDialog = () => {
    setOpenDialog("");
  };

  // Modifica la classe per il DialogContent
  const dialogClass = "max-w-[400px] w-full"; // o anche "max-w-sm w-full" per una larghezza ancora più piccola

  const handleAvatarUpload = async (event) => {
    try {
      const file = event.target.files[0];
      if (!file) return;

      if (!file.type.startsWith("image/")) {
        showToast.error(t("please_select_image"));
        return;
      }

      if (file.size > 5 * 1024 * 1024) {
        showToast.error(t("image_too_large"));
        return;
      }

      setIsUploading(true);

      const formData = new FormData();
      formData.append("avatar", file);

      const response = await apiCall(
        "put",
        `/users/${initialUser.id}`,
        formData,
        true, // useAuth
        false, // usePublicToken
        true, // formData
      );

      if (response.data.success) {
        showToast.success(t("avatar_updated"));

        // Forza l'aggiornamento dell'immagine aggiungendo un timestamp
        const newAvatarUrl = `${response.data.user.avatar_url}?t=${Date.now()}`;

        // Aggiorna il context dell'utente (questo aggiornerà l'avatar ovunque)
        updateUser((currentUser) => ({
          ...currentUser,
          avatar_url: newAvatarUrl,
        }));
      }
    } catch (error) {
      console.error("Error uploading avatar:", error);
      showToast.error(t("error_uploading_avatar"));
    } finally {
      setIsUploading(false);
    }
  };

  return (
    <div className="space-y-6">
      {/* Dati Personali Title */}
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-2xl font-bold">{t("account_personal_data")}</h2>
      </div>

      {/* Personal Details Card */}
      <Card className="bg-white">
        <CardContent>
          <form onSubmit={handlePersonalDetailsSubmit} className="space-y-6">
            {/* Avatar Upload Section */}
            <div className="flex items-center gap-4 pb-6 border-b">
              <Avatar className="w-16 h-16 bg-primary">
                <AvatarImage
                  key={initialUser?.avatar_url}
                  src={initialUser?.avatar_url}
                  alt={`${initialUser?.first_name} ${initialUser?.last_name}`}
                />
                <AvatarFallback className="bg-primary text-primary-foreground text-lg font-medium uppercase">
                  {initialUser?.first_name?.[0]}
                  {initialUser?.last_name?.[0]}
                </AvatarFallback>
              </Avatar>
              <div>
                <input
                  type="file"
                  id="avatar-upload"
                  accept="image/*"
                  onChange={handleAvatarUpload}
                  className="hidden"
                />
                <label
                  htmlFor="avatar-upload"
                  className={`
                    inline-flex items-center gap-2 px-4 py-2 rounded-md
                    bg-primary text-white cursor-pointer text-sm
                    hover:bg-primary/90 transition-colors
                    ${isUploading ? "opacity-50 cursor-not-allowed" : ""}
                  `}
                >
                  {isUploading ? t("uploading") : t("add_photo")}
                </label>
                <p className="text-xs text-gray-500 mt-1">
                  {t("supported_formats")}: JPG, PNG, GIF (max. 5MB)
                </p>
              </div>
            </div>

            <div className="grid gap-4 md:grid-cols-2">
              <div>
                <Label htmlFor="first_name">{t("account_first_name")}</Label>
                <Input
                  id="first_name"
                  value={personalDetails.first_name}
                  onChange={(e) =>
                    handleInputChange("first_name", e.target.value)
                  }
                  placeholder={t("account_first_name")}
                  className="h-8"
                />
              </div>

              <div>
                <Label htmlFor="last_name">{t("account_last_name")}</Label>
                <Input
                  id="last_name"
                  value={personalDetails.last_name}
                  onChange={(e) =>
                    handleInputChange("last_name", e.target.value)
                  }
                  placeholder={t("account_last_name")}
                  className="h-8"
                />
              </div>

              <div>
                <Label htmlFor="email">{t("account_email")}</Label>
                <Input
                  id="email"
                  type="email"
                  value={user?.email || ""}
                  disabled
                  className="h-8 bg-gray-50"
                />
                <p className="text-xs text-gray-500 mt-1">
                  {t("account_email_locked")}
                </p>
              </div>

              <div>
                <Label htmlFor="taxId">{t("account_tax_id")}</Label>
                <Input
                  id="taxId"
                  value={personalDetails.taxId}
                  onChange={(e) => handleInputChange("taxId", e.target.value)}
                  placeholder={t("account_tax_id")}
                  className="h-8"
                />
              </div>

              {/* Address Section */}
              <div className="md:col-span-2">
                <Label htmlFor="address">{t("account_address")}</Label>
                <Input
                  id="address"
                  value={personalDetails.address}
                  onChange={(e) => handleInputChange("address", e.target.value)}
                  placeholder={t("account_address")}
                  className="h-8"
                />
              </div>

              <div>
                <Label htmlFor="city">{t("account_city")}</Label>
                <Input
                  id="city"
                  value={personalDetails.city}
                  onChange={(e) => handleInputChange("city", e.target.value)}
                  placeholder={t("account_city")}
                  className="h-8"
                />
              </div>

              <div>
                <Label htmlFor="province">{t("account_province")}</Label>
                <Input
                  id="province"
                  value={personalDetails.province}
                  onChange={(e) =>
                    handleInputChange("province", e.target.value)
                  }
                  placeholder={t("account_province")}
                  className="h-8"
                />
              </div>

              <div>
                <Label htmlFor="zip_code">{t("account_zip")}</Label>
                <Input
                  id="zip_code"
                  value={personalDetails.zip_code}
                  onChange={(e) => handleInputChange("zip_code", e.target.value)}
                  placeholder={t("account_zip")}
                  className="h-8"
                />
              </div>

              <div>
                <Label htmlFor="state">{t("account_state")}</Label>
                <Input
                  id="state"
                  value={personalDetails.state}
                  onChange={(e) => handleInputChange("state", e.target.value)}
                  placeholder={t("account_state")}
                  className="h-8"
                />
              </div>
            </div>

            <div className="pt-4 border-t">
              <Button type="submit" className="w-full" disabled={!isModified}>
                {t("account_save_changes")}
              </Button>
            </div>
          </form>
        </CardContent>
      </Card>

      
    </div>
  );
};

export default AccountDetails;
