import { zodResolver } from "@hookform/resolvers/zod";
import { addDays, format, startOfMonth } from "date-fns";
import { enUS, fr, it } from "date-fns/locale";
import { CalendarIcon, Minus, Plus, Users } from "lucide-react";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { z } from "zod";
import Menu from "./Menu";
import { Button } from "./ui/button";
import { Calendar } from "./ui/calendar";
import { Card, CardContent, CardHeader, CardTitle } from "./ui/card";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "./ui/form";
import { Label } from "./ui/label";
import { Popover, PopoverContent, PopoverTrigger } from "./ui/popover";

const dateLocales = { it, en: enUS, fr };

const FormSchema = z.object({
  checkIn: z.date({
    required_error: "La data di check-in è obbligatoria.",
  }),
  checkOut: z.date({
    required_error: "La data di check-out è obbligatoria.",
  }),
  guests: z.object({
    adults: z.number().min(1).max(10),
    children: z.number().min(0).max(10),
  }),
});

const HomePage = ({ visibleSections }) => {
  const { t, i18n } = useTranslation();

  const [checkInOpen, setCheckInOpen] = useState(false);
  const [checkOutOpen, setCheckOutOpen] = useState(false);
  const form = useForm({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      guests: {
        adults: 2,
        children: 0,
      },
    },
  });

  const [availableApartments, setAvailableApartments] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [checkOutMonth, setCheckOutMonth] = useState(new Date());
  const [hasSearched, setHasSearched] = useState(false);
  const [error, setError] = useState(null);

  async function onSubmit(data) {
    setIsLoading(true);
    setError(null);
    setAvailableApartments([]);
    setHasSearched(true);
    try {
      const response = await fetch("/api/check-availability", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
      if (!response.ok) {
        throw new Error(t("common_error_message"));
      }
      const responseData = await response.json();
      setAvailableApartments(responseData.apartments);
    } catch (error) {
      console.error(t("common_error_message"), error);
      setError(t("common_error_message"));
    } finally {
      setIsLoading(false);
    }
  }

  const calendarClassName = {
    month: "space-y-4",
    caption: "flex justify-center pt-1 relative items-center",
    caption_label: "text-sm font-medium",
    nav: "space-x-1 flex items-center",
    nav_button: "h-7 w-7 bg-transparent p-0 opacity-50 hover:opacity-100",
    nav_button_previous: "absolute left-1",
    nav_button_next: "absolute right-1",
    table: "w-full border-collapse space-y-1",
    head_row: "flex",
    head_cell: "text-muted-foreground rounded-md w-9 font-normal text-[0.8rem]",
    row: "flex w-full mt-2",
    cell: "text-center text-sm p-0 relative [&:has([aria-selected])]:bg-accent first:[&:has([aria-selected])]:rounded-l-md last:[&:has([aria-selected])]:rounded-r-md focus-within:relative focus-within:z-20",
    day: "h-9 w-9 p-0 font-normal aria-selected:opacity-100",
    day_selected:
      "bg-primary text-primary-foreground hover:bg-primary hover:text-primary-foreground focus:bg-primary focus:text-primary-foreground",
    day_today: "bg-accent text-accent-foreground",
    day_outside: "text-muted-foreground opacity-50",
    day_disabled: "text-muted-foreground opacity-50",
    day_range_middle:
      "aria-selected:bg-accent aria-selected:text-accent-foreground",
    day_hidden: "invisible",
  };

  const handleBooking = (apartmentId) => {
    // Implementare qui la logica di prenotazione
  };

  const GuestSelect = ({ value, onChange }) => {
    const updateGuests = (type, operation) => () => {
      const newValue = {
        ...value,
        [type]:
          operation === "increment"
            ? Math.min(value[type] + 1, 10)
            : type === "adults"
              ? Math.max(value[type] - 1, 1)
              : Math.max(value[type] - 1, 0),
      };
      onChange(newValue);
    };

    const totalGuests = value.adults + value.children;

    return (
      <Popover>
        <PopoverTrigger asChild>
          <Button
            variant="outline"
            className="w-full h-12 flex items-center justify-between font-normal"
          >
            <span>
              {totalGuests}{" "}
              {totalGuests === 1
                ? t("booking_engine_guests_label")
                : t("booking_engine_guests_label")}
            </span>
            <Users className="h-4 w-4" />
          </Button>
        </PopoverTrigger>
        <PopoverContent className="w-80 bg-white">
          <div className="grid gap-4">
            <div className="space-y-2">
              <h4 className="font-medium leading-none">
                {t("booking_engine_guests_label")}
              </h4>
              <p className="text-sm text-muted-foreground">
                {t("booking_engine_guests_placeholder")}
              </p>
            </div>
            <div className="grid gap-4">
              {["adults", "children"].map((type) => (
                <div key={type} className="flex items-center justify-between">
                  <Label htmlFor={type} className="text-base">
                    {t(type)}
                  </Label>
                  <div className="flex items-center">
                    <Button
                      variant="outline"
                      size="icon"
                      className="w-8 h-8 rounded-full border flex items-center justify-center"
                      onClick={updateGuests(type, "decrement")}
                      disabled={
                        type === "adults"
                          ? value[type] === 1
                          : value[type] === 0
                      }
                    >
                      <Minus className="h-4 w-4" />
                    </Button>
                    <span className="mx-4 min-w-[20px] text-center">
                      {value[type]}
                    </span>
                    <Button
                      variant="outline"
                      size="icon"
                      className="w-8 h-8 rounded-full border flex items-center justify-center"
                      onClick={updateGuests(type, "increment")}
                      disabled={value[type] === 10}
                    >
                      <Plus className="h-4 w-4" />
                    </Button>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </PopoverContent>
      </Popover>
    );
  };

  return (
    <div className="min-h-screen bg-gradient-to-r from-blue-100 to-green-100 p-4 sm:p-8">
      <Menu visibleSections={visibleSections} />
      <header className="mb-8 sm:mb-12 text-center pt-20">
        <h1 className="text-5xl font-extrabold text-center mb-4 text-gray-800">
          {t("common_site_name")}
        </h1>
        <p className="text-lg sm:text-xl text-gray-600">
          {t("common_site_tagline")}
        </p>
      </header>

      <main className="max-w-4xl mx-auto">
        <Card className="mb-8 shadow-lg bg-white">
          <CardHeader>
            <CardTitle className="text-xl sm:text-2xl font-semibold text-center">
              {t("booking_engine_title")}
            </CardTitle>
          </CardHeader>
          <CardContent>
            <Form {...form}>
              <form
                onSubmit={form.handleSubmit(onSubmit)}
                className="space-y-4 sm:space-y-8"
              >
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                  <FormField
                    control={form.control}
                    name="checkIn"
                    render={({ field }) => (
                      <FormItem className="flex flex-col">
                        <FormLabel className="text-sm font-medium text-gray-700 mb-1">
                          {t("booking_engine_check_in_label")}
                        </FormLabel>
                        <Popover
                          open={checkInOpen}
                          onOpenChange={setCheckInOpen}
                        >
                          <PopoverTrigger asChild>
                            <FormControl>
                              <Button
                                variant={"outline"}
                                className={`w-full h-12 flex items-center justify-between pl-3 text-left font-normal bg-white text-base ${
                                  !field.value ? "text-muted-foreground" : ""
                                }`}
                              >
                                <span>
                                  {field.value
                                    ? format(field.value, "d MMMM yyyy", {
                                        locale: dateLocales[i18n.language],
                                      })
                                    : t(
                                        "booking_engine_select_date_placeholder",
                                      )}
                                </span>
                                <CalendarIcon className="ml-2 h-4 w-4" />
                              </Button>
                            </FormControl>
                          </PopoverTrigger>
                          <PopoverContent
                            className="w-auto p-0 bg-white"
                            align="start"
                          >
                            <Calendar
                              mode="single"
                              selected={field.value}
                              onSelect={(date) => {
                                field.onChange(date);
                                setCheckInOpen(false);
                                const nextDay = addDays(date, 1);
                                form.setValue("checkOut", nextDay);
                                setCheckOutMonth(startOfMonth(nextDay));
                              }}
                              disabled={(date) => date < new Date()}
                              initialFocus
                              locale={dateLocales[i18n.language]}
                              classNames={calendarClassName}
                            />
                          </PopoverContent>
                        </Popover>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name="checkOut"
                    render={({ field }) => (
                      <FormItem className="flex flex-col">
                        <FormLabel className="text-sm font-medium text-gray-700 mb-1">
                          {t("booking_engine_check_out_label")}
                        </FormLabel>
                        <Popover
                          open={checkOutOpen}
                          onOpenChange={setCheckOutOpen}
                        >
                          <PopoverTrigger asChild>
                            <FormControl>
                              <Button
                                variant={"outline"}
                                className={`w-full h-12 flex items-center justify-between pl-3 text-left font-normal bg-white text-base ${
                                  !field.value ? "text-muted-foreground" : ""
                                }`}
                              >
                                <span>
                                  {field.value
                                    ? format(field.value, "d MMMM yyyy", {
                                        locale: dateLocales[i18n.language],
                                      })
                                    : t(
                                        "booking_engine_select_date_placeholder",
                                      )}
                                </span>
                                <CalendarIcon className="ml-2 h-4 w-4" />
                              </Button>
                            </FormControl>
                          </PopoverTrigger>
                          <PopoverContent
                            className="w-auto p-0 bg-white"
                            align="start"
                          >
                            <Calendar
                              defaultMonth={checkOutMonth}
                              mode="single"
                              selected={field.value}
                              onSelect={(date) => {
                                field.onChange(date);
                                setCheckOutOpen(false);
                              }}
                              disabled={(date) => {
                                const checkIn = form.getValues().checkIn;
                                return !checkIn || date <= checkIn;
                              }}
                              initialFocus
                              locale={dateLocales[i18n.language]}
                              classNames={calendarClassName}
                            />
                          </PopoverContent>
                        </Popover>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
                <Controller
                  name="guests"
                  control={form.control}
                  render={({ field }) => (
                    <FormItem className="w-full">
                      <FormLabel className="block text-sm font-medium text-gray-700 mb-1">
                        {t("booking_engine_guests_label")}
                      </FormLabel>
                      <GuestSelect
                        value={field.value}
                        onChange={(newValue) => {
                          field.onChange(newValue);
                        }}
                      />
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <Button
                  type="submit"
                  className="w-full h-12 bg-blue-600 hover:bg-blue-700 text-white rounded-md text-base"
                  disabled={isLoading}
                >
                  {isLoading
                    ? t("booking_engine_searching_button")
                    : t("booking_engine_search_button")}
                </Button>
              </form>
            </Form>
          </CardContent>
        </Card>

        {error && (
          <Card className="mb-8 shadow-lg bg-red-100 border-red-300">
            <CardContent>
              <p className="text-center text-red-600 text-sm sm:text-lg">
                {error}
              </p>
            </CardContent>
          </Card>
        )}

        {availableApartments.length > 0 && (
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
            {availableApartments.map((apartment) => (
              <Card key={apartment.id} className="mb-4 shadow-lg bg-white">
                <CardHeader>
                  <CardTitle className="text-lg font-semibold">
                    {apartment.name}
                  </CardTitle>
                </CardHeader>
                <CardContent>
                  <p className="text-sm text-gray-600 mb-2">
                    {apartment.description}
                  </p>
                  <p className="text-md font-bold mb-4">
                    {t("apartments_price", { price: apartment.price })}
                  </p>
                  <Button
                    onClick={() => handleBooking(apartment.id)}
                    className="w-full bg-green-600 hover:bg-green-700 text-white p-2 rounded-md text-sm"
                  >
                    {t("booking_engine_book_now_button")}
                  </Button>
                </CardContent>
              </Card>
            ))}
          </div>
        )}

        {hasSearched &&
          availableApartments.length === 0 &&
          !isLoading &&
          !error && (
            <Card className="mb-8 shadow-lg bg-yellow-100 border-yellow-300">
              <CardContent>
                <p className="text-center text-yellow-600 text-sm sm:text-lg">
                  {t("booking_engine_no_apartments_available")}
                </p>
              </CardContent>
            </Card>
          )}
      </main>

      <footer className="mt-8 sm:mt-12 text-center text-gray-600">
        <p className="text-sm sm:text-base">
          {t("common_footer", { year: new Date().getFullYear() })}
        </p>
      </footer>
    </div>
  );
};

export default HomePage;
