// components/dashboard/ApartmentList.js
import { useTranslation } from "react-i18next";
import ItemList from "./ItemList";

const ApartmentList = ({ apartments, isLoading, error, onRefetch }) => {
  const { t } = useTranslation();

  return (
    <ItemList
      items={apartments}
      isLoading={isLoading}
      error={error}
      onRefetch={onRefetch}
      itemType="apartments"
      title={t("apartments_title")}
      addNewLabel={t("apartments_add_new")}
    />
  );
};

export default ApartmentList;
