import {
  Book,
  Building,
  Calendar as CalendarIcon,
  ChevronDown,
  ChevronLeft,
  ChevronRight,
  HelpCircle,
  Home,
  List,
  Moon,
  Settings as SettingsIcon,
  Sun,
  Users,
  Shield,
} from "lucide-react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "../../contexts/ThemeContext";
import { cn } from "../../lib/utils";

import LanguageSelector from "../LanguageSelector";

const SidebarComponent = ({
  activeSection,
  setActiveSection,
  isAdmin,
  className,
}) => {
  const { t } = useTranslation();
  const { isDarkMode, toggleTheme } = useTheme();
  const [openSubmenu, setOpenSubmenu] = useState(null);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
      if (window.innerWidth < 768) {
        setIsCollapsed(false);
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const menuItems = [
    { id: "home", label: t("menu_home"), icon: Home },
    { id: "onboarding", label: "Onboarding", icon: Home },
    { id: "reservations", label: t("menu_reservations"), icon: List },
    {
      id: "apartments",
      label: t("menu_apartments"),
      icon: Building,
      subItems: [
        { id: "apartments", label: t("menu_apartments") },
        { id: "altro", label: "Altro sottomenu" },
      ],
    },
    { id: "guides", label: t("menu_guides"), icon: Book },
    { id: "calendar", label: t("menu_calendar"), icon: CalendarIcon },
    {
      id: "settings",
      label: t("menu_settings"),
      icon: SettingsIcon,
    },
    { id: "regulations", label: t("menu_regulations"), icon: Shield },
  ];

  const adminMenuItems = [
    { id: "users", label: t("menu_users"), icon: Users },
    { id: "help", label: t("menu_help_articles"), icon: HelpCircle },
  ];

  const handleMenuClick = (item) => {
    if (item.subItems) {
      setOpenSubmenu(openSubmenu === item.id ? null : item.id);
    } else {
      setActiveSection(item.id);
      setOpenSubmenu(null);
    }
  };

  return (
    <div
      className={cn(
        "h-full relative transition-all duration-300",
        isDarkMode ? "bg-gray-900" : "bg-white",
        "shadow-xl",
        isCollapsed ? "w-20" : "w-64",
      )}
    >
      {!isMobile && (
        <button
          onClick={() => setIsCollapsed(!isCollapsed)}
          className={cn(
            "absolute -right-3 top-6 rounded-full p-1 shadow-md border group",
            isDarkMode
              ? "bg-gray-900 border-gray-700 hover:bg-gray-100"
              : "bg-white hover:bg-gray-100",
            "z-50 transition-all duration-200",
          )}
        >
          {isCollapsed ? (
            <ChevronRight
              size={16}
              className={cn(
                isDarkMode ? "text-white group-hover:text-black" : "text-black",
                "transition-colors duration-200",
              )}
            />
          ) : (
            <ChevronLeft
              size={16}
              className={cn(
                isDarkMode ? "text-white group-hover:text-black" : "text-black",
                "transition-colors duration-200",
              )}
            />
          )}
        </button>
      )}

      <nav
        className={cn(
          "p-4 flex flex-col h-full",
          isCollapsed ? "w-20" : "w-64",
          className,
        )}
      >
        <div className="mb-6 px-4 flex justify-between items-center">
          <h2 className={cn("text-lg font-bold", isCollapsed && "hidden")}>
            {" "}
          </h2>
          {isMobile && (
            <button onClick={toggleTheme} className="p-2 rounded-full">
              {isDarkMode ? <Sun size={20} /> : <Moon size={20} />}
            </button>
          )}
        </div>

        <ul className="space-y-2 mb-auto">
          {menuItems.map((item) => {
            const Icon = item.icon;
            const isSubmenuOpen = openSubmenu === item.id;

            return (
              <li key={item.id}>
                <button
                  onClick={() => handleMenuClick(item)}
                  className={cn(
                    "w-full flex items-center gap-3 px-4 py-2 rounded-lg transition-colors",
                    "text-sm font-medium",
                    isDarkMode
                      ? activeSection === item.id
                        ? "bg-white/10 text-white"
                        : "text-white/80 hover:bg-white/10 hover:text-white"
                      : activeSection === item.id
                        ? "bg-blue-500 text-white"
                        : "text-black hover:bg-blue-100 hover:border-blue-500 border-b-2 border-transparent",
                    isCollapsed && "justify-center",
                  )}
                  title={isCollapsed ? item.label : ""}
                >
                  <div
                    className={cn(
                      "flex items-center gap-3",
                      isCollapsed && "justify-center",
                    )}
                  >
                    <Icon size={20} />
                    {!isCollapsed && (
                      <span
                        className={
                          activeSection === item.id
                            ? "text-white"
                            : isDarkMode
                              ? "text-white/80"
                              : "text-black"
                        }
                      >
                        {item.label}
                      </span>
                    )}
                  </div>
                  {!isCollapsed &&
                    item.subItems &&
                    (isSubmenuOpen ? (
                      <ChevronDown size={16} />
                    ) : (
                      <ChevronRight size={16} />
                    ))}
                </button>

                {!isCollapsed && isSubmenuOpen && item.subItems && (
                  <ul className="ml-6 mt-2 space-y-2">
                    {item.subItems.map((subItem) => (
                      <li key={subItem.id}>
                        <button
                          onClick={() => setActiveSection(subItem.id)}
                          className={cn(
                            "w-full flex items-center gap-3 px-4 py-2 rounded-lg transition-colors",
                            "text-sm font-medium",
                            activeSection === subItem.id
                              ? isDarkMode
                                ? "bg-white/20 text-white"
                                : "bg-blue-200 text-white"
                              : isDarkMode
                                ? "text-white hover:bg-white/10 hover:border-blue-500"
                                : "text-black hover:bg-blue-100 hover:border-blue-500 border-b-2 border-transparent",
                          )}
                        >
                          <span
                            className={
                              activeSection === subItem.id
                                ? "text-white"
                                : isDarkMode
                                  ? "text-white/80"
                                  : "text-black"
                            }
                          >
                            {subItem.label}
                          </span>
                        </button>
                      </li>
                    ))}
                  </ul>
                )}
              </li>
            );
          })}

          {isAdmin && (
            <>
              <li className="py-2">
                <div className="flex flex-col space-y-1">
                  <div
                    className={cn(
                      "border-t border-gray-200 dark:border-gray-700",
                      isCollapsed ? "mx-2" : "mx-4",
                    )}
                  />
                  {!isCollapsed && (
                    <span className="text-xs text-gray-500 dark:text-gray-400 px-4">
                      {t("admin_only_section")}
                    </span>
                  )}
                </div>
              </li>
              {adminMenuItems.map((item) => (
                <li key={item.id}>
                  <button
                    onClick={() => handleMenuClick(item)}
                    className={cn(
                      "w-full flex items-center gap-3 px-4 py-2 rounded-lg transition-colors",
                      "text-sm font-medium",
                      isDarkMode
                        ? activeSection === item.id
                          ? "bg-white/10 text-white"
                          : "text-white/80 hover:bg-white/10 hover:text-white"
                        : activeSection === item.id
                          ? "bg-blue-500 text-white"
                          : "text-black hover:bg-blue-100 hover:border-blue-500 border-b-2 border-transparent",
                      isCollapsed && "justify-center",
                    )}
                    title={isCollapsed ? item.label : ""}
                  >
                    <div
                      className={cn(
                        "flex items-center gap-3",
                        isCollapsed && "justify-center",
                      )}
                    >
                      <item.icon size={20} />
                      {!isCollapsed && (
                        <span
                          className={
                            activeSection === item.id
                              ? "text-white"
                              : isDarkMode
                                ? "text-white/80"
                                : "text-black"
                          }
                        >
                          {item.label}
                        </span>
                      )}
                    </div>
                  </button>
                </li>
              ))}
            </>
          )}
        </ul>

        <div className="border-t border-white/10 pt-2"></div>
        {isMobile && (
          <div
            className={cn(
              "space-y-2 mt-4",
              isCollapsed && "flex flex-col items-center",
            )}
          >
            <a
              href="https://help.guestsuite.app"
              target="_blank"
              rel="noopener noreferrer"
              className={cn(
                "flex items-center gap-3 px-4 py-2 rounded-lg transition-colors text-sm font-medium",
                isDarkMode
                  ? "text-white hover:bg-white/10"
                  : "text-black hover:bg-blue-100",
                isCollapsed && "justify-center w-10 h-10 p-0",
              )}
            >
              <HelpCircle size={20} />
              {!isCollapsed && <span>Help</span>}
            </a>
            {!isCollapsed && <LanguageSelector />}
          </div>
        )}
      </nav>
    </div>
  );
};

export default SidebarComponent;
