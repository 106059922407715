import { Check } from "lucide-react";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "../../components/ui/accordion";
import { Button } from "../../components/ui/button";
import { Card, CardContent } from "../../components/ui/card";
import Footer from "../common/Footer";
import Navbar from "./Navbar";

// Definizione dei benefits all'inizio del file
const benefits = [
  {
    icon: "🚀",
    title: "Veloce da Configurare",
    description: "Setup in 5 minuti. Nessuna competenza tecnica richiesta.",
  },
  {
    icon: "💅",
    title: "Design Professionale",
    description:
      "Template moderni e responsive, ottimizzati per le conversioni.",
  },
  {
    icon: "📱",
    title: "Tutto in Uno",
    description:
      "Sito web, gestione prenotazioni e guide digitali in un'unica piattaforma.",
  },
];

// Dati testimonial
const testimonials = [
  {
    name: "Marco Rossi",
    role: "Proprietario B&B Villa Bella",
    image: "/testimonial1.jpg", // Aggiungi le immagini nella cartella public
    quote:
      "Ho creato il sito del mio B&B in meno di 10 minuti. Le prenotazioni sono aumentate del 40% nel primo mese!",
    rating: 5,
  },
  {
    name: "Laura Bianchi",
    role: "Host Appartamenti Milano",
    image: "/testimonial2.jpg",
    quote:
      "GuestSuite mi ha permesso di gestire tutti i miei appartamenti da un'unica dashboard. Un vero game changer!",
    rating: 5,
  },
  {
    name: "Giuseppe Verdi",
    role: "Proprietario Casa Vacanze",
    image: "/testimonial3.jpg",
    quote:
      "Le guide digitali sono fantastiche. I miei ospiti hanno tutto ciò che serve a portata di smartphone.",
    rating: 5,
  },
];

// Dati FAQ
const faqs = [
  {
    question: "Quanto tempo serve per creare il mio sito?",
    answer:
      "Con GuestSuite puoi creare il tuo sito professionale in meno di 5 minuti. Basta inserire le informazioni base dei tuoi appartamenti e le foto, al resto pensiamo noi.",
  },
  {
    question: "Posso usare un dominio personalizzato?",
    answer:
      "Sì, puoi utilizzare il tuo dominio personalizzato o ottenere un sottodominio gratuito nomestruttura.guestsuite.app",
  },
  {
    question: "Come funziona la prova gratuita?",
    answer:
      "Puoi provare GuestSuite gratuitamente per 14 giorni, senza carta di credito. Avrai accesso a tutte le funzionalità premium e potrai decidere se continuare al termine del periodo di prova.",
  },
  {
    question: "Posso gestire più appartamenti?",
    answer:
      "Sì, puoi gestire tutti i tuoi appartamenti da un'unica dashboard. Il piano Pro ti permette di gestire fino a 5 appartamenti, mentre il piano Enterprise non ha limiti.",
  },
  {
    question: "Come funziona il supporto?",
    answer:
      "Offriamo supporto via email 7 giorni su 7. I clienti Pro hanno accesso al supporto prioritario, mentre i clienti Enterprise hanno un account manager dedicato.",
  },
];

// Aggiungi questi dati all'inizio del file
const howItWorks = [
  {
    step: "1",
    title: "Crea il Tuo Sito",
    description:
      "Scegli un nome personalizzato per il tuo sito (es: villamarina.guestsuite.app) e personalizza il tuo spazio in pochi click.",
    icon: "🎯",
  },
  {
    step: "2",
    title: "Aggiungi i Tuoi Appartamenti",
    description:
      "Carica foto, descrizioni e prezzi dei tuoi appartamenti. Personalizza ogni dettaglio per attirare più ospiti.",
    icon: "🏠",
  },
  {
    step: "3",
    title: "Crea Guide Digitali",
    description:
      "Fornisci ai tuoi ospiti guide digitali con informazioni utili, consigli e istruzioni per il check-in self service.",
    icon: "📱",
  },
  {
    step: "4",
    title: "Sincronizza i Calendari",
    description:
      "Collega i tuoi calendari di Airbnb, Booking e altri portali tramite iCal per gestire tutte le prenotazioni in un unico posto.",
    icon: "📅",
  },
  {
    step: "5",
    title: "Gestisci le Prenotazioni",
    description:
      "Tieni traccia di tutte le prenotazioni, invia automaticamente le guide agli ospiti e gestisci i check-in/out.",
    icon: "✨",
  },
];

// Funzione per scrollare alla sezione
const scrollToSection = (id) => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({ behavior: "smooth" });
  }
};

const LandingPage = () => {
  useEffect(() => {
    // Aggiungi un listener per il clic su "Funzionalità"
    const funzionalitaLink = document.getElementById("funzionalita");
    if (funzionalitaLink) {
      funzionalitaLink.addEventListener("click", () => {
        scrollToSection("funzionalita");
      });
    }
  }, []);

  return (
    <div className="min-h-screen w-full overflow-x-hidden bg-white">
      <Navbar className="fixed top-0 w-full z-50" />

      {/* Hero Section */}
      <section className="pt-32 pb-24 relative overflow-hidden">
        <div className="container mx-auto px-4 relative">
          <div className="max-w-4xl mx-auto text-center">
            <div className="animate-fade-in-down space-y-6">
              <h1 className="text-5xl sm:text-6xl md:text-7xl font-bold mb-6 text-black">
                Il Tuo B&B Online
                <br />
                in 5 Minuti
              </h1>
              <p className="text-xl sm:text-2xl text-gray-800 mb-8 leading-relaxed">
                Smetti di perdere tempo con siti web complicati.
                <span className="font-semibold block mt-2 text-black">
                  GuestSuite crea il tuo sito professionale all'istante.
                </span>
              </p>

              {/* CTA */}
              <div className="space-y-4">
                <Link to="/dashboard">
                  <Button
                    size="lg"
                    className="bg-primary hover:bg-primary-light text-white px-12 py-6 text-xl font-medium rounded-[12px] shadow-lg hover:shadow-xl transform hover:-translate-y-1 transition-all duration-200"
                  >
                    Prova Gratis per 14 giorni →
                  </Button>
                </Link>
                <p className="text-sm text-gray-500">
                  Nessuna carta di credito richiesta • Setup in 5 minuti
                </p>
              </div>

              {/* Social proof */}
              <div className="flex flex-row justify-center gap-2 sm:gap-4 mt-12 max-w-3xl mx-auto px-2 sm:px-4">
                <div className="glass p-2 sm:p-4 rounded-xl text-center flex-1">
                  <div className="text-yellow-400 text-base sm:text-xl md:text-2xl">
                    ⭐️⭐️⭐️⭐️⭐️
                  </div>
                  <p className="text-gray-700 font-medium mt-1 sm:mt-2 text-xs sm:text-sm md:text-base">
                    4.9/5 su Trustpilot
                  </p>
                </div>
                <div className="glass p-2 sm:p-4 rounded-xl text-center flex-1">
                  <div className="text-base sm:text-xl md:text-2xl">🏆</div>
                  <p className="text-gray-700 font-medium mt-1 sm:mt-2 text-xs sm:text-sm md:text-base">
                    +1000 host soddisfatti
                  </p>
                </div>
                <div className="glass p-2 sm:p-4 rounded-xl text-center flex-1">
                  <div className="text-base sm:text-xl md:text-2xl">🎯</div>
                  <p className="text-gray-700 font-medium mt-1 sm:mt-2 text-xs sm:text-sm md:text-base">
                    Supporto 24/7
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* How It Works Section */}
      <section id="funzionalita" className="py-20 bg-white">
        <div className="container mx-auto px-4">
          <h2 className="text-4xl sm:text-5xl font-bold text-center mb-16 text-black">
            Funzionalità di GuestSuite
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {/* Funzionalità Alloggiati Web */}
            <Card className="glass border-0 transform hover:scale-105 transition-all duration-300">
              <CardContent className="p-8">
                <div className="text-5xl mb-6">📝</div>
                <h3 className="text-2xl font-bold mb-4 text-primary">
                  Alloggiati Web
                </h3>
                <p className="text-gray-600 text-lg">
                  Registra facilmente i tuoi ospiti presso le autorità
                  competenti, garantendo la conformità alle normative locali.
                </p>
              </CardContent>
            </Card>

            {/* Funzionalità Rilevatore ISTAT */}
            <Card className="glass border-0 transform hover:scale-105 transition-all duration-300">
              <CardContent className="p-8">
                <div className="text-5xl mb-6">📊</div>
                <h3 className="text-2xl font-bold mb-4 text-primary">
                  Rilevatore ISTAT
                </h3>
                <p className="text-gray-600 text-lg">
                  Monitora e registra i dati statistici richiesti dall'ISTAT per
                  una gestione informata e strategica.
                </p>
              </CardContent>
            </Card>

            {/* Altre funzionalità esistenti */}
            <Card className="glass border-0 transform hover:scale-105 transition-all duration-300">
              <CardContent className="p-8">
                <div className="text-5xl mb-6">📱</div>
                <h3 className="text-2xl font-bold mb-4 text-primary">
                  Tutto in Uno
                </h3>
                <p className="text-gray-600 text-lg">
                  Sito web, gestione prenotazioni e guide digitali in un'unica
                  piattaforma.
                </p>
              </CardContent>
            </Card>
          </div>
        </div>
      </section>

      {/* Benefits Section Migliorata */}
      <section className="py-20 relative bg-white">
        <div className="container mx-auto px-4">
          <h2 className="text-4xl sm:text-5xl font-bold text-center mb-16 text-black">
            Perché Scegliere GuestSuite?
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {benefits.map((benefit, index) => (
              <Card
                key={index}
                className="glass border-0 transform hover:scale-105 transition-all duration-300"
              >
                <CardContent className="p-8">
                  <div className="text-5xl mb-6 animate-float">
                    {benefit.icon}
                  </div>
                  <h3 className="text-2xl font-bold mb-4 text-primary">
                    {benefit.title}
                  </h3>
                  <p className="text-gray-600 text-lg">{benefit.description}</p>
                </CardContent>
              </Card>
            ))}
          </div>
        </div>
      </section>

      {/* Pricing Section */}
      <section id="pricing" className="py-20 scroll-mt-20 bg-white">
        <div className="container mx-auto px-4 text-center">
          <h2 className="text-4xl font-bold mb-4 text-black">
            Scegli il Piano Perfetto per Te
          </h2>
          <p className="text-xl text-gray-600 mb-12">
            Piani flessibili che crescono con il tuo business
          </p>

          <div className="grid md:grid-cols-3 gap-8 max-w-6xl mx-auto">
            {/* Piano Starter */}
            <Card className="border-2 hover:border-primary/50 transition-all">
              <CardContent className="p-6">
                <h3 className="text-2xl font-bold mb-2 text-black">Starter</h3>
                <div className="text-4xl font-bold mb-2 text-black">
                  €19<span className="text-xl text-gray-500">/mese</span>
                </div>
                <p className="text-gray-500 mb-6">Per chi inizia</p>

                <ul className="text-left space-y-4 mb-8">
                  <li className="flex items-center">
                    <Check className="h-5 w-5 text-green-500 mr-2" />
                    <span>Fino a 3 appartamenti</span>
                  </li>
                  <li className="flex items-center">
                    <Check className="h-5 w-5 text-green-500 mr-2" />
                    <span>Sito web personalizzato</span>
                  </li>
                  <li className="flex items-center">
                    <Check className="h-5 w-5 text-green-500 mr-2" />
                    <span>Gestione prenotazioni</span>
                  </li>
                  <li className="flex items-center">
                    <Check className="h-5 w-5 text-green-500 mr-2" />
                    <span>Guide digitali</span>
                  </li>
                </ul>

                <Link to="/login">
                  <Button className="w-full">Prova Gratis per 14 giorni</Button>
                </Link>
              </CardContent>
            </Card>

            {/* Piano Pro */}
            <Card className="border-2 border-primary relative transform scale-105 shadow-xl">
              <div className="absolute -top-4 left-1/2 -translate-x-1/2 bg-primary text-white px-4 py-1 rounded-full text-sm">
                Più Popolare
              </div>
              <CardContent className="p-6">
                <h3 className="text-2xl font-bold mb-2 text-black">Pro</h3>
                <div className="text-4xl font-bold mb-2 text-black">
                  €49<span className="text-xl text-gray-500">/mese</span>
                </div>
                <p className="text-gray-500 mb-6">Per host professionisti</p>

                <ul className="text-left space-y-4 mb-8">
                  <li className="flex items-center">
                    <Check className="h-5 w-5 text-green-500 mr-2" />
                    <span>Fino a 10 appartamenti</span>
                  </li>
                  <li className="flex items-center">
                    <Check className="h-5 w-5 text-green-500 mr-2" />
                    <span>Tutto del piano Starter</span>
                  </li>
                  <li className="flex items-center">
                    <Check className="h-5 w-5 text-green-500 mr-2" />
                    <span>Statistiche avanzate</span>
                  </li>
                  <li className="flex items-center">
                    <Check className="h-5 w-5 text-green-500 mr-2" />
                    <span>Supporto prioritario</span>
                  </li>
                </ul>

                <Link to="/login">
                  <Button className="w-full bg-primary text-white">
                    Prova Gratis per 14 giorni
                  </Button>
                </Link>
              </CardContent>
            </Card>

            {/* Piano Enterprise */}
            <Card className="border-2 hover:border-primary/50 transition-all">
              <CardContent className="p-6">
                <h3 className="text-2xl font-bold mb-2 text-black">
                  Enterprise
                </h3>
                <div className="text-4xl font-bold mb-2 text-black">
                  €99<span className="text-xl text-gray-500">/mese</span>
                </div>
                <p className="text-gray-500 mb-6">Per grandi portfolio</p>

                <ul className="text-left space-y-4 mb-8">
                  <li className="flex items-center">
                    <Check className="h-5 w-5 text-green-500 mr-2" />
                    <span>Appartamenti illimitati</span>
                  </li>
                  <li className="flex items-center">
                    <Check className="h-5 w-5 text-green-500 mr-2" />
                    <span>Tutto del piano Pro</span>
                  </li>
                  <li className="flex items-center">
                    <Check className="h-5 w-5 text-green-500 mr-2" />
                    <span>Account manager dedicato</span>
                  </li>
                  <li className="flex items-center">
                    <Check className="h-5 w-5 text-green-500 mr-2" />
                    <span>API personalizzate</span>
                  </li>
                </ul>

                <Link to="/login">
                  <Button className="w-full">Prova Gratis per 14 giorni</Button>
                </Link>
              </CardContent>
            </Card>
          </div>
        </div>
      </section>

      {/* Testimonial Section */}
      <section id="testimonials" className="py-20 scroll-mt-20 bg-white">
        <div className="container mx-auto px-4">
          <h2 className="text-4xl font-bold text-center mb-16 text-black">
            Chi Usa GuestSuite
          </h2>
          <div className="grid md:grid-cols-3 gap-8">
            {testimonials.map((testimonial, index) => (
              <Card key={index} className="overflow-hidden">
                <CardContent className="p-4 sm:p-6">
                  <div className="flex items-center gap-2 sm:gap-4 mb-4">
                    <div className="w-12 h-12 sm:w-16 sm:h-16 rounded-full overflow-hidden bg-gray-200 flex-shrink-0">
                      <img
                        src={testimonial.image}
                        alt={testimonial.name}
                        className="w-full h-full object-cover"
                      />
                    </div>
                    <div className="min-w-0 flex-1">
                      <h3 className="font-semibold truncate text-black">
                        {testimonial.name}
                      </h3>
                      <p className="text-sm text-gray-600 truncate">
                        {testimonial.role}
                      </p>
                      <div>
                        <div className="text-yellow-400 text-xl">
                          {"⭐".repeat(testimonial.rating)}
                        </div>
                      </div>
                    </div>
                  </div>
                  <p className="text-gray-600 italic text-sm sm:text-base break-words">
                    "{testimonial.quote}"
                  </p>
                </CardContent>
              </Card>
            ))}
          </div>
        </div>
      </section>

      {/* FAQ Section */}
      <section id="faq" className="py-20 scroll-mt-20 bg-white">
        <div className="container mx-auto px-4">
          <h2 className="text-4xl font-bold text-center mb-16 text-black">
            Domande Frequenti
          </h2>
          <div className="max-w-3xl mx-auto">
            <Accordion type="single" collapsible className="w-full">
              {faqs.map((faq, index) => (
                <AccordionItem key={index} value={`item-${index}`}>
                  <AccordionTrigger className="text-left text-black">
                    {faq.question}
                  </AccordionTrigger>
                  <AccordionContent>{faq.answer}</AccordionContent>
                </AccordionItem>
              ))}
            </Accordion>
          </div>
        </div>
      </section>

      {/* Final CTA */}
      <section className="py-20 bg-primary text-white">
        <div className="container mx-auto px-4 text-center">
          <h2 className="text-4xl font-bold mb-6">
            Pronto a Trasformare il Tuo Business?
          </h2>
          <p className="text-xl mb-8 opacity-90">
            Unisciti a migliaia di host che stanno già usando GuestSuite
          </p>
          <Link to="/login" className="w-full sm:w-auto inline-block">
            <Button
              size="lg"
              variant="secondary"
              className="bg-white text-primary hover:bg-gray-100 w-full sm:w-auto flex items-center justify-center"
            >
              Crea il Tuo Sito Ora
            </Button>
          </Link>
        </div>
      </section>

      <Footer />
    </div>
  );
};

export default LandingPage;
