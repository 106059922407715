// src/utils/dataLoader.js

import axios from "axios";

import { UPLOADS_URL } from "../config";
import { API_BASE_URL } from "../config";

export const getGuides = async (lang) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/guides`);
    const guides = response.data;

    return guides
      .map((guide) => {
        let translation = guide.translations && guide.translations[lang];
        if (!translation && lang !== "en") {
          translation = guide.translations && guide.translations["en"];
        }
        if (!translation) {
          const availableLanguages = Object.keys(guide.translations || {});
          if (availableLanguages.length > 0) {
            translation = guide.translations[availableLanguages[0]];
          } else {
            console.error("No translations available for guide:", guide.id);
            return null;
          }
        }

        return {
          id: guide.id,
          image: guide.image,
          title: translation.title || "No title",
          description: translation.description || "No description",
        };
      })
      .filter(Boolean);
  } catch (error) {
    console.error("Error fetching guides:", error);
    return [];
  }
};

export const getGuideById = async (id, language) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/guides/${id}`);
    const guide = response.data;

    // Gestisci le traduzioni
    if (guide.translations && guide.translations[language]) {
      guide.title = guide.translations[language].title || guide.title;
      guide.description =
        guide.translations[language].description || guide.description;
    }

    return guide;
  } catch (error) {
    console.error("Error fetching guide:", error);
    throw error;
  }
};
const requireLanguages = require.context(
  "../../public/locales", // Percorso alla directory dei file di lingua
  false, // Non cercare ricorsivamente
  /\.json$/, // Cerca solo file che terminano con .json
);

export const getAvailableLanguages = async () => {
  const languages = [];

  requireLanguages.keys().forEach((filename) => {
    const langCode = filename.replace("./", "").replace(".json", "");
    const module = requireLanguages(filename);

    // Modifica le label per tutti i linguaggi
    const languageLabels = {
      it: "Italiano",
      en: "English",
      fr: "Français",
      de: "Deutsch",
      es: "Español",
      pt: "Português",
      ru: "Русский",
      zh: "中文",
      ja: "日本語",
      // Aggiungi qui altre lingue se necessario
    };

    const languageLabel = languageLabels[langCode] || langCode; // Usa la label modificata o il codice della lingua
    languages.push({ code: langCode, label: module.language || languageLabel });
  });

  return languages;
};
