import { useState } from "react";
import { Eye, EyeOff } from "react-feather";
import { useTranslation } from "react-i18next";
import { useApi } from "../../hooks/useApi";
import Spinner from "../Spinner"; // Importa il componente Spinner
import { Button } from "../ui/button";
import { Card, CardContent, CardFooter, CardHeader } from "../ui/card";
import { Input } from "../ui/input";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../ui/select";

const Regulations = ({ apartments = [] }) => {
  const { t } = useTranslation();
  const { apiCall } = useApi();
  const [selectedApartment, setSelectedApartment] = useState("");

  const [alloggiati_username, setAlloggiatiUsername] = useState("");
  const [alloggiati_password, setAlloggiatiPassword] = useState("");
  const [alloggiati_token, setAlloggiatiToken] = useState("");
  const [alloggiatiStatus, setAlloggiatiStatus] = useState("");
  const [alloggiatiCheckStatus, setAlloggiatiCheckStatus] = useState("");
  const [alloggiatiSaveStatus, setAlloggiatiSaveStatus] = useState("");

  const [istat_username, setIstatUsername] = useState("");
  const [istat_password, setIstatPassword] = useState("");
  const [istatStatus, setIstatStatus] = useState("");
  const [istatCheckStatus, setIstatCheckStatus] = useState("");
  const [istatSaveStatus, setIstatSaveStatus] = useState("");

  const [showAlloggiatiPassword, setShowAlloggiatiPassword] = useState(false);
  const [showIstatPassword, setShowIstatPassword] = useState(false);

  const [isTooltipOpen, setIsTooltipOpen] = useState(false);

  const handleApartmentChange = (value) => {
    setSelectedApartment(value);
    const selectedApartmentData = apartments.find(
      (apartment) => apartment.id.toString() === value,
    );
    if (selectedApartmentData) {
      const alloggiatiCredentials =
        selectedApartmentData.alloggiati_credentials || {};
      const istatCredentials = selectedApartmentData.istat_credentials || {};

      setAlloggiatiUsername(alloggiatiCredentials.username || "");
      setAlloggiatiPassword(alloggiatiCredentials.password || "");
      setAlloggiatiToken(alloggiatiCredentials.token || "");

      setIstatUsername(istatCredentials.username || "");
      setIstatPassword(istatCredentials.password || "");
    }
  };

  const checkAlloggiatiConnection = async () => {
    if (!alloggiati_username || !alloggiati_password || !alloggiati_token) {
      setAlloggiatiStatus({
        message: "Tutti i campi sono obbligatori per il check.",
        isError: true,
      });
      return;
    }

    try {
      const response = await apiCall(
        "post",
        "/regulations/alloggiatiweb/test",
        {
          username: alloggiati_username,
          password: alloggiati_password,
          token: alloggiati_token,
        },
        true,
      );
      if (response.success) {
        setAlloggiatiStatus({
          message: "Connessione verificata con successo!",
          isError: false,
        });
      } else {
        setAlloggiatiStatus({
          message: `Errore: ${response.message}`,
          isError: true,
        });
      }
    } catch (error) {
      setAlloggiatiStatus({
        message: "Errore durante la connessione.",
        isError: true,
      });
    }
  };

  const saveAlloggiatiCredentials = async () => {
    if (
      !selectedApartment ||
      !alloggiati_username ||
      !alloggiati_password ||
      !alloggiati_token
    ) {
      setAlloggiatiStatus({
        message: "Seleziona un appartamento e compila tutti i campi.",
        isError: true,
      });
      return;
    }

    try {
      const response = await apiCall(
        "post",
        "/regulations/alloggiatiweb/savecredentials",
        {
          apartmentId: selectedApartment,
          username: alloggiati_username,
          password: alloggiati_password,
          token: alloggiati_token,
        },
        true,
      );
      if (response.success) {
        apartments.find(
          (apartment) => apartment.id.toString() === selectedApartment,
        ).alloggiati_credentials = {
          username: alloggiati_username,
          password: alloggiati_password,
          token: alloggiati_token,
        };
        setAlloggiatiStatus({
          message: "Credenziali salvate con successo!",
          isError: false,
        });
      } else {
        setAlloggiatiStatus({
          message: `Errore: ${response.message}`,
          isError: true,
        });
      }
    } catch (error) {
      setAlloggiatiStatus({
        message: "Errore durante il salvataggio delle credenziali.",
        isError: true,
      });
    }
  };

  const checkIstatCredentials = async () => {
    if (!istat_username || !istat_password) {
      setIstatStatus({
        message: "Username e password sono obbligatori per il check.",
        isError: true,
      });
      return;
    }

    try {
      const response = await apiCall(
        "post",
        "/regulations/istat/test",
        {
          username: istat_username,
          password: istat_password,
        },
        true,
      );
      if (response.success) {
        setIstatStatus({ message: response.message, isError: false });
      } else {
        setIstatStatus({
          message: `Errore: ${response.message}`,
          isError: true,
        });
      }
    } catch (error) {
      setIstatStatus({
        message: "Errore durante la connessione ISTAT.",
        isError: true,
      });
    }
  };

  const saveIstatCredentials = async () => {
    if (!istat_username || !istat_password) {
      setIstatStatus({
        message: "Username e password sono obbligatori per il salvataggio.",
        isError: true,
      });
      return;
    }

    try {
      const response = await apiCall(
        "post",
        "/regulations/istat/savecredentials",
        {
          apartmentId: selectedApartment,
          username: istat_username,
          password: istat_password,
        },
        true,
      );
      if (response.success) {
        apartments.find(
          (apartment) => apartment.id.toString() === selectedApartment,
        ).istat_credentials = {
          username: istat_username,
          password: istat_password,
        };
        setIstatStatus({
          message: "Credenziali ISTAT salvate con successo!",
          isError: false,
        });
      } else {
        setIstatStatus({
          message: `Errore: ${response.message}`,
          isError: true,
        });
      }
    } catch (error) {
      setIstatStatus({
        message: "Errore durante il salvataggio delle credenziali ISTAT.",
        isError: true,
      });
    }
  };

  const isDisabled = !selectedApartment; // Condizione per disabilitare

  return (
    <div className="space-y-6">
      <h2 className="text-2xl font-bold">{t("menu_regulations")}</h2>
      <div className="flex justify-start mb-4">
        <Select
          value={selectedApartment?.toString()}
          onValueChange={handleApartmentChange}
          disabled={apartments.length === 0}
        >
          <SelectTrigger
            className={`w-[200px] flex h-10 items-center justify-between rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background placeholder:text-muted-foreground focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 ${apartments.length === 0 ? "cursor-not-allowed opacity-50" : ""}`}
          >
            <SelectValue
              placeholder={
                apartments.length === 0
                  ? t("no_apartments_available")
                  : t("calendar_select_apartment")
              }
              className="text-sm"
            />
          </SelectTrigger>
          <SelectContent
            position="popper"
            className="w-[--radix-select-trigger-width]"
          >
            {" "}
            {apartments.map((apartment) => (
              <SelectItem
                key={apartment.id}
                value={apartment.id.toString()}
                className="relative flex w-full cursor-default select-none items-center rounded-sm py-1.5 pl-8 pr-2 text-sm outline-none focus:bg-accent focus:text-accent-foreground data-[disabled]:pointer-events-none data-[disabled]:opacity-50"
              >
                {apartment.title ||
                  t("calendar_apartment", { id: apartment.id })}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <Card className="p-4 bg-white">
          <CardHeader>
            <h3 className="text-xl font-semibold">
              {t("menu_alloggiati_web")}
            </h3>
          </CardHeader>
          <CardContent>
            <label className="block mt-2 text-sm">
              {t("label_username_placeholder")}
            </label>
            <Input
              placeholder={t("username_placeholder")}
              value={alloggiati_username}
              onChange={(e) => setAlloggiatiUsername(e.target.value)}
              className="mt-1"
              disabled={isDisabled}
            />
            <label className="block mt-2 text-sm">
              {t("label_password_placeholder")}
            </label>
            <div className="relative mt-2">
              <Input
                placeholder={t("password_placeholder")}
                value={alloggiati_password}
                onChange={(e) => setAlloggiatiPassword(e.target.value)}
                type={showAlloggiatiPassword ? "text" : "password"}
                disabled={isDisabled}
              />
              <button
                type="button"
                onClick={() =>
                  setShowAlloggiatiPassword(!showAlloggiatiPassword)
                }
                className="absolute right-2 top-1/2 transform -translate-y-1/2"
              >
                {showAlloggiatiPassword ? <EyeOff /> : <Eye />}
              </button>
            </div>

            <label className="block mt-2 text-sm flex items-center">
              {t("label_token_placeholder")}
              <button
                onClick={() => setIsTooltipOpen(!isTooltipOpen)}
                className="text-blue-600 w-5 h-5 rounded-full border border-blue-600 flex items-center justify-center text-sm hover:bg-blue-50 focus:outline-none focus:ring-2 focus:ring-blue-500 ml-2"
                aria-label="Mostra informazioni"
              >
                ?
              </button>
            </label>

            {isTooltipOpen && (
              <div className="absolute left-1/2 transform -translate-x-1/2 mt-2 w-80 bg-white border border-gray-200 rounded-lg shadow-lg p-4 z-10">
                <div className="flex justify-between items-start">
                  <div className="flex-1">
                    <p className="text-gray-800 text-sm">
                      {t("token_tooltip")}
                    </p>
                  </div>
                  <button
                    onClick={() => setIsTooltipOpen(false)}
                    className="ml-2 text-gray-500 hover:text-gray-700"
                    aria-label="Chiudi"
                  >
                    <svg
                      className="w-4 h-4"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                    >
                      <path
                        fillRule="evenodd"
                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </button>
                </div>
              </div>
            )}

            <Input
              placeholder={t("token_placeholder")}
              value={alloggiati_token}
              onChange={(e) => setAlloggiatiToken(e.target.value)}
              className="mt-1"
              disabled={isDisabled}
            />
          </CardContent>
          <CardFooter>
            <div className="flex justify-between">
              <Button
                onClick={async () => {
                  if (isDisabled) {
                    alert("Seleziona prima un appartamento");
                    return;
                  }
                  setAlloggiatiCheckStatus({ isLoading: true });
                  await checkAlloggiatiConnection();
                  setAlloggiatiCheckStatus({ isLoading: false });
                }}
                className={`mt-2 bg-blue-600 text-white flex items-center justify-center min-w-[120px] ${isDisabled ? "opacity-50 cursor-not-allowed" : ""}`}
                disabled={isDisabled}
              >
                {alloggiatiCheckStatus.isLoading ? (
                  <Spinner className="mr-2" />
                ) : null}
                {!alloggiatiCheckStatus.isLoading && "Convalida"}
              </Button>

              <Button
                onClick={async () => {
                  if (isDisabled) {
                    alert("Seleziona prima un appartamento");
                    return;
                  }
                  setAlloggiatiSaveStatus({ isLoading: true });
                  await saveAlloggiatiCredentials();
                  setAlloggiatiSaveStatus({ isLoading: false });
                }}
                className={`mt-2 bg-green-600 text-white flex items-center justify-center min-w-[120px] ${isDisabled ? "opacity-50 cursor-not-allowed" : ""}`}
                disabled={isDisabled}
              >
                {alloggiatiSaveStatus.isLoading && <Spinner className="mr-2" />}
                {!alloggiatiSaveStatus.isLoading && "Salva"}
              </Button>
            </div>
            {alloggiatiStatus && (
              <p
                className={`mt-2 ${alloggiatiStatus.isError ? "text-red-500" : "text-green-500"}`}
              >
                {alloggiatiStatus.message}
              </p>
            )}
          </CardFooter>
        </Card>
        <Card className="p-4 bg-white">
          <CardHeader>
            <h3 className="text-xl font-semibold">{t("menu_istat")}</h3>
          </CardHeader>
          <CardContent>
            <label className="block mt-2 text-sm">
              {t("label_istat_selection_placeholder")}
            </label>
            <Select
              value="campania-rilevatore-turistico"
              onValueChange={(value) => console.log(value)}
              disabled={isDisabled}
            >
              <SelectTrigger className="w-full flex h-10 items-center justify-between rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background placeholder:text-muted-foreground focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2">
                <SelectValue placeholder="Seleziona" className="text-sm" />
              </SelectTrigger>
              <SelectContent
                position="popper"
                className="w-[--radix-select-trigger-width]"
              >
                {" "}
                <SelectItem
                  key="campania-rilevatore-turistico"
                  value="campania-rilevatore-turistico"
                  className="relative flex w-full cursor-default select-none items-center rounded-sm py-1.5 pl-8 pr-2 text-sm outline-none focus:bg-accent focus:text-accent-foreground"
                >
                  Campania - Rilevatore Turistico
                </SelectItem>
              </SelectContent>
            </Select>
            <label className="block mt-2 text-sm">
              {t("label_username_placeholder")}
            </label>
            <Input
              placeholder={t("username_placeholder")}
              value={istat_username}
              onChange={(e) => setIstatUsername(e.target.value)}
              className="mt-1"
              disabled={isDisabled}
            />
            <label className="block mt-2 text-sm">
              {t("label_password_placeholder")}
            </label>
            <div className="relative mt-2">
              <Input
                placeholder={t("password_placeholder")}
                type={showIstatPassword ? "text" : "password"}
                value={istat_password}
                onChange={(e) => setIstatPassword(e.target.value)}
                disabled={isDisabled}
              />
              <button
                type="button"
                onClick={() => setShowIstatPassword(!showIstatPassword)}
                className="absolute right-2 top-1/2 transform -translate-y-1/2"
              >
                {showIstatPassword ? <EyeOff /> : <Eye />}
              </button>
            </div>
          </CardContent>
          <CardFooter>
            <div className="flex justify-between">
              <Button
                disabled={isDisabled}
                onClick={async () => {
                  setIstatCheckStatus({ isLoading: true });
                  setIstatStatus({ message: "", isError: false });
                  await checkIstatCredentials();
                  setIstatCheckStatus({ isLoading: false });
                }}
                className="mt-2 bg-blue-600 text-white flex items-center justify-center min-w-[120px]"
              >
                {istatCheckStatus.isLoading ? (
                  <Spinner className="mr-2" />
                ) : (
                  "Convalida"
                )}
              </Button>
              <Button
                disabled={isDisabled}
                onClick={async () => {
                  setIstatSaveStatus({ isLoading: true });
                  await saveIstatCredentials();
                  setIstatSaveStatus({ isLoading: false });
                }}
                className="mt-2 bg-green-600 text-white flex items-center justify-center min-w-[120px]"
              >
                {istatSaveStatus.isLoading ? <Spinner /> : "Salva"}
              </Button>
            </div>
            {istatStatus && (
              <p
                className={`mt-2 ${istatStatus.isError ? "text-red-500" : "text-green-500"}`}
              >
                {istatStatus.message}
              </p>
            )}
          </CardFooter>
        </Card>
      </div>
    </div>
  );
};

export default Regulations;
