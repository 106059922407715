// client/src/hooks/useApi.js
import { useState, useCallback, useMemo } from "react";
import axios from "axios";
import { API_BASE_URL } from "../config";
import authService from "../services/authService";
import { isProduction } from "../config";

export const useApi = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const apiCall = useCallback(
    async (
      method,
      endpoint,
      data = null,
      useAuth = false,
      usePublicToken = false,
      formData = false,
    ) => {
      setIsLoading(true);
      setError(null);

      const headers = useAuth
        ? { Authorization: `Bearer ${authService.getToken()}` }
        : usePublicToken
          ? { Authorization: `Bearer ${authService.getPublicToken()}` }
          : {};

      if (formData) {
        headers["Content-Type"] = "multipart/form-data";
      }

      let url;
      if (isProduction) {
        url = `https://${window.location.hostname}${API_BASE_URL}${endpoint}`;
      } else {
        url = `http://${window.location.hostname}:3001/api${endpoint}`;
      }

      try {
        const response = await axios({
          method,
          url,
          data,
          headers,
        });

        setIsLoading(false);
        return { ...response };
      } catch (err) {
        if (useAuth && err.response && err.response.status === 401) {
          try {
            await authService.refreshToken();
            // Retry the original request with the new token
            const retryResponse = await axios({
              method,
              url: `${API_BASE_URL}${endpoint}`,
              data,
              headers: {
                ...headers,
                Authorization: `Bearer ${authService.getToken()}`,
              },
            });
            setIsLoading(false);
            return { ...retryResponse, headers: retryResponse.headers };
          } catch (refreshError) {
            authService.logout();
            setError("Session expired. Please log in again.");

            // Redirect to login only if useAuth is true
            if (useAuth) {
              window.location.href = "/login"; // Redirect to login page
            }

            throw new Error("Session expired");
          }
        } else {
          setError(err.response ? err.response.data.message : err.message);
          setIsLoading(false);
          throw err;
        }
      }
    },
    [],
  );

  return { apiCall, isLoading, error };
};
