import { it } from "date-fns/locale";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Calendar as CalendarUI } from "../../components/ui/calendar";
import { Card, CardContent, CardHeader } from "../../components/ui/card";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../components/ui/select";
import { useApi } from "../../hooks/useApi";
import { cn } from "../../lib/utils";

const Calendar = () => {
  const { t, i18n } = useTranslation();
  const [date, setDate] = useState({
    from: new Date(),
    to: new Date(),
  });
  const [events, setEvents] = useState([]);
  const [apartments, setApartments] = useState([]);
  const [selectedApartment, setSelectedApartment] = useState(null);
  const { apiCall } = useApi();
  const [numberOfMonths, setNumberOfMonths] = useState(2);

  // Determina la locale del calendario in base alla lingua corrente
  const calendarLocale = i18n.language === "it" ? it : undefined;

  // Effect per il resize
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setNumberOfMonths(1);
      } else {
        setNumberOfMonths(2);
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Fetch apartments
  useEffect(() => {
    const fetchApartments = async () => {
      try {
        const data = await apiCall("get", "/apartments", null, true);
        console.log(data);
        setApartments(data);
      } catch (error) {
        console.error("Error fetching apartments:", error);
      }
    };
    fetchApartments();
  }, []);

  // Fetch events for selected apartment
  useEffect(() => {
    const fetchEvents = async () => {
      if (!selectedApartment) {
        setEvents([]);
        return;
      }

      try {
        const data = await apiCall(
          "get",
          `/apartments/${selectedApartment}/events`,
          null,
          true,
        );
        setEvents(
          data?.map((event) => ({
            ...event,
            start: new Date(event.start),
            end: new Date(event.end),
          })) || [],
        );
      } catch (error) {
        console.error("Error fetching calendar data:", error);
      }
    };
    fetchEvents();
  }, [selectedApartment]);

  const isDateInRange = (date, start, end) => {
    const checkDate = new Date(date);
    const startDate = new Date(start);
    const endDate = new Date(end);
    return checkDate >= startDate && checkDate <= endDate;
  };

  const disabledDays = (date) => {
    return events.some((event) => isDateInRange(date, event.start, event.end));
  };

  const selectedApartmentData = apartments.find(
    (apt) => apt.id === selectedApartment,
  );

  return (
    <>
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-2xl font-bold">{t("calendar_title")}</h2>
      </div>
      <Card className="w-full bg-white">
        <CardHeader>
          <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center gap-4">
            <Select
              value={selectedApartment?.toString()}
              onValueChange={(value) => setSelectedApartment(Number(value))}
              disabled={apartments.length === 0}
            >
              <SelectTrigger className="w-[200px] flex h-10 items-center justify-between rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background placeholder:text-muted-foreground focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50">
                <SelectValue
                  placeholder={t("calendar_select_apartment")}
                  className="text-sm"
                />
              </SelectTrigger>
              <SelectContent
                position="popper"
                className="w-[--radix-select-trigger-width]"
              >
                {apartments.map((apartment) => (
                  <SelectItem
                    key={apartment.id}
                    value={apartment.id.toString()}
                    className="relative flex w-full cursor-default select-none items-center rounded-sm py-1.5 pl-8 pr-2 text-sm outline-none focus:bg-accent focus:text-accent-foreground data-[disabled]:pointer-events-none data-[disabled]:opacity-50"
                  >
                    {apartment.title ||
                      t("calendar_apartment", { id: apartment.id })}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>
        </CardHeader>
        <CardContent className="overflow-x-auto">
          {selectedApartment ? (
            <div className="flex justify-center min-w-fit">
              <CalendarUI
                mode="range"
                defaultMonth={date?.from}
                selected={date}
                onSelect={setDate}
                numberOfMonths={numberOfMonths}
                locale={calendarLocale}
                disabled={disabledDays}
                className="rounded-md border max-w-full"
                modifiers={{
                  booked: (date) =>
                    events.some((event) =>
                      isDateInRange(date, event.start, event.end),
                    ),
                }}
                modifiersStyles={{
                  booked: {
                    backgroundColor: "rgba(75, 85, 99, 0.2)",
                    borderRadius: "0",
                    cursor: "not-allowed",
                  },
                }}
                classNames={{
                  months: cn(
                    "flex flex-col sm:flex-row space-y-4 sm:space-x-4 sm:space-y-0",
                    "max-w-full",
                  ),
                  month: "space-y-4 max-w-[300px]",
                  caption: "flex justify-center pt-1 relative items-center",
                  caption_label: "text-sm font-medium",
                  nav: "space-x-1 flex items-center",
                  nav_button: cn(
                    "h-7 w-7 bg-transparent p-0 opacity-50 hover:opacity-100",
                  ),
                  nav_button_previous: "absolute left-1",
                  nav_button_next: "absolute right-1",
                  table: "w-full border-collapse space-y-1",
                  head_row: "flex",
                  head_cell: cn(
                    "text-muted-foreground rounded-md w-8 sm:w-9 font-normal text-[0.8rem]",
                  ),
                  row: "flex w-full mt-2",
                  cell: cn(
                    "relative p-0 text-center text-sm focus-within:relative focus-within:z-20",
                    "first:[&:has([aria-selected])]:rounded-l-md last:[&:has([aria-selected])]:rounded-r-md",
                  ),
                  day: cn(
                    "h-8 w-8 sm:h-9 sm:w-9 p-0 font-normal aria-selected:opacity-100",
                    "hover:bg-muted hover:text-accent-foreground",
                    "relative text-xs sm:text-sm",
                    "[&:has([aria-selected])]:bg-accent",
                  ),
                  day_selected:
                    "bg-primary text-primary-foreground hover:bg-primary hover:text-primary-foreground focus:bg-primary focus:text-primary-foreground",
                  day_today: "bg-accent text-accent-foreground",
                  day_range_middle:
                    "aria-selected:bg-accent aria-selected:text-accent-foreground",
                  day_disabled: cn(
                    "text-muted-foreground opacity-50",
                    "cursor-not-allowed",
                  ),
                  day_range_end:
                    "aria-selected:bg-primary aria-selected:text-primary-foreground",
                  day_range_start:
                    "aria-selected:bg-primary aria-selected:text-primary-foreground",
                  day_outside: "text-muted-foreground opacity-50",
                }}
              />
            </div>
          ) : (
            <div className="text-center text-muted-foreground py-8">
              {t("calendar_no_apartment")}
            </div>
          )}
        </CardContent>
      </Card>
    </>
  );
};

export default Calendar;
