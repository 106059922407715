import React, {
  createContext,
  useState,
  useContext,
  useEffect,
  useCallback,
} from "react";
import authService from "../services/authService";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    console.log("Current user state:", user);
  }, [user]);

  const checkAuth = useCallback(async () => {
    setIsLoading(true);
    try {
      const userData = await authService.verifyToken();
      if (userData) {
        setUser(userData);
        console.log("User data set:", userData);
      } else {
        setUser(null);
      }
    } catch (error) {
      console.error("Auth verification failed:", error);
      authService.logout();
      setUser(null);
    } finally {
      setIsLoading(false);
    }
  }, []);

  // Aggiungi questa funzione per aggiornare l'utente
  const updateUser = useCallback((updates) => {
    setUser((currentUser) => {
      const updatedUser =
        typeof updates === "function"
          ? updates(currentUser)
          : { ...currentUser, ...updates };

      console.log("Updating user:", updatedUser);
      return updatedUser;
    });
  }, []);

  useEffect(() => {
    checkAuth();
  }, [checkAuth]);

  const login = async (email, password) => {
    try {
      const response = await authService.login(email, password);
      setUser(response.user);
      return response;
    } catch (error) {
      console.error("Login failed:", error);
      throw error;
    }
  };

  const logout = useCallback(() => {
    authService.logout();
    setUser(null);
  }, []);

  const refreshToken = useCallback(async () => {
    try {
      const newToken = await authService.refreshToken();
      await checkAuth();
      return newToken;
    } catch (error) {
      console.error("Failed to refresh token:", error);
      logout();
      throw error;
    }
  }, [logout, checkAuth]);

  const googleLogin = async (credential) => {
    try {
      const response = await authService.googleLogin(credential);
      setUser(response.user);
      return response;
    } catch (error) {
      console.error("Google login failed:", error);
      throw error;
    }
  };

  const authContextValue = {
    user,
    login,
    logout,
    refreshToken,
    isLoading,
    checkAuth,
    googleLogin,
    updateUser, // Aggiungi updateUser al context
  };

  return (
    <AuthContext.Provider value={authContextValue}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
