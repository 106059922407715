import { Menu } from "lucide-react";
import { Link } from "react-router-dom";
import { Button } from "../ui/button";
import { Sheet, SheetClose, SheetContent, SheetTrigger } from "../ui/sheet";

const Navbar = () => {
  const scrollToSection = (sectionId) => {
    const element = document.getElementById(sectionId);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <header className="fixed top-0 left-0 right-0 bg-white/80 backdrop-blur-sm z-50 border-b">
      <nav className="container mx-auto px-4 h-14">
        <div className="flex items-center justify-between h-full">
          {/* Menu Mobile e Logo */}
          <div className="flex items-center gap-2">
            <Sheet>
              <SheetTrigger asChild>
                <Button variant="ghost" size="sm" className="lg:hidden p-1">
                  <Menu className="h-5 w-5" />
                </Button>
              </SheetTrigger>
              <SheetContent side="left" className="w-64">
                <div className="flex flex-col mt-4">
                  <Link to="/" className="text-lg font-bold mb-6">
                    GuestSuite
                  </Link>

                  <div className="space-y-1">
                    <SheetClose asChild>
                      <button
                        onClick={() => scrollToSection("funzionalita")}
                        className="w-full text-left px-3 py-2 text-sm hover:bg-gray-100 rounded-md"
                      >
                        Funzionalità
                      </button>
                    </SheetClose>

                    <SheetClose asChild>
                      <button
                        onClick={() => scrollToSection("testimonials")}
                        className="w-full text-left px-3 py-2 text-sm hover:bg-gray-100 rounded-md"
                      >
                        Testimonial
                      </button>
                    </SheetClose>

                    <SheetClose asChild>
                      <button
                        onClick={() => scrollToSection("pricing")}
                        className="w-full text-left px-3 py-2 text-sm hover:bg-gray-100 rounded-md"
                      >
                        Prezzi
                      </button>
                    </SheetClose>

                    <SheetClose asChild>
                      <button
                        onClick={() => scrollToSection("faq")}
                        className="w-full text-left px-3 py-2 text-sm hover:bg-gray-100 rounded-md"
                      >
                        FAQ
                      </button>
                    </SheetClose>
                  </div>

                  <div className="mt-auto pt-4 border-t">
                    <SheetClose asChild>
                      <Link to="/login">
                        <Button className="w-full" size="sm">
                          Accedi
                        </Button>
                      </Link>
                    </SheetClose>
                  </div>
                </div>
              </SheetContent>
            </Sheet>

            <Link to="/" className="text-lg font-bold">
              GuestSuite
            </Link>
          </div>

          {/* Menu Desktop */}
          <div className="hidden lg:flex items-center gap-6">
            <button
              onClick={() => scrollToSection("funzionalita")}
              className="hover:text-primary transition-colors"
            >
              Funzionalità
            </button>
            <button
              onClick={() => scrollToSection("testimonials")}
              className="hover:text-primary transition-colors"
            >
              Testimonial
            </button>
            <button
              onClick={() => scrollToSection("pricing")}
              className="hover:text-primary transition-colors"
            >
              Prezzi
            </button>
            <button
              onClick={() => scrollToSection("faq")}
              className="hover:text-primary transition-colors"
            >
              FAQ
            </button>
          </div>

          {/* Login Button (Desktop) */}
          <Link to="/login" className="hidden lg:block">
            <Button variant="ghost">Accedi</Button>
          </Link>
        </div>
      </nav>
    </header>
  );
};

export default Navbar;
