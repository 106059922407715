import { toast } from "react-toastify";

// Configurazione comune per tutti i toast
const defaultConfig = {
  position: "top-right",
  autoClose: 2000, // Ridotto drasticamente a 800ms
  hideProgressBar: true, // Nasconde la barra del progresso per un look più pulito
  closeOnClick: true,
  pauseOnHover: false, // Non si ferma al passaggio del mouse
  draggable: true,
  progress: undefined,
};

export const showToast = {
  success: (message) => toast.success(message, defaultConfig),
  error: (message) => toast.error(message, defaultConfig),
  warning: (message) => toast.warning(message, defaultConfig),
  info: (message) => toast.info(message, defaultConfig),
};
