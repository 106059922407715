import React, { useState, useEffect, useCallback } from "react";
import { useApi } from "../../hooks/useApi";
import {
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../ui/table";
import { Button } from "../ui/button";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
  DialogFooter,
} from "../ui/dialog";
import { Input } from "../ui/input";
import { Label } from "../ui/label";
import {
  Select,
  SelectTrigger,
  SelectValue,
  SelectContent,
  SelectItem,
} from "../ui/select";
import { useToast } from "../ui/use-toast";
import { useTranslation } from "react-i18next";

const AdminUsersTable = () => {
  const { t } = useTranslation();
  const [users, setUsers] = useState([]);
  const [editingUser, setEditingUser] = useState(null);
  const [isCreatingUser, setIsCreatingUser] = useState(false);
  const [newUser, setNewUser] = useState({
    email: "",
    password: "",
    role: "client",
    status: "active",
  });
  const [newPassword, setNewPassword] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [sortConfig, setSortConfig] = useState(null);
  const { apiCall, isLoading, error } = useApi();
  const { toast } = useToast();

  const loadUsers = async () => {
    try {
      const response = await apiCall("get", "/admin/users", null, true);
      console.log("Response:", response);
      if (response.data && response.data.success) {
        setUsers(response.data.users || []);
      }
    } catch (err) {
      console.error("Error fetching users:", err);
    }
  };

  useEffect(() => {
    loadUsers();
  }, [apiCall]);

  const handleEditUser = (user) => {
    setEditingUser(user);
    setNewPassword("");
  };

  const handleCloseDialog = () => {
    setEditingUser(null);
    setNewPassword("");
  };

  const handleEditSubmit = async (e) => {
    e.preventDefault();
    try {
      const updateData = {
        email: editingUser.email,
        role: editingUser.role,
        status: editingUser.status,
        updated_at: new Date().toISOString(),
      };

      if (newPassword.trim()) {
        updateData.password = newPassword;
      }

      await apiCall("put", `/admin/users/${editingUser.id}`, updateData, true);

      toast({
        title: "Success",
        description: "User updated successfully",
      });

      loadUsers();
      handleCloseDialog();
    } catch (error) {
      console.error("Error updating user:", error);
      toast({
        variant: "destructive",
        title: "Error",
        description: "Failed to update user",
      });
    }
  };

  const handleCreateUser = async () => {
    try {
      const createdUserData = {
        ...newUser,
        created_at: new Date().toISOString(),
      };
      await apiCall("post", "/admin/users", createdUserData, true);
      setIsCreatingUser(false);
      setNewUser({ email: "", password: "", role: "client", status: "active" });
      loadUsers();
    } catch (err) {
      console.error("Error creating user:", err);
    }
  };

  const sortedUsers = () => {
    let sortableUsers = [...users];
    if (sortConfig !== null) {
      sortableUsers.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === "ascending" ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === "ascending" ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableUsers.filter(
      (user) =>
        user.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
        user.role.toLowerCase().includes(searchTerm.toLowerCase()) ||
        user.status.toLowerCase().includes(searchTerm.toLowerCase()),
    );
  };

  const requestSort = (key) => {
    let direction = "ascending";
    if (
      sortConfig &&
      sortConfig.key === key &&
      sortConfig.direction === "ascending"
    ) {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };

  if (isLoading) {
    return <div className="text-center">{t("loading")}</div>;
  }

  if (error) {
    return <div className="text-center text-red-600">{error}</div>;
  }

  return (
    <>
      <Button onClick={() => setIsCreatingUser(true)} className="mb-4">
        Create New User
      </Button>

      <Input
        type="text"
        placeholder="Search by email, role, or status..."
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        className="mb-4"
      />

      <Table>
        <TableCaption>A list of all registered users.</TableCaption>
        <TableHeader>
          <TableRow>
            <TableHead onClick={() => requestSort("email")}>Email</TableHead>
            <TableHead onClick={() => requestSort("role")}>Role</TableHead>
            <TableHead onClick={() => requestSort("status")}>Status</TableHead>
            <TableHead onClick={() => requestSort("created_at")}>
              Created At
            </TableHead>
            <TableHead onClick={() => requestSort("updated_at")}>
              Updated At
            </TableHead>
            <TableHead>Actions</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {sortedUsers().map((user) => (
            <TableRow key={user.id}>
              <TableCell>{user.email}</TableCell>
              <TableCell>{user.role}</TableCell>
              <TableCell>{user.status}</TableCell>
              <TableCell>
                {new Date(user.created_at).toLocaleString()}
              </TableCell>
              <TableCell>
                {new Date(user.updated_at).toLocaleString()}
              </TableCell>
              <TableCell>
                <Button onClick={() => handleEditUser(user)}>Edit</Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      <Dialog
        open={!!editingUser}
        onOpenChange={(open) => {
          if (!open) {
            handleCloseDialog();
          }
        }}
      >
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Edit User</DialogTitle>
          </DialogHeader>
          <form onSubmit={handleEditSubmit}>
            <Label>Email</Label>
            <Input
              value={editingUser?.email || ""}
              onChange={(e) =>
                setEditingUser({ ...editingUser, email: e.target.value })
              }
            />
            <Label>Role</Label>
            <Select
              value={editingUser?.role || ""}
              onValueChange={(value) =>
                setEditingUser({ ...editingUser, role: value })
              }
            >
              <SelectTrigger>
                <SelectValue placeholder="Select role" />
              </SelectTrigger>
              <SelectContent
                position="popper"
                className="w-[--radix-select-trigger-width]"
              >
                {" "}
                <SelectItem value="user">User</SelectItem>
                <SelectItem value="admin">Admin</SelectItem>
              </SelectContent>
            </Select>
            <DialogFooter>
              <Button type="submit">Save Changes</Button>
            </DialogFooter>
          </form>
        </DialogContent>
      </Dialog>

      <Dialog
        open={isCreatingUser}
        onOpenChange={(open) => !open && handleCloseDialog()}
      >
        <DialogContent
          className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50"
          onClick={handleCloseDialog}
        >
          <div
            className="bg-white p-8 rounded-lg shadow-lg w-full max-w-3xl h-auto max-h-[90vh] overflow-y-auto"
            onClick={(e) => e.stopPropagation()}
          >
            <DialogHeader>
              <DialogTitle>Create New User</DialogTitle>
              <DialogDescription>
                Enter the details for the new user.
              </DialogDescription>
            </DialogHeader>
            <div className="grid gap-4 py-4">
              <div className="grid grid-cols-4 items-center gap-4">
                <Label htmlFor="newEmail" className="text-right">
                  Email
                </Label>
                <Input
                  id="newEmail"
                  value={newUser.email}
                  onChange={(e) =>
                    setNewUser({ ...newUser, email: e.target.value })
                  }
                  className="col-span-3"
                />
              </div>
              <div className="grid grid-cols-4 items-center gap-4">
                <Label htmlFor="newPassword" className="text-right">
                  Password
                </Label>
                <Input
                  id="newPassword"
                  type="password"
                  value={newUser.password}
                  onChange={(e) =>
                    setNewUser({ ...newUser, password: e.target.value })
                  }
                  className="col-span-3"
                />
              </div>
              <div className="grid grid-cols-4 items-center gap-4">
                <Label htmlFor="newRole" className="text-right">
                  Role
                </Label>
                <Select
                  value={newUser.role}
                  onValueChange={(value) =>
                    setNewUser({ ...newUser, role: value })
                  }
                >
                  <SelectTrigger className="col-span-3">
                    <SelectValue placeholder="Select a role" />
                  </SelectTrigger>
                  <SelectContent
                    position="popper"
                    className="w-[--radix-select-trigger-width]"
                  >
                    <SelectItem value="client">Client</SelectItem>
                    <SelectItem value="admin">Admin</SelectItem>
                  </SelectContent>
                </Select>
              </div>
            </div>
            <DialogFooter>
              <Button type="submit" onClick={handleCreateUser}>
                Create User
              </Button>
            </DialogFooter>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default AdminUsersTable;
