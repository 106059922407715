import { useState } from "react";
import { Button } from "../ui/button";
import { useTranslation } from "react-i18next";
import { CreditCard, Check } from "lucide-react";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
  CardDescription,
} from "../ui/card";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "../ui/dialog";
import { Input } from "../ui/input";
import { Label } from "../ui/label";
import {
  Table,
  TableHeader,
  TableBody,
  TableRow,
  TableHead,
  TableCell,
} from "../ui/table";
import { Download } from "lucide-react";

import { showToast } from "../../utils/toast";

// Configurazione piani
const PLANS = {
  FREE: {
    id: 'free',
    name: 'Free Plan',
    price: '€0',
    interval: 'month',
    features: [
      'Up to 3 apartments',
      'Basic support',
      'Basic features'
    ]
  },
  PRO: {
    id: 'pro',
    name: 'Pro Plan',
    price: '€19.99',
    interval: 'month',
    features: [
      'Unlimited apartments',
      'Priority support',
      'Advanced features',
      'Analytics'
    ]
  }
};

const BillingDetails = ({ user }) => {
  const { t } = useTranslation();
  const [openDialog, setOpenDialog] = useState("");
  const [loading, setLoading] = useState(false);
  const [currentPlan, setCurrentPlan] = useState(PLANS.FREE);
  const [paymentMethod, setPaymentMethod] = useState({
    cardNumber: "",
    expiry: "",
    cvc: "",
    saved: false
  });

  const handleOpenDialog = (dialogName) => {
    setOpenDialog(dialogName);
  };

  const handleCloseDialog = () => {
    setOpenDialog("");
    // Reset payment form solo se stiamo chiudendo il dialog dei pagamenti
    if (openDialog === 'payment') {
      setPaymentMethod(prev => ({
        ...prev,
        cardNumber: "",
        expiry: "",
        cvc: ""
      }));
    }
  };

  const handleChangePlan = async (planId) => {
    try {
      setLoading(true);
      const newPlan = PLANS[planId.toUpperCase()];
      
      // Se stiamo passando al piano PRO e non abbiamo un metodo di pagamento salvato
      if (planId.toUpperCase() === 'PRO' && !paymentMethod.saved) {
        handleOpenDialog('payment');
        return;
      }

      // Simula una breve attesa
      await new Promise(resolve => setTimeout(resolve, 1000));
      
      setCurrentPlan(newPlan);
      showToast.success(t("plan_updated_successfully"));
      handleCloseDialog();
    } catch (error) {
      console.error('Error changing plan:', error);
      showToast.error(t("error_changing_plan"));
    } finally {
      setLoading(false);
    }
  };

  const handleUpdatePaymentMethod = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);

      // Validazione base
      if (paymentMethod.cardNumber.length < 16 || 
          paymentMethod.expiry.length < 5 || 
          paymentMethod.cvc.length < 3) {
        showToast.error(t("invalid_card_details"));
        return;
      }

      // Simula una breve attesa
      await new Promise(resolve => setTimeout(resolve, 1000));

      // Aggiorna lo stato del metodo di pagamento
      setPaymentMethod(prev => ({
        ...prev,
        saved: true
      }));

      showToast.success(t("payment_method_updated"));
      handleCloseDialog();

      // Se stavamo cercando di passare al piano PRO, completa l'upgrade
      if (openDialog === 'payment' && currentPlan.id === 'free') {
        handleChangePlan('PRO');
      }
    } catch (error) {
      console.error('Error updating payment method:', error);
      showToast.error(t("error_updating_payment_method"));
    } finally {
      setLoading(false);
    }
  };

  const formatCardNumber = (value) => {
    const v = value.replace(/\s+/g, '').replace(/[^0-9]/gi, '');
    const matches = v.match(/\d{4,16}/g);
    const match = matches && matches[0] || '';
    const parts = [];

    for (let i = 0, len = match.length; i < len; i += 4) {
      parts.push(match.substring(i, i + 4));
    }

    if (parts.length) {
      return parts.join(' ');
    } else {
      return value;
    }
  };

  const formatExpiry = (value) => {
    const v = value.replace(/\s+/g, '').replace(/[^0-9]/gi, '');
    if (v.length >= 2) {
      return `${v.substring(0, 2)}/${v.substring(2, 4)}`;
    }
    return v;
  };

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    let formattedValue = value;

    if (id === 'cardNumber') {
      formattedValue = formatCardNumber(value);
    } else if (id === 'expiry') {
      formattedValue = formatExpiry(value);
    } else if (id === 'cvc') {
      formattedValue = value.replace(/[^0-9]/g, '').substring(0, 3);
    }

    setPaymentMethod(prev => ({
      ...prev,
      [id]: formattedValue
    }));
  };

  return (
    <div className="space-y-4 md:space-y-6">
      {/* Piano Attuale */}
      <Card className="bg-white">
        <CardHeader>
          <CardTitle>{t("current_plan")}</CardTitle>
          <CardDescription>{t("plan_description")}</CardDescription>
        </CardHeader>
        <CardContent>
          <div className="space-y-4">
            <div className="flex flex-col sm:flex-row sm:justify-between sm:items-start gap-4">
              <div>
                <h4 className="text-xl font-semibold">{currentPlan.name}</h4>
                <p className="text-sm text-muted-foreground">
                  {currentPlan.price}/{currentPlan.interval}
                </p>
              </div>
              <Button 
                variant="outline" 
                onClick={() => handleOpenDialog("plan")}
                className="w-full sm:w-auto"
              >
                {t("change_plan")}
              </Button>
            </div>

            <div className="pt-4 border-t">
              <h5 className="font-medium mb-2">{t("plan_features")}</h5>
              <ul className="space-y-2">
                {currentPlan.features.map((feature, index) => (
                  <li key={index} className="text-sm text-muted-foreground flex items-center">
                    <Check className="h-4 w-4 mr-2 text-green-500 flex-shrink-0" />
                    <span className="flex-1">{t(feature)}</span>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </CardContent>
      </Card>

      {/* Metodo di Pagamento */}
      <Card className="bg-white">
        <CardHeader>
          <CardTitle>{t("payment_method")}</CardTitle>
          <CardDescription>{t("payment_method_description")}</CardDescription>
        </CardHeader>
        <CardContent>
          <div className="flex flex-col sm:flex-row sm:items-center justify-between gap-4">
            <div className="flex items-center space-x-4">
              <CreditCard className="h-6 w-6 text-gray-500 flex-shrink-0" />
              <div>
                {paymentMethod.saved ? (
                  <>
                    <p className="font-medium">
                      •••• •••• •••• {paymentMethod.cardNumber.slice(-4)}
                    </p>
                    <p className="text-sm text-muted-foreground">
                      {t("expires")} {paymentMethod.expiry}
                    </p>
                  </>
                ) : (
                  <p className="text-sm text-muted-foreground">
                    {t("no_payment_method")}
                  </p>
                )}
              </div>
            </div>
            <Button 
              variant="outline" 
              onClick={() => handleOpenDialog("payment")}
              className="w-full sm:w-auto"
            >
              {paymentMethod.saved ? t("update") : t("add_payment_method")}
            </Button>
          </div>
        </CardContent>
      </Card>

      {/* Fatture Recenti */}
      <Card className="bg-white">
        <CardHeader>
          <CardTitle>{t("recent_invoices")}</CardTitle>
          <CardDescription>{t("invoices_description")}</CardDescription>
        </CardHeader>
        <CardContent>
          <div className="overflow-x-auto">
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead className="w-[100px]">{t("date")}</TableHead>
                  <TableHead className="w-[100px]">{t("amount")}</TableHead>
                  <TableHead className="w-[100px]">{t("status")}</TableHead>
                  <TableHead className="w-[60px] text-right">{t("invoice")}</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {[
                  {
                    id: 1,
                    date: "2024-03-01",
                    amount: "€19.99",
                    status: "Pagata",
                    invoice_url: "#"
                  },
                  {
                    id: 2,
                    date: "2024-02-01",
                    amount: "€19.99",
                    status: "Pagata",
                    invoice_url: "#"
                  },
                  {
                    id: 3,
                    date: "2024-01-01",
                    amount: "€19.99",
                    status: "Pagata",
                    invoice_url: "#"
                  }
                ].map((invoice) => (
                  <TableRow key={invoice.id}>
                    <TableCell>
                      {new Date(invoice.date).toLocaleDateString()}
                    </TableCell>
                    <TableCell>{invoice.amount}</TableCell>
                    <TableCell>
                      <span className="inline-flex items-center px-2 py-1 rounded-full text-xs font-medium bg-green-100 text-green-800">
                        {invoice.status}
                      </span>
                    </TableCell>
                    <TableCell className="text-right">
                      <Button variant="ghost" size="sm" asChild>
                        <a 
                          href={invoice.invoice_url} 
                          target="_blank" 
                          rel="noopener noreferrer"
                        >
                          <Download className="h-4 w-4" />
                        </a>
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </CardContent>
      </Card>

      {/* Dialog Cambio Piano */}
      <Dialog open={openDialog === "plan"} onOpenChange={handleCloseDialog}>
        <DialogContent className="sm:max-w-[425px] w-[95vw] max-h-[90vh] overflow-y-auto">
          <DialogHeader>
            <DialogTitle>{t("select_plan")}</DialogTitle>
            <DialogDescription>
              {t("select_plan_description")}
            </DialogDescription>
          </DialogHeader>
          <div className="grid gap-4 py-4">
            {Object.values(PLANS).map((plan) => (
              <div
                key={plan.id}
                className={`p-4 border rounded-lg ${
                  currentPlan.id === plan.id ? 'border-primary' : ''
                }`}
              >
                <div className="flex justify-between items-start mb-2">
                  <div>
                    <h4 className="font-medium">{plan.name}</h4>
                    <p className="text-sm text-muted-foreground">
                      {plan.price}/{plan.interval}
                    </p>
                  </div>
                  {currentPlan.id === plan.id && (
                    <span className="text-primary">
                      <Check className="h-5 w-5" />
                    </span>
                  )}
                </div>
                <ul className="text-sm text-muted-foreground mt-2 space-y-1">
                  {plan.features.map((feature, index) => (
                    <li key={index} className="flex items-center">
                      <Check className="h-4 w-4 mr-2 text-green-500" />
                      {t(feature)}
                    </li>
                  ))}
                </ul>
                <Button
                  className="mt-4 w-full"
                  variant={currentPlan.id === plan.id ? "outline" : "default"}
                  disabled={loading || currentPlan.id === plan.id}
                  onClick={() => handleChangePlan(plan.id)}
                >
                  {currentPlan.id === plan.id
                    ? t("current_plan")
                    : t("select_plan")}
                </Button>
              </div>
            ))}
          </div>
        </DialogContent>
      </Dialog>

      {/* Dialog Metodo di Pagamento */}
      <Dialog open={openDialog === "payment"} onOpenChange={handleCloseDialog}>
        <DialogContent className="sm:max-w-[425px] w-[95vw]">
          <DialogHeader>
            <DialogTitle>{t("update_payment_method")}</DialogTitle>
            <DialogDescription>
              {t("payment_method_description")}
            </DialogDescription>
          </DialogHeader>
          <form onSubmit={handleUpdatePaymentMethod}>
            <div className="grid gap-4 py-4">
              <div className="space-y-2">
                <Label htmlFor="cardNumber">{t("card_number")}</Label>
                <Input
                  id="cardNumber"
                  placeholder="4242 4242 4242 4242"
                  value={paymentMethod.cardNumber}
                  onChange={handleInputChange}
                  maxLength="19"
                  required
                />
              </div>
              <div className="grid grid-cols-2 gap-4">
                <div className="space-y-2">
                  <Label htmlFor="expiry">{t("expiry")}</Label>
                  <Input
                    id="expiry"
                    placeholder="MM/YY"
                    value={paymentMethod.expiry}
                    onChange={handleInputChange}
                    maxLength="5"
                    required
                  />
                </div>
                <div className="space-y-2">
                  <Label htmlFor="cvc">{t("cvc")}</Label>
                  <Input
                    id="cvc"
                    placeholder="123"
                    value={paymentMethod.cvc}
                    onChange={handleInputChange}
                    maxLength="3"
                    required
                  />
                </div>
              </div>
            </div>
            <div className="flex justify-end gap-4">
              <Button type="button" variant="outline" onClick={handleCloseDialog}>
                {t("cancel")}
              </Button>
              <Button type="submit" disabled={loading}>
                {loading ? t("saving") : t("save")}
              </Button>
            </div>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default BillingDetails;
